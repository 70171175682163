import {
  AbilityBuilder,
  CreateAbility,
  createMongoAbility,
  MongoAbility,
} from "@casl/ability";

const actions = [
  "create",
  "read",
  "update",
  "delete",
  "cancel",
  "approve",
  "sync",
  "upload",
] as const;
const subjects = [
  "invoice-ap",
  "tax-income",
  "purchase-order",
  "settlement-bank",
  "invoice-ar",
  "asset-retirement",
  "unit-exchange",
  "report-sales",
  "contact-data",
  "asset-addition",
  "asset-depreciation",
  "leasing",
  "work-order",
  "calendar",
  "delivery-order",
  "invoice-join",
  "menu",
  "asset-reclass",
  "internal-contract",
  "warehouse-transfer",
  "accounting-conversion",
  "branch-warehouse",
  "transfer-to-asset",
  "master-type",
  "tax-invoice-code",
  "product-uom",
  "input-pph",
  "invoice-prepayment",
  "accounting-type",
  "product-category",
  "warehouse",
  "accounting-account",
  "sales-return",
  "unit-change-location",
  "asset-category",
  "result-work-order",
  "return-to-supplier",
  "general-journal",
  "sales-order",
  "cash-in-out",
  "invoice-ap-prepayment",
  "asset",
  "tax-invoice-sn",
  "uom-conversion",
  "accounting-currency",
  "cm-bank-transfer",
  "bank",
  "inventory-receive",
  "invoice-receipt",
  "warehouse-location",
  "asset-book",
  "berita-acara",
  "preferences",
  "role",
  "cash-bank-movement",
  "company",
  "ap-payment",
  "picking-list",
  "approver-purchase-requisition",
  "accounting-tax",
  "stock-adjustment",
  "report-kartu-hutang",
  "privilege",
  "invoice-controlling",
  "product",
  "sales-tracking",
  "fifo-report",
  "purchase-requisition",
  "user",
  "report-aging",
  "depreciation-report",
  "trucking-driver",
  "trucking-rate",
  "sync-company-bank",
  "trucking-in-out",
  "trucking-payment",
  "trucking-invoice-ar",
  "trucking-settlement",
  "trucking-sales-order",
  "ujo-tracking",
] as const;

export type Actions = (typeof actions)[number];
export type Subjects = (typeof subjects)[number];
export type AppAbilities = [Actions, Subjects];
export type MenuAccesses = `${Subjects}:${Actions}`;
export type AppAbility = MongoAbility<AppAbilities>;

export const createAppAbility = createMongoAbility as CreateAbility<AppAbility>;

export const defineUserPermissions = (
  authorities: MenuAccesses[]
): AppAbility => {
  const builder = new AbilityBuilder(createAppAbility);

  authorities.forEach(item => {
    const [menu, access] = item.split(/:/gm);
    builder.can(access.toLowerCase() as Actions, menu as Subjects);
  });
  return builder.build();
};
export const defaultAbility = defineUserPermissions([]);
