// Core
// Class
import { Api } from "@/models/class/api.class";
import { CreateAccessories } from "@/models/interface/deliveryorder.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
// Interfaces
import { MarginSalesHeadReportResponseDTO } from "@/models/interface/ReportMarginSales.interface";
import {
  SalesOrderCloseRequestDto,
  SalesOrderCreateRequestDto,
  SalesOrderResponseDto,
  SalesOrderSubmitResponseDto,
  SalesOrderUpdateRequestDto,
} from "@/models/interface/sales-order";
import { SalesOrderReportResponseDto } from "@/models/interface/sales-order-report";
import {
  DataResponseBastState,
  DeliveryOrderList,
  DetailBastWithdrawl,
  DetailInternalContract,
  DetailInternalContractChangeLocation,
  ListDetailHistoryInternalContract,
  PickingListDetail,
  PickingListList,
  RequestBastHasBeenCreated,
  RequestInternalContract,
  RequestPickingList,
  RequestUpdateBastExchange,
  ResponseBastExchange,
  ResponseBastHasBeenCreated,
  ResponseBastWithdrawl,
  ResponseDetailBastExchange,
  ResponseDetailBastHasBeenCreated,
  ResponseDoReadyToPrint,
  ResponseGetListDoReady,
  ResponseGetListInternalContract,
  ResponseGetListInternalContractSo,
  ResponseGetListReminderContract,
  ResponseGetListReportInternalContract,
  ResponseListBastByIc,
  ResponseListInternalContractChangeLocation,
  ResponseReportUnitPosition,
  ResponseReportUnitRented,
  ResponseTrackingIc,
  SalesOrderDataTracking,
  SalesOrderHeaderList,
  SalesOrderList,
  SalesOrderListTracking,
} from "@/models/interface/salesOrder.interface";
import {
  ISalesOrderTrackingGroupResponseDTO,
  RequestParamsGetListSalesOrderTracking,
} from "@/models/interface/SalesOrderTracking.interface";
import { SalesReturnResponseDTO } from "@/models/interface/SalesReturn.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class SalesOrderServices extends HttpClient {
  /**
   * @deprecated
   * new service {@linkcode internalContractService.list}
   */
  getListInternalContract = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListInternalContract> => {
    return this.get<ResponseGetListInternalContract>(Api.InternalContract, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListInternalContractForRecurring = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListInternalContract> => {
    return this.get<ResponseGetListInternalContract>(
      Api.InternalContract + "/available/recurring",
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListInternalContractSo = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListInternalContractSo> => {
    return this.get<ResponseGetListInternalContractSo>(
      Api.InternalContract + "/so",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * new hooks {@linkcode getContractReminders}
   */
  getListReminderContract = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListReminderContract> => {
    return this.get<ResponseGetListReminderContract>(
      Api.InternalContract + "/reminder",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode UnitReceiptMonitoringService.approveReminderContract}
   */
  approveReminderContract = (
    id: string,
    payload: { receiveDate: Date }
  ): Promise<boolean> => {
    return this.put<boolean, { receiveDate: Date }>(
      Api.InternalContract + `/receive-date/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListAvailableInternalContract(
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListInternalContract> {
    return this.get<ResponseGetListInternalContract>(
      Api.AvailableInternalContract,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListReportUnitRented = (
    params: RequestQueryParamsModel
  ): Promise<ResponseReportUnitRented> => {
    return this.get<ResponseReportUnitRented>(Api.ReportUnitRented, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListReportUnitPosition(
    params: RequestQueryParamsModel
  ): Promise<ResponseReportUnitPosition[]> {
    return this.get<ResponseReportUnitPosition[]>(Api.ReportUnitPosition, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportUnitPosition(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.ReportUnitPosition + "/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  downloadReportHistoryUnits = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.ReportHistoryUnit + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  downloadReportUnitRented = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.ReportUnitRented + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  downloadReportDailyOperational = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.ReportDailyOperational + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListBastExchange = (
    params: RequestQueryParamsModel
  ): Promise<ResponseBastExchange> => {
    return this.get<ResponseBastExchange>(Api.BastExchange, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailBastExchange = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<ResponseDetailBastExchange> => {
    return this.get<ResponseDetailBastExchange>(Api.BastExchange + `/${id}`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateBastExchange = (
    payload: RequestUpdateBastExchange,
    id: string
  ): Promise<boolean> => {
    return this.put<boolean, RequestUpdateBastExchange>(
      Api.BastExchange + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPrintChangeUnit = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.BastExchange + `/report/print/${id}`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPrintReportBapu = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.BastReady + `/report/bapu/print/${id}`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListBastWithdrawl = (
    params: RequestQueryParamsModel
  ): Promise<ResponseBastWithdrawl> => {
    return this.get<ResponseBastWithdrawl>(Api.BastReady + `/withdrawl`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailBastWithdrawl = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<DetailBastWithdrawl> => {
    return this.get<DetailBastWithdrawl>(Api.BastReady + `/withdrawl/${id}`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListBastHasBeenCreated = (
    params: RequestQueryParamsModel
  ): Promise<ResponseBastHasBeenCreated> => {
    return this.get<ResponseBastHasBeenCreated>(Api.BastReady + `/return`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailBastHasBeenCreated = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<ResponseDetailBastHasBeenCreated> => {
    return this.get<ResponseDetailBastHasBeenCreated>(
      Api.BastReady + `/return/${id}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListBastState = (
    params: RequestQueryParamsModel
  ): Promise<DataResponseBastState[]> => {
    return this.get<DataResponseBastState[]>(Api.BastReady + `/state`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListDoReadyToCreateBast = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListDoReady> => {
    return this.get<ResponseGetListDoReady>(Api.BastReady, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListBastByIc = (idIc: string): Promise<ResponseListBastByIc> => {
    return this.get<ResponseListBastByIc>(
      Api.BastReady + `/product/service/${idIc}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListDoReadyToPrintBast = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<ResponseDoReadyToPrint> => {
    return this.get<ResponseDoReadyToPrint>(Api.BastReady + `/${id}`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  printDoReadyToPrintBast = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.BastReady + `/report/bast/print/${id}`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListReportUnitMarginProduct(
    params: RequestQueryParamsModel
  ): Promise<MarginSalesHeadReportResponseDTO> {
    return this.get<MarginSalesHeadReportResponseDTO>(Api.ReportUnitMargin, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportUnitMarginProduct = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.ReportUnitMargin + `/report/download`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListReportInternalContract = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListReportInternalContract> => {
    return this.get<ResponseGetListReportInternalContract>(
      Api.InternalContract + "/report",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListInternalContractChangeLocation = (
    params
  ): Promise<ResponseListInternalContractChangeLocation> => {
    return this.get<ResponseListInternalContractChangeLocation>(
      Api.InternalContractChangeLocation,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailInternalContractChangeLocation = (
    id: string
  ): Promise<DetailInternalContractChangeLocation> => {
    return this.get<DetailInternalContractChangeLocation>(
      Api.InternalContractChangeLocation + "/" + id
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPrintInternalContractChangeLocation = (id: string): Promise<Blob> => {
    return this.get<Blob>(
      Api.InternalContractChangeLocation + "/report/print/" + id,
      { responseType: "arraybuffer" }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * @duplicate
   * new service {@linkcode internalContractService.detail}
   */
  getDetailInternalContract = (id: string): Promise<DetailInternalContract> => {
    return this.get<DetailInternalContract>(Api.InternalContract + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailHistoryInternalContract = (
    params: RequestQueryParamsModel
  ): Promise<ListDetailHistoryInternalContract> => {
    return this.get<ListDetailHistoryInternalContract>(
      Api.InternalContract + "/history",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getReportDownloadInternalContract = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.InternalContract + "/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getReportPrintInternalContract = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.InternalContract + "/report/print", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  cancelInternalContract = (id: string): Promise<string> => {
    return this.put<string, null>(Api.InternalContract + `/cancel/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  postInternalContract = (
    payload: RequestInternalContract
  ): Promise<string> => {
    return this.post<string, RequestInternalContract>(
      Api.InternalContract,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  putBastHasBeenCreated(
    payload: RequestBastHasBeenCreated,
    id: string
  ): Promise<ResponseDetailBastHasBeenCreated> {
    return this.put<
      ResponseDetailBastHasBeenCreated,
      RequestBastHasBeenCreated
    >(Api.BastReady + `/return/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  putInternalContract = (
    payload: RequestInternalContract,
    id: string
  ): Promise<string> => {
    return this.put<string, RequestInternalContract>(
      Api.InternalContract + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListSalesOrder = (
    params: RequestQueryParamsModel
  ): Promise<SalesOrderList> => {
    return this.get<SalesOrderList>(Api.Sales, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getListSalesOrderHeader = (
    params: RequestQueryParamsModel
  ): Promise<SalesOrderHeaderList> => {
    return this.get<SalesOrderHeaderList>(Api.SalesHeader, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListAvailableSalesOrder(
    params?: RequestQueryParamsModel
  ): Promise<SalesOrderHeaderList> {
    return this.get<SalesOrderHeaderList>(Api.AvailableSalesOrder, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @description old version of sales order tracking
   * @deprecated
   */
  getListSalesOrderTracking = (
    params: RequestQueryParamsModel
  ): Promise<SalesOrderListTracking> => {
    return this.get<SalesOrderListTracking>(Api.SalesTracking, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  public closePeriodSales(params: string): Promise<boolean> {
    return this.post<boolean, any>(Api.ClosePeriodSales + params)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createSalesOrder(
    payload: SalesOrderCreateRequestDto
  ): Promise<SalesOrderResponseDto> {
    return this.post<SalesOrderResponseDto, SalesOrderCreateRequestDto>(
      Api.Sales,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  closeSalesOrder(
    payload: SalesOrderCloseRequestDto,
    id: string
  ): Promise<void> {
    return this.put<void, SalesOrderCloseRequestDto>(
      Api.CloseSalesOrder + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateSalesOrder(
    payload: SalesOrderUpdateRequestDto,
    id: string
  ): Promise<SalesOrderResponseDto> {
    return this.put<SalesOrderResponseDto, SalesOrderUpdateRequestDto>(
      Api.Sales + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelSalesOrder(id: string): Promise<void> {
    return this.put<void>(Api.Sales + "/cancel/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitSalesOrder(
    payload: SalesOrderUpdateRequestDto,
    id: string
  ): Promise<SalesOrderSubmitResponseDto> {
    return this.put<SalesOrderSubmitResponseDto, SalesOrderUpdateRequestDto>(
      Api.SalesSubmit + "/" + id,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailSalesOrder(id: string): Promise<SalesOrderResponseDto> {
    return this.get<SalesOrderResponseDto>(`${Api.Sales}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailDraftReturnItem(id: string): Promise<SalesReturnResponseDTO> {
    return this.get<SalesReturnResponseDTO>(Api.GetDraftReturnItem + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  getPickingListList = (
    params: RequestQueryParamsModel
  ): Promise<PickingListList> => {
    return this.get<PickingListList>(Api.PickingList, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getPickingListChangeUnit = (
    params: RequestQueryParamsModel
  ): Promise<PickingListList> => {
    return this.get<PickingListList>(Api.PickingListChangeUnit, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getPickingListCreateDo = (
    params: RequestQueryParamsModel
  ): Promise<PickingListList> => {
    return this.get<PickingListList>(Api.PickingList + "/create/do", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getListAccessories = (): Promise<string[]> => {
    return this.get<string[]>(Api.ListAccessories)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * create new accessories for DO
   * @param payload payload
   * @returns promise
   */
  createAccessories = (
    payload: CreateAccessories
  ): Promise<CreateAccessories> => {
    return this.post<CreateAccessories, CreateAccessories>(
      Api.ListAccessories,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getReportPrintPickingList = (id: string[]): Promise<Blob> => {
    return this.get<Blob>(Api.PickingListPrint + `/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createPickingList(payload: RequestPickingList): Promise<string> {
    return this.post<string, RequestPickingList>(Api.PickingList, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailPickingList = (id: string): Promise<PickingListDetail> => {
    return this.get<PickingListDetail>(Api.PickingList + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDeliveryOrderList = (
    params: RequestQueryParamsModel
  ): Promise<DeliveryOrderList> => {
    return this.get<DeliveryOrderList>(Api.DeliveryOrder, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @description get delivery orders filter by sales order status is RENT
   */
  getDeliveryOrderForBAST = (
    params: RequestQueryParamsModel
  ): Promise<DeliveryOrderList> => {
    return this.get<DeliveryOrderList>(`${Api.DeliveryOrder}/available/bast`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListAvailableDeliveryOrder = (
    params: RequestQueryParamsModel
  ): Promise<DeliveryOrderList> => {
    return this.get<DeliveryOrderList>(Api.AvailableDeliveryOrder, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListAvailableDeliveryOrderForReturnCust(
    params: RequestQueryParamsModel
  ): Promise<DeliveryOrderList> {
    return this.get<DeliveryOrderList>(
      Api.AvailableDeliveryOrderForReturnCust,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getReportPrintDeliveryOrder = (id: string): Promise<Blob> => {
    return this.get<Blob>(Api.DeliveryOrderPrint + `/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getSalesOrderTrackingDetail(
    docNumber: string
  ): Promise<SalesOrderDataTracking[]> {
    const param = {
      documentReference: docNumber,
    };
    return this.get<SalesOrderDataTracking[]>(`${Api.Sales}/tracking/detail`, {
      params: param,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadSalesOrderReport(param: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(`${Api.SalesReportDownload}`, {
      params: param,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getSalesOrderReport(
    param: RequestQueryParamsModel
  ): Promise<Array<SalesOrderReportResponseDto>> {
    return this.get<Array<SalesOrderReportResponseDto>>(`${Api.Sales}/report`, {
      params: param,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listTrackingIc = (
    params: RequestQueryParamsModel
  ): Promise<ResponseTrackingIc> => {
    return this.get<ResponseTrackingIc>(Api.TrackingIc, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listDropdownTrackingIc = (
    params: RequestQueryParamsModel
  ): Promise<string[]> => {
    return this.get<string[]>(Api.TrackingIc + "/list", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  downloadTrackingIc(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.TrackingIc + "/report/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * Get list of available sales order for creating a picking list
   * @param params query param
   * @returns Promise
   */
  public getAvailableSalesOrder(
    params: RequestQueryParamsModel
  ): Promise<SalesOrderList> {
    return this.get<SalesOrderList>(`${Api.Sales}/available/pl`, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @description new version of sales order tracking
   */
  getSalesOrderTracking(
    params?: RequestParamsGetListSalesOrderTracking
  ): Promise<ISalesOrderTrackingGroupResponseDTO> {
    return this.get<ISalesOrderTrackingGroupResponseDTO>(
      Api.SalesTracking + "/list",
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const salesOrderServices = new SalesOrderServices();
