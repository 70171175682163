import vue from "vue";
import InvoiceApHeader from "./invoices-ap/InvoiceAPHeader.vue";
import TabApplyPrepaymentInvoiceAp from "./invoices-ap/TabApplyPrepaymentInvoiceAp.vue";
import TabDetailsInvoiceAp from "./invoices-ap/TabDetailsInvoiceAp.vue";
import TabStatusInvoiceAp from "./invoices-ap/TabStatusInvoiceAp.vue";
import TabTaxDetailsInvoiceAp from "./invoices-ap/TabTaxDetailsInvoiceAp.vue";
// AP Prepayment
import TableDetailsPrepayment from "./invoices-prepayment-debit-memo/TableDetailsPrepayment.vue";

vue.component("TabDetailsInvoiceAp", TabDetailsInvoiceAp);
vue.component("TabTaxDetailsInvoiceAp", TabTaxDetailsInvoiceAp);
vue.component("TabApplyPrepaymentInvoiceAp", TabApplyPrepaymentInvoiceAp);
vue.component("TabStatusInvoiceAp", TabStatusInvoiceAp);
vue.component("InvoiceApHeader", InvoiceApHeader);

const TableStatusPrepayment = () =>
  import(
    /* webpackPrefetch: true */ "./invoices-prepayment-debit-memo/TableStatusPrepayment.vue"
  );
const TableTaxDetailsPrepayment = () =>
  import(
    /* webpackPrefetch: true */ "./invoices-prepayment-debit-memo/TableTaxDetailsPrepayment.vue"
  );
vue.component("TableDetailsPrepayment", TableDetailsPrepayment);
vue.component("TableStatusPrepayment", TableStatusPrepayment);
vue.component("TableTaxDetailsPrepayment", TableTaxDetailsPrepayment);
