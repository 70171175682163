import { StorageKeys } from "@/models/constant/enums/storage.enum";
import {
  InvoiceAPCreateDTO,
  InvoiceAPLineResponse,
  InvoiceAPPrepaymentResponse,
  InvoiceAPResponse,
} from "@/models/interface/invoiceAp.interface";
import LocalStorageService from "@/services/LocalStorage.service";

export interface IForm {
  source: string;
  branch: string;
  currency: string;
  supplierName: string;
  supplierShipAddress: string;
  supplierBillAddress: string;
  termOfPayment: string;
  findPurchaseOrder: string[];
  findGoodReceipt: string[];
  taxType: string;
  invoiceNumber: string;
  invoiceSupplierNo: string;
  invoiceDate: string;
  accountingDate: string;
  invoiceReceivedDate: string;
  rate: number;
  journalNumber: string;
  journalId: string;
  payablesAccount: string;
  invoiceDescription: string;
  statusInvoice: string;
  taxRegistrationNumber: string;
  taxRegistrationName: string;
  taxInvoiceDate: string;
  taxInvoiceNumber: string;
  total?: number;
  prepaymentUsed?: number;
  remainingInvoiceAmount?: number;
  apPayment?: number;
}

export const initInvoiceAPCreateDTO = (): InvoiceAPCreateDTO => ({
  invoiceSource: "",
  invoiceType: "",
  branchWarehouseId: "",
  currency: "",
  currencyRate: 1,
  supplierId: "",
  supplierShipToAddress: "",
  supplierBillToAddress: "",
  taxType: "",
  termOfPayment: 0,
  invoiceDate: "",
  accountingDate: "",
  invoiceReceivedDate: "",
  payablesAccountId: "",
  description: "",
  applyPrepayment: {
    deletedPrepaymentLineIds: [],
    prepaymentLines: [],
  },
  invoiceAPLines: [],
  taxRegistrationNumber: "",
  taxRegistrationName: "",
  taxInvoiceDate: "",
  taxInvoiceNumber: "",
  discountValue: 0,
  percentDiscount: 0,
  invoiceSupplierNo: "",
  status: null,
});

export const initInvoiceAPResponseDTO = (): InvoiceAPResponse => ({
  accountingDate: "",
  applyPrepayment: {
    prepaymentLines: [],
  },
  branchWarehouseId: "",
  branchWarehouseName: "",
  createdDate: "",
  currency: "",
  currencyRate: 1,
  description: "",
  discountValue: 0,
  documentNumber: "",
  goodReceipts: [],
  grandTotal: 0,
  id: "",
  invoiceAPLines: [],
  invoiceAPPaymentDetails: [],
  invoiceAmount: 0,
  invoiceDate: "",
  invoiceGrossAmount: 0,
  invoiceIncomeTaxAmount: 0,
  invoiceReceivedDate: "",
  invoiceSource: "",
  invoiceSupplierNo: "",
  invoiceSubtotal: 0,
  invoiceTaxAmount: 0,
  invoiceType: "",
  journalId: "",
  journalNo: "",
  modifiedDate: "",
  paidAmount: 0,
  payablesAccount: "",
  payablesAccountId: "",
  percentDiscount: 0,
  prepaymentUsed: 0,
  purchaseOrders: [],
  status: "",
  supplierBillToAddress: "",
  supplierId: "",
  supplierName: "",
  supplierShipToAddress: "",
  taxInvoiceDate: "",
  taxInvoiceNumber: "",
  taxRegistrationName: "",
  taxRegistrationNumber: "",
  taxType: "",
  termOfPayment: 0,
  total: 0,
  totalTax: 0,
  remainingInvoiceAmount: 0,
});

export const initFormHeader = (): IForm => ({
  source: "",
  branch: "",
  currency: LocalStorageService.load(StorageKeys.BASE_CURRENCY) || "",
  supplierName: "",
  supplierShipAddress: "",
  supplierBillAddress: "",
  termOfPayment: "",
  findPurchaseOrder: [],
  findGoodReceipt: [],
  taxType: "",
  invoiceNumber: "",
  invoiceSupplierNo: "",
  invoiceDate: "",
  accountingDate: "",
  invoiceReceivedDate: "",
  rate: 0,
  journalNumber: "",
  journalId: "",
  payablesAccount: "",
  invoiceDescription: "",
  statusInvoice: "",
  taxRegistrationNumber: "",
  taxRegistrationName: "",
  taxInvoiceDate: "",
  taxInvoiceNumber: "",
});

type TypeOmitInvoiceAPLineResponse = "createdDate" | "modifiedDate";

export type InvoiceAPLine = {
  no: string;
  taxRate: number;
  generated: boolean;
  gross: number;
  grossAfterDiscount: number;
  uniqueId: string;
} & Omit<InvoiceAPLineResponse, TypeOmitInvoiceAPLineResponse>;

export const initInvoiceAPRow = (): InvoiceAPLine => ({
  no: "",
  baseAmount: 0,
  description: "",
  discountValue: 0,
  expenseAccountId: "",
  expenseAccount: "",
  goodReceiptLineId: null,
  incomeTaxId: "",
  merk: "",
  percentDiscount: 0,
  price: 0,
  productId: "",
  purchaseOrderLineId: null,
  qty: 0,
  id: null,
  subTotal: 0,
  taxCode: "",
  taxValue: 0,
  taxRate: 0,
  uomId: "",
  uom: "",
  partName: "",
  partNumber: "",
  documentReference: "",
  incomeTax: "",
  incomeTaxRate: 0,
  incomeTaxValue: 0,
  generated: false,
  gross: 0,
  grossAfterDiscount: 0,
  documentReferenceId: "",
  uniqueId: "",
  qtyAvailableForReturn: 0,
});

export const initInvoiceAPPrepayment = (): InvoiceAPPrepaymentResponse => ({
  prepaymentLines: [],
});
