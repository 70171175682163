var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12 } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.invoiceType.label)
                  }
                },
                [
                  _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_invoice_ap")))
                  ])
                ]
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.source.label)
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.source.decorator,
                          expression: "formRules.source.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.source.name,
                        placeholder: _vm.$t(_vm.formRules.source.placeholder),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": false,
                        loading: _vm.loading.source,
                        allowClear: true,
                        disabled: _vm.allowEdit
                      },
                      on: {
                        search: function(value) {
                          return _vm.getSource(value)
                        },
                        change: _vm.handleChangeSource
                      }
                    },
                    _vm._l(_vm.dataSource, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.value) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.value) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.branch.label)
                  }
                },
                [
                  _c("SelectBranch", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.branch.decorator,
                        expression: "formRules.branch.decorator"
                      }
                    ],
                    attrs: { disabled: _vm.allowEdit, "label-in-value": "" },
                    on: { change: _vm.handleChangeBranch }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.currency.label)
                  }
                },
                [
                  _c("currency-select", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.currency.decorator,
                        expression: "formRules.currency.decorator"
                      }
                    ],
                    attrs: { disabled: _vm.allowEdit || _vm.disabled.currency },
                    on: { currencyChange: _vm.handleChangeCurrency }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isIDR,
                      expression: "!isIDR"
                    }
                  ],
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.rate.label)
                  }
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.rate.decorator,
                        expression: "formRules.rate.decorator"
                      }
                    ],
                    staticClass: "w-100",
                    attrs: {
                      name: _vm.formRules.rate.name,
                      disabled: _vm.allowEdit,
                      placeholder: _vm.$t(_vm.formRules.rate.placeholder),
                      formatter: _vm.formatterNumber,
                      parser: _vm.reverseFormatNumber,
                      min: 0,
                      precision: _vm.storeBaseDecimalPlace
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.supplierName.label)
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.supplierName.decorator,
                          expression: "formRules.supplierName.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.supplierName.name,
                        placeholder: _vm.$t(
                          _vm.formRules.supplierName.placeholder
                        ),
                        "show-search": "",
                        "filter-option": false,
                        loading: _vm.loading.supplier,
                        "allow-clear": "",
                        disabled: _vm.allowEdit
                      },
                      on: {
                        search: function(value) {
                          return _vm.getSupplier(value)
                        },
                        change: _vm.handleChangeSupplier
                      }
                    },
                    _vm._l(_vm.dataSupplier, function(data) {
                      return _c(
                        "a-select-option",
                        { key: data.id, attrs: { value: data.id } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.fullName) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.fullName) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.supplierShipAddress.label)
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.supplierShipAddress.decorator,
                          expression: "formRules.supplierShipAddress.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.supplierShipAddress.name,
                        placeholder: _vm.$t(
                          _vm.formRules.supplierShipAddress.placeholder
                        ),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        allowClear: true,
                        disabled: _vm.allowEdit
                      }
                    },
                    _vm._l(_vm.dataSupplierShipAddress, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.address } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.address) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.address) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.supplierBillAddress.label)
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.supplierBillAddress.decorator,
                          expression: "formRules.supplierBillAddress.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.supplierBillAddress.name,
                        placeholder: _vm.$t(
                          _vm.formRules.supplierBillAddress.placeholder
                        ),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        allowClear: true,
                        disabled: _vm.allowEdit
                      }
                    },
                    _vm._l(_vm.dataSupplierBillAddress, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.address } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.address) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.address) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.termOfPayment.label)
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.termOfPayment.decorator,
                          expression: "formRules.termOfPayment.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.termOfPayment.name,
                        placeholder: _vm.$t(
                          _vm.formRules.termOfPayment.placeholder
                        ),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": false,
                        loading: _vm.loading.top,
                        allowClear: true,
                        disabled: _vm.allowEdit
                      },
                      on: {
                        search: function(value) {
                          return _vm.getTermOfPayment(value)
                        }
                      }
                    },
                    _vm._l(_vm.dataTermOfPayment, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.value) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.value) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.storeAPDetail.purchaseOrders.length,
                      expression: "storeAPDetail.purchaseOrders.length"
                    }
                  ],
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t("lbl_purchase_order")
                  }
                },
                _vm._l(_vm.storeAPDetail.purchaseOrders, function(item) {
                  return _c("a-tag", { key: item.id }, [
                    _vm._v(" " + _vm._s(item.documentNumber || "-") + " ")
                  ])
                }),
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.storeAPDetail.goodReceipts.length,
                      expression: "storeAPDetail.goodReceipts.length"
                    }
                  ],
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t("lbl_good_receipt")
                  }
                },
                _vm._l(_vm.storeAPDetail.goodReceipts, function(item) {
                  return _c("a-tag", { key: item.id, staticClass: "ml-2" }, [
                    _vm._v(" " + _vm._s(item.documentNumber || "-") + " ")
                  ])
                }),
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.shouldGenerateFromPO && !_vm.allowEdit,
                      expression: "shouldGenerateFromPO && !allowEdit"
                    }
                  ],
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.findPurchaseOrder.label)
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        value: _vm.storeAPForm.findPurchaseOrder,
                        name: _vm.formRules.findPurchaseOrder.name,
                        placeholder: _vm.$t(
                          _vm.formRules.findPurchaseOrder.placeholder
                        ),
                        "show-search": "",
                        "filter-option": false,
                        loading: _vm.loading.findPO,
                        "allow-clear": "",
                        mode: "multiple"
                      },
                      on: {
                        search: function(value) {
                          return _vm.searchPO(value)
                        },
                        deselect: function(e) {
                          return _vm.onDeselectDoc(e, "PO")
                        },
                        change: _vm.onChangeFindPO
                      }
                    },
                    _vm._l(_vm.dataFindPurchaseOrder, function(data) {
                      return _c(
                        "a-select-option",
                        { key: data.value, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.label) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.label) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled: _vm.allowEdit,
                        type: "primary",
                        block: "",
                        loading: _vm.loading.generate
                      },
                      on: { click: _vm.handleGenerate }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_generate")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.shouldGenerateFromGR && !_vm.allowEdit,
                      expression: "shouldGenerateFromGR && !allowEdit"
                    }
                  ],
                  attrs: {
                    "label-align": "left",
                    label: _vm.$t(_vm.formRules.findGoodReceipt.label)
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        value: _vm.storeAPForm.findGoodReceipt,
                        name: _vm.formRules.findGoodReceipt.name,
                        placeholder: _vm.$t(
                          _vm.formRules.findGoodReceipt.placeholder
                        ),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": false,
                        loading: _vm.loading.findGR,
                        mode: "multiple"
                      },
                      on: {
                        search: function(value) {
                          return _vm.searchGR(value)
                        },
                        deselect: function(e) {
                          return _vm.onDeselectDoc(e, "GR")
                        },
                        change: _vm.onChangeFindGR
                      }
                    },
                    _vm._l(_vm.dataFindGoodReceipt, function(data) {
                      return _c(
                        "a-select-option",
                        { key: data.value, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.label) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.label) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled: _vm.allowEdit,
                        type: "primary",
                        block: "",
                        loading: _vm.loading.generate
                      },
                      on: { click: _vm.handleGenerate }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_generate")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12 } },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t(_vm.formRules.taxType.label) } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.taxType.decorator,
                          expression: "formRules.taxType.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.taxType.name,
                        placeholder: _vm.$t(_vm.formRules.taxType.placeholder),
                        loading: _vm.loading.taxType,
                        disabled: _vm.allowEdit
                      },
                      on: { change: _vm.onChangeTaxType }
                    },
                    _vm._l(_vm.dataTaxType, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.value) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.value) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t(_vm.formRules.invoiceNumber.label) } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.invoiceNumber.decorator,
                        expression: "formRules.invoiceNumber.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.invoiceNumber.name,
                      placeholder: _vm.$t(
                        _vm.formRules.invoiceNumber.placeholder
                      ),
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.invoiceSupplierNo.label)
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.invoiceSupplierNo.decorator,
                        expression: "formRules.invoiceSupplierNo.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.invoiceSupplierNo.name,
                      placeholder: _vm.$t(
                        _vm.formRules.invoiceSupplierNo.placeholder
                      ),
                      disabled: _vm.allowEdit
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t(_vm.formRules.invoiceDate.label) } },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.invoiceDate.decorator,
                        expression: "formRules.invoiceDate.decorator"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      name: _vm.formRules.invoiceDate.name,
                      placeholder: _vm.$t(
                        _vm.formRules.invoiceDate.placeholder
                      ),
                      format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                      disabled: _vm.allowEdit,
                      "disabled-date": _vm.disabledDate,
                      "show-time": ""
                    },
                    on: { change: _vm.onChangeInvoiceDate }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: { label: _vm.$t(_vm.formRules.accountingDate.label) }
                },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      value: _vm.accountingDate,
                      name: _vm.formRules.accountingDate.name,
                      placeholder: _vm.$t(
                        _vm.formRules.accountingDate.placeholder
                      ),
                      format: _vm.DEFAULT_DATE_FORMAT,
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.invoiceReceivedDate.label)
                  }
                },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.invoiceReceivedDate.decorator,
                        expression: "formRules.invoiceReceivedDate.decorator"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      name: _vm.formRules.invoiceReceivedDate.name,
                      placeholder: _vm.$t(
                        _vm.formRules.invoiceReceivedDate.placeholder
                      ),
                      format: _vm.DEFAULT_DATE_FORMAT,
                      "disabled-date": _vm.disabledDateInvoiceReceived,
                      disabled: _vm.allowEdit
                    }
                  })
                ],
                1
              ),
              _vm.hasPrivilegeAccounting && !!_vm.storeAPForm.journalId
                ? _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.journalNumber.label)
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "generaljournal.detail",
                              params: { id: _vm.storeAPForm.journalId }
                            }
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "link" } }, [
                            _vm._v(_vm._s(_vm.storeAPForm.journalNumber || "-"))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                {
                  attrs: { label: _vm.$t(_vm.formRules.payablesAccount.label) }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.payablesAccount.decorator,
                          expression: "formRules.payablesAccount.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.payablesAccount.name,
                        placeholder: _vm.$t(
                          _vm.formRules.payablesAccount.placeholder
                        ),
                        showSearch: true,
                        "option-filter-prop": "children",
                        "filter-option": false,
                        loading: _vm.loading.payableAccount,
                        allowClear: true,
                        disabled: _vm.allowEdit
                      },
                      on: {
                        search: function(value) {
                          return _vm.debounce(function() {
                            return _vm.getPayablesAccount({
                              currencyCode: _vm.storeAPForm.currency,
                              valueSearch: value
                            })
                          })
                        }
                      }
                    },
                    _vm._l(_vm.dataPayablesAccount, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.id } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(data.code + " " + data.description) +
                                    " "
                                )
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(data.code + " " + data.description) +
                                  " "
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.invoiceDescription.label)
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.invoiceDescription.decorator,
                        expression: "formRules.invoiceDescription.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.invoiceDescription.name,
                      placeholder: _vm.$t(
                        _vm.formRules.invoiceDescription.placeholder
                      ),
                      disabled: _vm.allowEdit
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.storeAPForm.statusInvoice,
                      expression: "storeAPForm.statusInvoice"
                    }
                  ],
                  attrs: { label: _vm.$t(_vm.formRules.statusInvoice.label) }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.statusInvoice.decorator,
                        expression: "formRules.statusInvoice.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.statusInvoice.name,
                      placeholder: _vm.$t(
                        _vm.formRules.statusInvoice.placeholder
                      ),
                      disabled: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }