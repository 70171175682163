import { RouteConfig } from 'vue-router';

// Models
import { config } from '@/models/constant/routes.constant';

const router: RouteConfig = {
    path: config.USER_ROUTE.path,
    name: config.USER_ROUTE.name,
    meta: config.USER_ROUTE.meta,
    component: {
        render(c) { return c("router-view") }
    },
    children: [
        {
            path: config.LIST_USER_ROUTE.path,
            name: config.LIST_USER_ROUTE.name,
            meta: config.LIST_USER_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ListUsers.vue")
        },
        {
            path: config.CREATE_USER_ROUTE.path,
            name: config.CREATE_USER_ROUTE.name,
            meta: config.CREATE_USER_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./CreateUser.vue")
        },
        {
            path: config.EDIT_USER_ROUTE.path,
            name: config.EDIT_USER_ROUTE.name,
            meta: config.EDIT_USER_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./CreateUser.vue")
        },
        {
            path: config.CHANGE_PASSWORD_ROUTE.path,
            name: config.CHANGE_PASSWORD_ROUTE.name,
            meta: config.CHANGE_PASSWORD_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./Changepassword.vue")
        },
        {
            path: config.LIST_MENU_ROUTE.path,
            name: config.LIST_MENU_ROUTE.name,
            meta: config.LIST_MENU_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ListMenu.vue")
        },
        {
            path: config.LIST_ROLE_ROUTE.path,
            name: config.LIST_ROLE_ROUTE.name,
            meta: config.LIST_ROLE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ListRole.vue")
        },
        {
            path: config.LIST_PRIVILEGE_ROUTE.path,
            name: config.LIST_PRIVILEGE_ROUTE.name,
            meta: config.LIST_PRIVILEGE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ListPrivilege.vue")
        },
    ]
};

export default router;
