// Models
import { config } from "@/models/constant/routes.constant";
import { RouteConfig } from "vue-router";

const index: RouteConfig = {
  path: config.SALES_ROUTE.path,
  name: config.SALES_ROUTE.name,
  meta: config.SALES_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.CLOSEPERIOD_ROUTE.path,
      name: config.CLOSEPERIOD_ROUTE.name,
      meta: config.CLOSEPERIOD_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./OpenClose.vue"),
    },
    {
      path: config.SALESORDER_ROUTE.path,
      name: config.SALESORDER_ROUTE.name,
      meta: config.SALESORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./sales-order/SalesOrder.vue"),
    },
    {
      path: config.SALES_ORDER_CREATE_ROUTE.path,
      name: config.SALES_ORDER_CREATE_ROUTE.name,
      meta: config.SALES_ORDER_CREATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./sales-order/FormPage.vue"),
    },
    {
      path: config.SALES_ORDER_EDIT_ROUTE.path,
      name: config.SALES_ORDER_EDIT_ROUTE.name,
      meta: config.SALES_ORDER_EDIT_ROUTE.meta,
      props: true,
      component: () =>
        import(/* webpackPrefetch: true */ "./sales-order/FormPage.vue"),
    },
    {
      path: config.SALES_ORDER_DETAIL_ROUTE.path,
      name: config.SALES_ORDER_DETAIL_ROUTE.name,
      meta: config.SALES_ORDER_DETAIL_ROUTE.meta,
      props: true,
      component: () =>
        import(/* webpackPrefetch: true */ "./sales-order/DetailPage.vue"),
    },
    {
      path: config.PICKINGLIST_ROUTE.path,
      name: config.PICKINGLIST_ROUTE.name,
      meta: config.PICKINGLIST_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sales-transactions/ListPickingList.vue"
        ),
    },
    // {
    //     path: config.PICKINGLISTCREATE_ROUTE.path,
    //     name: config.PICKINGLISTCREATE_ROUTE.name,
    //     meta: config.PICKINGLISTCREATE_ROUTE.meta,
    //     component: () => import(/* webpackPrefetch: true */"./sales-transactions/PickingList.vue")
    // },
    {
      path: config.PICKINGLISTEDIT_ROUTE.path,
      name: config.PICKINGLISTEDIT_ROUTE.name,
      meta: config.PICKINGLISTEDIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sales-transactions/PickingList.vue"
        ),
    },
    {
      path: config.DELIVERYORDERVIEW_ROUTE.path,
      name: config.DELIVERYORDERVIEW_ROUTE.name,
      meta: config.DELIVERYORDERVIEW_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sales-transactions/ListDeliveryOrder.vue"
        ),
    },
    {
      path: config.DELIVERYORDERCREATE_ROUTE.path,
      name: config.DELIVERYORDERCREATE_ROUTE.name,
      meta: config.DELIVERYORDERCREATE_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sales-transactions/DeliveryOrder.vue"
        ),
    },
    {
      path: config.DELIVERYORDEREDIT_ROUTE.path,
      name: config.DELIVERYORDEREDIT_ROUTE.name,
      meta: config.DELIVERYORDEREDIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./sales-transactions/DeliveryOrder.vue"
        ),
    },
    {
      path: config.INTERNAL_CONTRACT_ROUTE.path,
      name: config.INTERNAL_CONTRACT_ROUTE.name,
      meta: config.INTERNAL_CONTRACT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./internal-contract/ListInternalContract.vue"
        ),
    },
    {
      path: config.CREATE_INTERNAL_CONTRACT_ROUTE.path,
      name: config.CREATE_INTERNAL_CONTRACT_ROUTE.name,
      meta: config.CREATE_INTERNAL_CONTRACT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./internal-contract/CreateInternalContract.vue"
        ),
    },
    {
      path: config.VIEW_INTERNAL_CONTRACT_ROUTE.path,
      name: config.VIEW_INTERNAL_CONTRACT_ROUTE.name,
      meta: config.VIEW_INTERNAL_CONTRACT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./internal-contract/CreateInternalContract.vue"
        ),
    },
    //#region new route to internal contract refactoring
    // {
    //   path: config.CREATE_INTERNAL_CONTRACT_ROUTE.path,
    //   name: config.CREATE_INTERNAL_CONTRACT_ROUTE.name,
    //   meta: config.CREATE_INTERNAL_CONTRACT_ROUTE.meta,
    //   component: () =>
    //     import(
    //       /* webpackPrefetch: true */ "./internal-contract/InternalContractIndex.vue"
    //     ),
    // },
    //#endregion
    {
      path: config.CETAK_SERAH_TERIMA_ROUTE.path,
      name: config.CETAK_SERAH_TERIMA_ROUTE.name,
      meta: config.CETAK_SERAH_TERIMA_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-serah-terima/CetakSerahTerima.vue"
        ),
    },
    {
      path: config.VIEW_PRINT_BAST_ROUTE.path,
      name: config.VIEW_PRINT_BAST_ROUTE.name,
      meta: config.VIEW_PRINT_BAST_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-serah-terima/ViewPrintBAST.vue"
        ),
    },
    {
      path: config.BALIKAN_SERAH_TERIMA_ROUTE.path,
      name: config.BALIKAN_SERAH_TERIMA_ROUTE.name,
      meta: config.BALIKAN_SERAH_TERIMA_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-serah-terima/BalikanSerahTerima.vue"
        ),
    },
    {
      path: config.CREATE_BALIKAN_SERAH_TERIMA_ROUTE.path,
      name: config.CREATE_BALIKAN_SERAH_TERIMA_ROUTE.name,
      meta: config.CREATE_BALIKAN_SERAH_TERIMA_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-serah-terima/CreateBASTBalikan.vue"
        ),
    },
    {
      path: config.TARIK_UNIT_ROUTE.path,
      name: config.TARIK_UNIT_ROUTE.name,
      meta: config.TARIK_UNIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-tarik-unit/TarikUnit.vue"
        ),
    },
    {
      path: config.CETAK_TARIK_UNIT_ROUTE.path,
      name: config.CETAK_TARIK_UNIT_ROUTE.name,
      meta: config.CETAK_TARIK_UNIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-tarik-unit/CetakTarikUnit.vue"
        ),
    },
    {
      path: config.TUKAR_UNIT_ROUTE.path,
      name: config.TUKAR_UNIT_ROUTE.name,
      meta: config.TUKAR_UNIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-tukar-unit/TukarUnit.vue"
        ),
    },
    {
      path: config.CETAK_TUKAR_UNIT_ROUTE.path,
      name: config.CETAK_TUKAR_UNIT_ROUTE.name,
      meta: config.CETAK_TUKAR_UNIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-tukar-unit/CetakTukarUnit.vue"
        ),
    },
    {
      path: config.BA_MOVE_LOCATION_ROUTE.path,
      name: config.BA_MOVE_LOCATION_ROUTE.name,
      meta: config.BA_MOVE_LOCATION_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-pindah-lokasi/PindahLokasi.vue"
        ),
    },
    {
      path: config.CETAK_BA_MOVE_LOCATION_ROUTE.path,
      name: config.CETAK_BA_MOVE_LOCATION_ROUTE.name,
      meta: config.CETAK_BA_MOVE_LOCATION_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./berita-acara/ba-pindah-lokasi/CetakPindahLokasi.vue"
        ),
    },
    {
      path: config.REPORTS_INTERNAL_CONTRACT_ROUTE.path,
      name: config.REPORTS_INTERNAL_CONTRACT_ROUTE.name,
      meta: config.REPORTS_INTERNAL_CONTRACT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/InternalContract.vue"),
    },
    {
      path: config.REPORTS_SALES_ORDER_ROUTE.path,
      name: config.REPORTS_SALES_ORDER_ROUTE.name,
      meta: config.REPORTS_SALES_ORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/SalesOrderReport.vue"),
    },
    {
      path: config.REPORTS_UNIT_POSITION_ROUTE.path,
      name: config.REPORTS_UNIT_POSITION_ROUTE.name,
      meta: config.REPORTS_UNIT_POSITION_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/unit-position/UnitPositionReport.vue"
        ),
    },
    {
      path: config.REPORTS_HISTORY_UNITS_ROUTE.path,
      name: config.REPORTS_HISTORY_UNITS_ROUTE.name,
      meta: config.REPORTS_HISTORY_UNITS_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/history-unit/HistoryUnits.vue"
        ),
    },
    {
      path: config.REPORTS_OF_UNIT_POSITION_ROUTE.path,
      name: config.REPORTS_OF_UNIT_POSITION_ROUTE.name,
      meta: config.REPORTS_OF_UNIT_POSITION_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/report-of-unit-position/ReportOfUnitPosition.vue"
        ),
    },
    {
      path: config.REPORTS_OF_UNIT_RENTED.path,
      name: config.REPORTS_OF_UNIT_RENTED.name,
      meta: config.REPORTS_OF_UNIT_RENTED.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportsUnitRented.vue"),
    },
    {
      path: config.TRACKINGIC_ROUTE.path,
      name: config.TRACKINGIC_ROUTE.name,
      meta: config.TRACKINGIC_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/TrackingIc.vue"),
    },
    {
      path: config.MARGIN_ROUTE.path,
      name: config.MARGIN_ROUTE.name,
      meta: config.MARGIN_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/Margin.vue"),
    },
    {
      path: config.UNIT_MARGIN_ROUTE.path,
      name: config.UNIT_MARGIN_ROUTE.name,
      meta: config.UNIT_MARGIN_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/UnitMargin.vue"),
    },
  ],
};

export default index;
