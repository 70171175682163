import { defineFilename } from "@/helpers/common";

const useBlob = () => {
  const toObjectUrl = response => {
    return window.URL.createObjectURL(new Blob([response]));
  };

  const toDownload = (response, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");

    const [name, ext] = fileName.split(".");
    const formatted = defineFilename(name) + "." + ext;

    link.href = url;
    link.setAttribute("download", formatted); //or any other extension
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };
  return { toDownload, toObjectUrl };
};

export default useBlob;
