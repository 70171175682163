























































































































































































































































































































































































































































import CurrencySelect from "@/components/custom/select/CurrencySelect.vue";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import { findPercentage, isNotUnique, removeDuplicate } from "@/helpers/common";
import { debounce, debounceProcess } from "@/helpers/debounce";
import { useCoaListFindById, useProductExpenseAccount } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { StorageKeys } from "@/models/constant/enums/storage.enum";
import { OptionModel } from "@/models/constant/interface/common.interface";
import {
  DATE_TIME_HOURS_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { INVOICE_AP_SOURCE } from "@/models/enums/invoice-ap.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  PREFERENCE_ACCOUNT_SETUP_KEY,
  PREFERENCE_FEATURE_KEY,
} from "@/models/enums/preference.enum";
import {
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_TYPE,
} from "@/models/enums/purchase-order.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  AddressDataList,
  ContactData,
  ResponseContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  InvoiceAPGoodReceiptResponse,
  InvoiceAPPurchaseOrderResponse,
  InvoiceAPResponse,
} from "@/models/interface/invoiceAp.interface";
import { GoodsReceiveChecklistResponse } from "@/models/interface/logistic.interface";
import {
  ResponseDetailPurchaseOrder,
  ResponseGetListPurchaseOrder,
} from "@/models/interface/purchase.interface";
import { ReceivingItemDraftResponseDTO } from "@/models/interface/receive-item.interface";
import {
  DataCoa,
  DataMasterCurrency,
  ResponsePreference,
} from "@/models/interface/settings.interface";
import { IAuthorities } from "@/models/interfaces/auth.interface";
import { contactServices } from "@/services/contact.service";
import LocalStorageService from "@/services/LocalStorage.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { purchaseServices } from "@/services/purchase.service";
import { settingsServices } from "@/services/settings.service";
import {
  IForm,
  initFormHeader,
  initInvoiceAPPrepayment,
  InvoiceAPLine,
} from "@/store/resources/invoice-ap.resource";
import { LabelInValue } from "@/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import Decimal from "decimal.js-light";
import moment, { Moment } from "moment";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

const SOURCES_TO_GENERATE_FROM_PO: INVOICE_AP_SOURCE[] = [
  INVOICE_AP_SOURCE.SERVICE,
];

const SOURCES_TO_GENERATE_FROM_GR: INVOICE_AP_SOURCE[] = [
  INVOICE_AP_SOURCE.RENT_TO_RENT,
  INVOICE_AP_SOURCE.SPAREPART,
  INVOICE_AP_SOURCE.OTHERS,
  INVOICE_AP_SOURCE.UNIT,
];

@Component({
  inject: {
    headerForm: {
      from: "headerForm",
    },
  },
  components: {
    SelectBranch,
    "currency-select": CurrencySelect,
  },
  computed: {
    ...mapState({
      storePreference: (st: any) =>
        st.preferenceStore.dataPreference as ResponsePreference[],
      storeAPForm: (st: any) => st.invoiceApStore.form as IForm,
      storeAPDetail: (st: any) =>
        st.invoiceApStore.detailInvoiceAP as InvoiceAPResponse,
      storeInvoiceAPLines: (st: any) =>
        st.invoiceApStore.invoiceAPLines as InvoiceAPLine[],
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      getPreferenceByKey: "preferenceStore/GET_PREFERENCE_BY_KEY",
      allowEdit: "invoiceApStore/GET_ALLOW_TO_EDIT",
    }),
  },
  methods: {
    debounce,
    ...mapMutations({
      setAPForm: "invoiceApStore/SET_FORM_HEADER",
      setInvoiceAPLines: "invoiceApStore/SET_INVOICE_AP_LINES",
      setInvoiceAPDeletedLine: "invoiceApStore/SET_INVOICE_AP_DELETED_LINE",
      setInvoiceAPPrepayments: "invoiceApStore/SET_INVOICE_AP_PREPAYMENTS",
    }),
    ...mapActions({
      setExpenseAccountProducts:
        "invoiceApStore/SET_INVOICE_AP_LINE_EXPENSE_ACCOUNT",
      recalculatePricing: "invoiceApStore/RECALCULATE_PRICING",
      pushDeletedInvoiceAPLine: "invoiceApStore/PUSH_DELETED_INVOICE_AP_LINE",
    }),
  },
})
export default class InvoiceAPHeader extends Mixins(MNotificationVue) {
  headerForm!: WrappedFormUtils;

  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;

  dataSource: ResponseListMaster[] = [];
  dataCurrency: DataMasterCurrency[] = [];

  dataSupplier: ContactData[] = [];
  dataSupplierBillAddress: AddressDataList[] = [];
  dataSupplierShipAddress: AddressDataList[] = [];

  dataTermOfPayment: ResponseListMaster[] = [];
  dataFindPurchaseOrder: OptionModel[] = [];
  dataFindGoodReceipt: OptionModel[] = [];
  dataTaxType: ResponseListMaster[] = [];
  dataPayablesAccount: DataCoa[] = [];

  currencyTo = "IDR";
  mode: Mode | null = null;

  disabled = {
    currency: false,
  };

  queryParams = {
    po: {
      limit: 20,
      page: 0,
      search: `poType~${PURCHASE_ORDER_TYPE.SERVICE}_AND_status~${PURCHASE_ORDER_STATUS.APPROVED}`,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel,
    gr: {
      limit: 20,
      page: 0,
      // search: `receivingItem.status~${RECEIVE_ITEM_STATUS.PARTIAL}_AND_receivingItem.status~${RECEIVE_ITEM_STATUS.UNBILLED}`,
      search: "",
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel,
  };

  loading = {
    source: false,
    supplier: false,
    top: false,
    findPO: false,
    findGR: false,
    taxType: false,
    payableAccount: false,
    generate: false,
  };

  formRules = {
    invoiceType: {
      label: "lbl_invoice_type",
    },
    source: {
      label: "lbl_source",
      name: "source",
      placeholder: "lbl_source_placeholder",
      decorator: [
        "source",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              type: "object",
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
      decorator: [
        "currency",
        {
          rules: [
            {
              initialValue: this.prefBaseCurrency,
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierName",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: [
        "supplierName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierShipAddress: {
      label: "lbl_supplier_ship_address",
      name: "supplierShipAddress",
      placeholder: "lbl_supplier_ship_address_placeholder",
      decorator: [
        "supplierShipAddress",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierBillAddress: {
      label: "lbl_supplier_bill_address",
      name: "supplierBillAddress",
      placeholder: "lbl_supplier_bill_address_placeholder",
      decorator: [
        "supplierBillAddress",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    termOfPayment: {
      label: "lbl_term_of_payment",
      name: "termOfPayment",
      placeholder: "lbl_term_of_payment_placeholder",
      decorator: [
        "termOfPayment",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    findPurchaseOrder: {
      label: "lbl_find_purchase_order",
      name: "findPurchaseOrder",
      placeholder: "lbl_find_purchase_order_placeholder",
    },
    findGoodReceipt: {
      label: "lbl_find_good_receipt",
      name: "findGoodReceipt",
      placeholder: "lbl_find_good_receipt_placeholder",
    },
    taxType: {
      label: "lbl_tax_type",
      name: "taxType",
      placeholder: "lbl_tax_type_placeholder",
      decorator: [
        "taxType",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "invoiceNumber",
      placeholder: "lbl_invoice_number_placeholder",
      decorator: [
        "invoiceNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceSupplierNo: {
      label: "lbl_invoice_supplier_number",
      name: "invoiceSupplierNo",
      placeholder: "lbl_invoice_supplier_number_placeholder",
      decorator: [
        "invoiceSupplierNo",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      name: "invoiceDate",
      placeholder: "lbl_invoice_date_placeholder",
      decorator: [
        "invoiceDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    accountingDate: {
      label: "lbl_accounting_date",
      name: "accountingDate",
      placeholder: "lbl_accounting_date_placeholder",
      decorator: [
        "accountingDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceReceivedDate: {
      label: "lbl_invoice_received_date",
      name: "invoiceReceivedDate",
      placeholder: "lbl_invoice_received_date_placeholder",
      decorator: [
        "invoiceReceivedDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rate: {
      label: "lbl_rate",
      name: "rate",
      placeholder: "lbl_rate_placeholder",
      decorator: [
        "rate",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    journalNumber: {
      label: "lbl_journal_number",
      name: "journalNumber",
      placeholder: "lbl_journal_number_placeholder",
      decorator: [
        "journalNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    payablesAccount: {
      label: "lbl_payables_account",
      name: "payablesAccount",
      placeholder: "lbl_payables_account_placeholder",
      decorator: [
        "payablesAccount",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceDescription: {
      label: "lbl_invoice_description",
      name: "invoiceDescription",
      placeholder: "lbl_invoice_description_placeholder",
      decorator: [
        "invoiceDescription",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    statusInvoice: {
      label: "lbl_status_invoice",
      name: "statusInvoice",
      placeholder: "lbl_status_invoice_placeholder",
      decorator: [
        "statusInvoice",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  userPrivileges: IAuthorities[] = [];

  created(): void {
    this.getSupplier = debounceProcess(this.getSupplier, 400);
    this.getSource = debounceProcess(this.getSource, 400);
    this.getTermOfPayment = debounceProcess(this.getTermOfPayment, 400);
    this.searchPO = debounceProcess(this.searchPO, 400);
    this.searchGR = debounceProcess(this.searchGR, 400);

    this.mode = this.$route.meta.mode as Mode;

    this.setUserPrivilege();

    this.fetchOptions();
  }

  get hasPrivilegeAccounting(): boolean {
    return !!this.userPrivileges.find(
      priv => priv.key === "general-journal" && priv.privilege.read
    );
  }

  get isIDR(): boolean {
    return this.storeAPForm.currency === "IDR";
  }

  get shouldGenerateFromPO(): boolean {
    return SOURCES_TO_GENERATE_FROM_PO.includes(this.storeAPForm.source);
  }

  get shouldGenerateFromGR(): boolean {
    return SOURCES_TO_GENERATE_FROM_GR.includes(this.storeAPForm.source);
  }

  get isModeCreate(): boolean {
    return this.mode === Mode.CREATE;
  }

  get prefBaseCurrency(): string {
    return LocalStorageService.load(StorageKeys.BASE_CURRENCY) || "";
  }

  get accountingDate(): Moment {
    return this.storeAPForm.accountingDate;
  }

  @Watch("storePreference.length", {
    immediate: true,
  })
  onChangePreference(newValue: number): void {
    if (!newValue || !this.isModeCreate) return;
    this.setBaseCurrencyCreate();
    this.setBasePayablesAccount();
    this.setDefaultExpenseAccount();
  }

  // trigger detail invoice
  @Watch("storeAPForm.statusInvoice")
  onChangeStatusInvoice(): void {
    this.handleMissingSupplier();

    // fetch options GR
    this.queryParams.gr.search = this.createQuerySearch();
    this.getListGR(this.queryParams.gr);

    this.queryParams.po.search = this.createQuerySearch();
    this.getListPO(this.queryParams.po);

    this.getPayablesAccount({ currencyCode: this.storeAPDetail.currency });
  }

  @Watch("storeInvoiceAPLines.length")
  onChangeInvoiceAPLines(newValue: number, oldValue: number): void {
    if (newValue > oldValue) return;
    const lines = this.storeInvoiceAPLines as InvoiceAPLine[];
    const { findPurchaseOrder, findGoodReceipt } = this.storeAPForm as IForm;
    const references = this.shouldGenerateFromPO
      ? findPurchaseOrder
      : findGoodReceipt;
    let newLines: InvoiceAPLine[] = [];
    references.forEach(ref => {
      newLines = [
        ...newLines,
        ...lines.filter(line => ref === line.documentReferenceId),
      ];
    });
    const newRefs = newLines.map<string>(line => line.documentReferenceId);
    this.setAPForm({
      ...this.storeAPForm,
      findGoodReceipt: removeDuplicate(newRefs),
      findPurchaseOrder: removeDuplicate(newRefs),
    });
  }

  fetchOptions(): void {
    this.getSource();
    this.getSupplier();
    this.getTermOfPayment();
    this.getTaxType();

    if (this.isModeCreate) {
      this.getPayablesAccount({ currencyCode: this.prefBaseCurrency });
    }
  }

  setUserPrivilege(): void {
    this.userPrivileges = LocalStorageService.loadUserPrivilege();
  }

  handleChangeSource(value: string): void {
    const init = initFormHeader();
    this.setAPForm({
      ...init,
      source: value,
    });

    this.setInvoiceAPLines([]);
    this.setInvoiceAPDeletedLine([]);
    this.recalculatePricing();
    this.disabled.currency = false;
  }

  handleChangeCurrency(param: DataMasterCurrency): void {
    const { currencyCode } = param;

    if (currencyCode?.toUpperCase() === "IDR") {
      this.setBasePayablesAccount();
    } else {
      this.setAPForm({
        ...this.storeAPForm,
        payablesAccount: undefined,
      });
    }

    // set currency
    this.setAPForm({
      ...this.storeAPForm,
      currency: currencyCode,
    });

    this.findCurrencyRates(currencyCode as string, this.currencyTo);
    this.getPayablesAccount({
      currencyCode: currencyCode as string,
      autoFill: true,
    });
    this.deciderGetListDocumentSource();
  }

  filterOption(input, option): boolean {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  /**
   * find rates of currency
   * @param fromCurr currency based on form
   * @param toCurr currency based on preference
   */
  findCurrencyRates(fromCurr: string, toCurr: string): void {
    const params: RequestQueryParamsModel = {
      search: `fromCurrency.currencyCode~${fromCurr}_AND_toCurrency.currencyCode~${toCurr}`,
    };
    settingsServices.listOfCurrency(params, "").then(response => {
      const [curr] = response.data;
      if (!curr) return;
      this.setAPForm({
        ...this.storeAPForm,
        rate: curr.rate,
      });
    });
  }

  getSource(valueSearch = ""): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "INVOICE_AP_SOURCE",
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.source = true;
    masterServices
      .listMaster(params)
      .then(response => {
        this.dataSource = response;
      })
      .finally(() => (this.loading.source = false));
  }

  getSupplier(valueSearch = ""): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `active~true_AND_supplier~true`,
    };
    if (valueSearch)
      params.search = `active~true_AND_supplier~true_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
    this.loading.supplier = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.dataSupplier = res.data;
        this.handleMissingSupplier();
      })
      .finally(() => (this.loading.supplier = false));
  }

  handleChangeSupplier(supplierId: string | undefined): void {
    this.setInvoiceAPLines([]);
    this.setInvoiceAPDeletedLine([]);
    this.setInvoiceAPPrepayments(initInvoiceAPPrepayment());
    this.recalculatePricing();
    if (!supplierId) return;
    this.setAPForm({
      ...this.storeAPForm,
      supplierName: supplierId,
      findPurchaseOrder: [],
      findGoodReceipt: [],
    });
    this.getDetailContact(supplierId);
    this.setSupplierAddress(supplierId);

    this.deciderGetListDocumentSource();
  }

  setSupplierAddress(supplierId: string): void {
    // bill to
    this.dataSupplierBillAddress = this.dataSupplier
      .map(dataMap => {
        if (dataMap.id === supplierId)
          return dataMap.addressDataList.filter(
            dataFilter => dataFilter.billTo
          );
        else return;
      })
      .filter(dataFilter => dataFilter)
      .flat();

    // ship to
    this.dataSupplierShipAddress = this.dataSupplier
      .map(dataMap => {
        if (dataMap.id === supplierId)
          return dataMap.addressDataList.filter(
            dataFilter => dataFilter.shipTo
          );
        else return;
      })
      .filter(dataFilter => dataFilter)
      .flat();

    this.setAPForm({
      ...this.storeAPForm,
      supplierShipAddress:
        this.dataSupplierBillAddress[0]?.address || undefined,
      supplierBillAddress:
        this.dataSupplierShipAddress[0]?.address || undefined,
    });
  }

  getDetailContact(supplierId: string): void {
    contactServices
      .getContactData(supplierId)
      .then((detail: ResponseContactData) => {
        this.setSupplierTaxData(detail);
        this.setSupplierTop(detail);
      });
  }

  setSupplierTaxData({
    taxRegisName,
    taxRegisNumber,
  }: ResponseContactData): void {
    this.setAPForm({
      ...this.storeAPForm,
      taxRegistrationNumber: taxRegisNumber,
      taxRegistrationName: taxRegisName,
    });
  }

  setSupplierTop({ top }: ResponseContactData): void {
    this.setAPForm({
      ...this.storeAPForm,
      termOfPayment: top,
    });
  }

  getTermOfPayment(valueSearch = ""): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "TOP",
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.top = true;
    masterServices
      .listMaster(params)
      .then(data => {
        this.dataTermOfPayment = data;
      })
      .finally(() => (this.loading.top = false));
  }

  searchPO(value: string): void {
    const searchBy: string[] = [this.createQuerySearch()];

    if (value) {
      searchBy.unshift(`documentNumber~*${value}*`);
    }

    this.queryParams.po.search = searchBy.join("_AND_");

    this.getListPO(this.queryParams.po);
  }

  getListPO(params: RequestQueryParamsModel): void {
    this.loading.findPO = true;
    purchaseServices
      .getListPurchaseOrderAvailableAP(params)
      .then(response => {
        this.dataFindPurchaseOrder = response.data
          .filter(dataFilter => dataFilter.documentNumber)
          .map<OptionModel>(item => ({
            label: item.documentNumber,
            value: item.id,
          }));

        if (!this.isModeCreate) {
          this.handleMissingPO();
        }
      })
      .finally(() => (this.loading.findPO = false));
  }

  searchGR(value: string): void {
    const searchBy: string[] = [this.createQuerySearch()];

    if (value) {
      searchBy.unshift(`receiveNumber~*${value}*`);
    }

    this.queryParams.gr.search = searchBy.join("_AND_");

    this.getListGR(this.queryParams.gr);
  }

  getListGR(params: RequestQueryParamsModel): void {
    this.loading.findGR = true;
    logisticServices
      .listOfReceivingItemsAvailableInvoiceAp(params)
      .then(response => {
        this.dataFindGoodReceipt = response.data.map<OptionModel>(item => ({
          label: item.receiveNumber,
          value: item.id,
        }));

        if (!this.isModeCreate) {
          this.handleMissingGR();
        }
      })
      .finally(() => (this.loading.findGR = false));
  }

  getTaxType(): void {
    const params: RequestQueryParamsModel = {
      name: "TAX_CALCULATION",
    };
    this.loading.taxType = true;
    masterServices
      .listMaster(params)
      .then(res => {
        this.dataTaxType = res.filter(
          item => item.value !== TAX_CALCULATION.NONE
        );
      })
      .finally(() => (this.loading.taxType = false));
  }

  getPayablesAccount(funcParam: {
    currencyCode: string;
    valueSearch?: string;
    autoFill?: boolean;
  }): void {
    const { currencyCode, valueSearch, autoFill } = funcParam;
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      sorts: "code:asc",
      search: "",
    };

    const searchBy: string[] = [
      "parentAccount.code~2*",
      `currency.currencyCode~${currencyCode}`,
    ];

    if (valueSearch) {
      searchBy.unshift(`code~*${valueSearch}*_OR_description~*${valueSearch}*`);
    }

    params.search = searchBy.join("_AND_");

    this.loading.payableAccount = true;
    settingsServices
      .listOfCoa(params, "")
      .then(data => {
        this.dataPayablesAccount = data.data;

        if (autoFill && currencyCode !== "IDR") {
          this.setAPForm({
            ...this.storeAPForm,
            payablesAccount: data.data[0]?.id || "",
          });
        }
      })
      .finally(() => (this.loading.payableAccount = false));
  }

  handleGenerate(): void {
    if (this.shouldGenerateFromPO) {
      this.generateOnlyNewDoc({
        fieldForm: "findPurchaseOrder",
        fieldDetail: "purchaseOrders",
      });
    } else if (this.shouldGenerateFromGR) {
      this.generateOnlyNewDoc({
        fieldForm: "findGoodReceipt",
        fieldDetail: "goodReceipts",
      });
    }
  }

  createPORow(detail: ResponseDetailPurchaseOrder): InvoiceAPLine[] {
    return detail.purchaseOrderLines
      .filter(item => item.qtyOutstanding > 0)
      .map<InvoiceAPLine>(row => {
        const gross = new Decimal(row.qty || 0)
          .times(row.price || 0)
          .toNumber();
        return {
          uniqueId: row.id,
          gross,
          no: "",
          baseAmount: 0,
          description: row.description,
          discountValue: row.discountValue || 0,
          expenseAccountId: "", // later set account based on preference
          expenseAccount: "",
          goodReceiptLineId: null,
          incomeTaxId: "",
          merk: row.merk,
          percentDiscount: findPercentage(row.discountValue || 0, gross),
          price: row.price || 0,
          productId: row.productId,
          purchaseOrderLineId: row.id,
          qty: row.qtyOutstanding,
          id: null,
          subTotal: 0,
          taxCode: row.taxCode,
          taxValue: 0,
          taxRate: row.taxRate,
          uomId: row.uomId,
          uom: row.uom,
          partName: row.productName,
          partNumber: row.productCode,
          documentReference: detail.documentNumber,
          incomeTax: "",
          incomeTaxRate: 0,
          incomeTaxValue: 0,
          generated: true,
          grossAfterDiscount: 0,
          documentReferenceId: detail.id,
          qtyAvailableForReturn: 0, // not using this field but required type
        };
      });
  }

  createGRRow(detail: ReceivingItemDraftResponseDTO): InvoiceAPLine[] {
    return detail.receiveItems
      .filter(item => item.qtyOutstanding > 0)
      .map<InvoiceAPLine>(row => {
        const gross = new Decimal(row.qty || 0)
          .times(row.price || 0)
          .toNumber();
        return {
          qtyAvailableForReturn: 0, // not using this field but required type
          uniqueId: row.id,
          gross,
          no: "", // set numbering later
          baseAmount: 0,
          description: "",
          discountValue: row.discountValue || 0,
          expenseAccountId: "", // later set account based on preference
          expenseAccount: "",
          goodReceiptLineId: row.id,
          incomeTaxId: "",
          merk: row.merk,
          percentDiscount: findPercentage(row.discountValue || 0, gross),
          price: row.price || 0,
          productId: row.productId,
          purchaseOrderLineId: null,
          qty: row.qtyOutstanding,
          id: null,
          subTotal: 0,
          taxCode: row.taxCode,
          taxValue: 0,
          taxRate: row.taxRate || 0,
          uomId: row.productUomId,
          uom: row.productUom,
          partName: row.productName,
          partNumber: row.productCode,
          documentReference: detail.receiveNumber,
          incomeTax: "",
          incomeTaxRate: 0,
          incomeTaxValue: 0,
          generated: true,
          grossAfterDiscount: 0,
          documentReferenceId: detail.id,
        };
      });
  }

  setBaseCurrencyCreate(): void {
    const prefCurrency: ResponsePreference | undefined =
      this.getPreferenceByKey(PREFERENCE_FEATURE_KEY.FEATURE_BASE_CURRENCY);
    if (!prefCurrency) return;
    settingsServices
      .listOfMasterCurrency({ search: `secureId~${prefCurrency.value}` }, "")
      .then(response => {
        const [currency] = response.data;
        this.currencyTo = currency.currencyCode as string;
        if (!this.isModeCreate) return;
        this.setAPForm({
          ...this.storeAPForm,
          currency: currency.currencyCode,
        });
      });
  }

  setBasePayablesAccount(): void {
    const prefAccount: ResponsePreference = this.getPreferenceByKey(
      PREFERENCE_ACCOUNT_SETUP_KEY.ACCOUNT_SETUP_ACCOUNT_PAYABLES
    );
    if (!prefAccount) return;
    this.setAPForm({
      ...this.storeAPForm,
      payablesAccount: prefAccount.value,
    });
  }

  /**
   * set default expense account on each line
   * based on preference
   */
  async setDefaultExpenseAccount(): Promise<void> {
    try {
      const { source } = this.storeAPForm as IForm;
      if (source === INVOICE_AP_SOURCE.SERVICE) {
        // use expense account from master product if come from PO service
        this.storeInvoiceAPLines.forEach(async (row: InvoiceAPLine) => {
          // fetch account
          const { expensePurchaseAccountId, expensePurchaseAccountName } =
            await useProductExpenseAccount(row.productId);
          row.expenseAccountId = expensePurchaseAccountId;
          row.expenseAccount = expensePurchaseAccountName;
        });
      } else {
        // use unbilled payables account if come from GR
        const account =
          this.getPreferenceByKey(
            PREFERENCE_ACCOUNT_SETUP_KEY.ACCOUNT_SETUP_UNBILLED_PAYABLES
          )?.value || "";
        if (!account) return;

        // fetch account
        const { code, description } = await useCoaListFindById(account);
        this.setExpenseAccountProducts({
          accountId: account,
          accountDescription: `${code} - ${description}`,
        });
      }
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  onChangeTaxType(e: TAX_CALCULATION): void {
    this.setAPForm({
      ...this.storeAPForm,
      taxType: e,
    });
    this.recalculatePricing();
  }

  onChangeInvoiceDate(e: Moment): void {
    this.setAPForm({
      ...this.storeAPForm,
      invoiceDate: e,
      accountingDate: e,
    });
  }

  disabledDateInvoiceReceived(current: string): boolean {
    return moment(current).isBefore(this.storeAPForm.invoiceDate);
  }

  handleMissingSupplier(): void {
    const detail: InvoiceAPResponse = this.storeAPDetail;

    // supplier
    if (!this.dataSupplier.length || !detail.supplierId) return;
    const optSupplier = this.dataSupplier.find(
      opt => opt.id === detail.supplierId
    );
    if (!optSupplier) {
      this.findListSupplierById(detail.supplierId);
    }
  }

  findListSupplierById(id: string): void {
    contactServices
      .listContactData({ search: `secureId~${id}` })
      .then(({ data }) => {
        const [supplier] = data;
        this.pushMissingSupplier(supplier);
      });
  }

  pushMissingSupplier(supplier: ContactData): void {
    this.dataSupplier.push({ ...supplier });
  }

  handleMissingGR(): void {
    const { findGoodReceipt } = this.storeAPForm as IForm;
    if (!this.dataFindGoodReceipt.length || !findGoodReceipt.length) {
      return;
    }

    const match = this.dataFindGoodReceipt.find(item =>
      findGoodReceipt.includes(item.value)
    );
    if (match) return;

    const promises: Promise<GoodsReceiveChecklistResponse>[] = [];
    findGoodReceipt.forEach(item => {
      promises.push(
        logisticServices.listOfReceivingItemsChecklistV2({
          search: `receivingItem.secureId~${item}`,
        })
      );
    });
    Promise.all(promises).then(res => {
      res.forEach(({ data }) => {
        const [el] = data;
        if (!el) return;
        this.dataFindGoodReceipt.push({
          label: el.receiveNumber,
          value: el.receivingId,
        });
      });
    });
  }

  handleMissingPO(): void {
    const { findPurchaseOrder } = this.storeAPForm as IForm;
    if (!this.dataFindPurchaseOrder.length || !findPurchaseOrder.length) {
      return;
    }

    const match = this.dataFindPurchaseOrder.find(item =>
      findPurchaseOrder.includes(item.value)
    );
    if (match) return;

    const promises: Promise<ResponseGetListPurchaseOrder>[] = [];
    findPurchaseOrder.forEach(item => {
      promises.push(
        purchaseServices.getListPurchaseOrder({
          search: `secureId~${item}`,
        })
      );
    });
    Promise.all(promises).then(res => {
      res.forEach(({ data }) => {
        const [el] = data;
        if (!el) return;
        this.dataFindPurchaseOrder.push({
          label: el.documentNumber,
          value: el.id,
        });
      });
    });
  }

  removeDuplicateInvoiceApLines(
    invoiceApLines: InvoiceAPLine[],
    sources: InvoiceAPLine[]
  ) {
    return [...invoiceApLines, ...sources].filter(
      (filterInvoiceAp, indexInvoiceAp) =>
        [...this.storeInvoiceAPLines, ...sources].findIndex(
          itemInvoiceAp => filterInvoiceAp.uniqueId === itemInvoiceAp.uniqueId
        ) === indexInvoiceAp
    );
  }

  guardTaxType(
    data: Array<ReceivingItemDraftResponseDTO | ResponseDetailPurchaseOrder>
  ): boolean {
    const taxTypes = data.map<string>(item => item.taxType);
    return isNotUnique(taxTypes);
  }

  generateFromGR(ids: string[]): void {
    const promises: Promise<ReceivingItemDraftResponseDTO>[] = [];
    ids.forEach(id => {
      promises.push(logisticServices.getDetailReceiveItem(id));
    });

    let sources: InvoiceAPLine[] = [];
    Promise.all(promises)
      .then(details => {
        if (this.guardTaxType(details)) {
          this.showNotifWarning("notif_document_has_different_tax_type");
          return;
        }

        this.setAPForm({
          ...this.storeAPForm,
          taxType: details[0] ? details[0].taxType : "",
        });

        details.forEach(detail => {
          const lines: InvoiceAPLine[] = this.createGRRow(detail);
          sources = [...sources, ...lines];
        });

        // commit to store
        this.setInvoiceAPLines(
          this.removeDuplicateInvoiceApLines(this.storeInvoiceAPLines, sources)
        );

        // set numbering
        this.setNumbering(this.storeInvoiceAPLines);

        this.setDefaultExpenseAccount();
        this.recalculatePricing();

        this.disabled.currency = true;
      })
      .catch(() => (this.disabled.currency = false));
  }

  generateFromPO(ids: string[]): void {
    const promises: Promise<ResponseDetailPurchaseOrder>[] = [];
    ids.forEach(id => {
      promises.push(purchaseServices.getDetailPurchaseOrder(id));
    });

    let sources: InvoiceAPLine[] = [];
    Promise.all(promises)
      .then(details => {
        if (this.guardTaxType(details)) {
          this.showNotifWarning("notif_document_has_different_tax_type");
          return;
        }

        this.setAPForm({
          ...this.storeAPForm,
          taxType: details[0]?.taxType || null,
        });

        details.forEach(detail => {
          const lines: InvoiceAPLine[] = this.createPORow(detail);
          sources = [...sources, ...lines];
        });

        // commit to store
        this.setInvoiceAPLines(
          this.removeDuplicateInvoiceApLines(this.storeInvoiceAPLines, sources)
        );

        // set numbering
        this.setNumbering(this.storeInvoiceAPLines);

        this.setDefaultExpenseAccount();
        this.recalculatePricing();

        this.disabled.currency = true;
      })
      .catch(() => (this.disabled.currency = false));
  }

  /**
   * @param id doc id
   */
  onDeselectDoc(id: string, source: "GR" | "PO"): void {
    const finderOptions =
      source === "GR" ? this.dataFindGoodReceipt : this.dataFindPurchaseOrder;
    const opt = finderOptions.find(item => item.value === id);
    const invoiceLines: InvoiceAPLine[] = this
      .storeInvoiceAPLines as InvoiceAPLine[];
    const newSource: InvoiceAPLine[] = invoiceLines.filter(row => {
      // side effect: push deleted line id in to store
      if (row.id && row.documentReference === opt?.label) {
        this.pushDeletedInvoiceAPLine(row.id);
      }

      return row.documentReference !== opt?.label ?? "";
    });

    // side effect to update list selected PO/GR when view detail document
    this.setListSelectedPo(newSource);
    this.setListSelectedGr(newSource);

    this.setNumbering(newSource);
    this.setInvoiceAPLines(newSource);
    this.recalculatePricing();
  }

  setListSelectedPo(source: InvoiceAPLine[]): void {
    const { purchaseOrders } = this.storeAPDetail as InvoiceAPResponse;
    const docRefs: string[] = source.map<string>(
      (item: InvoiceAPLine) => item.documentReference
    );
    const newList = purchaseOrders.filter(item =>
      docRefs.includes(item.documentNumber)
    );
    this.storeAPDetail.purchaseOrders = newList;
  }

  setListSelectedGr(source: InvoiceAPLine[]): void {
    const { goodReceipts } = this.storeAPDetail as InvoiceAPResponse;
    const docRefs: string[] = source.map<string>(
      (item: InvoiceAPLine) => item.documentReference
    );
    const newList = goodReceipts.filter(item =>
      docRefs.includes(item.documentNumber)
    );
    this.storeAPDetail.goodReceipts = newList;
  }

  setNumbering(sources: InvoiceAPLine[]): void {
    sources.forEach((item, i) => (item.no = (i + 1).toString()));
  }

  handleChangeBranch(value?: LabelInValue): void {
    this.setAPForm({
      ...this.storeAPForm,
      branch: value,
    });
    this.deciderGetListDocumentSource();
  }

  /**
   * create query search for finding PO/GR
   * based on supplier, branch, and currency
   */
  createQuerySearch(): string {
    const searchBy: string[] = [];
    const { supplierName, branch, currency } = this.storeAPForm as IForm;

    searchBy.push(`supplier.secureId~${supplierName}`);

    if (branch) {
      searchBy.push(`branch.secureId~${branch.key}`);
    }

    searchBy.push(`priceCurrency.currencyCode~${currency}`);

    return searchBy.join("_AND_");
  }

  deciderGetListDocumentSource(): void {
    const { supplierName, branch, currency } = this.storeAPForm as IForm;

    if (!supplierName || !branch || !currency) return;

    // find PO/GR by supplier
    if (this.shouldGenerateFromPO) {
      this.queryParams.po.search = this.createQuerySearch();
      this.getListPO(this.queryParams.po);
    } else if (this.shouldGenerateFromGR) {
      this.queryParams.gr.search = this.createQuerySearch();
      this.getListGR(this.queryParams.gr);
    }
  }

  onChangeFindPO(value: string[]): void {
    this.setAPForm({
      ...this.storeAPForm,
      findPurchaseOrder: value,
    });
  }

  onChangeFindGR(value: string[]): void {
    this.setAPForm({
      ...this.storeAPForm,
      findGoodReceipt: value,
    });
  }

  /**
   * generate only for newly added GR/PO ids
   * @param fieldDetail refer into property on detail invoice AP dto
   * @param fieldForm refer into property on form invoice AP
   */
  generateOnlyNewDoc({
    fieldDetail,
    fieldForm,
  }: {
    fieldForm: "findPurchaseOrder" | "findGoodReceipt";
    fieldDetail: "goodReceipts" | "purchaseOrders";
  }): void {
    const flatIds: string[] = this.storeAPDetail[fieldDetail].map(
      (item: InvoiceAPGoodReceiptResponse | InvoiceAPPurchaseOrderResponse) =>
        item.id
    );
    const filteredIds: string[] = this.storeAPForm[fieldForm].filter(
      (item: string) => {
        return !flatIds.includes(item);
      }
    );

    if (this.storeAPForm[fieldForm] && this.storeAPForm[fieldForm].length) {
      // generate only new ids
      if (fieldForm === "findGoodReceipt") {
        this.generateFromGR(filteredIds);
      } else {
        this.generateFromPO(filteredIds);
      }
    } else {
      if (fieldForm === "findGoodReceipt") {
        this.showNotifError("lbl_good_receipt_mandatory");
      } else {
        this.showNotifError("lbl_purchase_order_mandatory");
      }
    }
  }

  disabledDate(current: Moment): boolean {
    if (this.isModeCreate) {
      return current > moment();
    }
    const year = moment(this.storeAPDetail.invoiceDate).year();
    const JANUARY = 0;
    const DECEMBER = 11;
    const backYear = moment().year(year).month(JANUARY).date(1).startOf("day");
    const afterYear = moment().year(year).month(DECEMBER).date(31).endOf("day");
    return current < backYear || current > afterYear;
  }
}
