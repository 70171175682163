import { SearchBuilder } from "@/builder";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { reportPayableMutationService } from "@/services/ReportPayableMutation.service";
import { Moment } from "moment";
import useDate from "./useDate";

type FilterField = Partial<{
  invoiceType: string | null;
  status: string | null;
  paymentFrom: Moment | null;
  paymentTo: Moment | null;
  branchId: string | null;
  supplierId: string | null;
  currencyId: string | null;
  dateFrom: Moment | null;
  dateTo: Moment | null;
  invoiceNumber: string | null;
  invoiceSupplierNumber: string | null;
}>;

const useReportPayableMutation = () => {
  const { toStartDay, toEndDay } = useDate();

  const findAll = (params: RequestQueryParamsModel) => {
    return reportPayableMutationService.list(params);
  };

  const download = (params: RequestQueryParamsModel) => {
    return reportPayableMutationService.download(params);
  };

  const findAllInvoice = (params: RequestQueryParamsModel) => {
    return reportPayableMutationService.listInvoice(params);
  };

  const findAllInvoiceSupplier = (params: RequestQueryParamsModel) => {
    return reportPayableMutationService.listInvoiceSupplier(params);
  };

  const buildSearchList = ({
    branchId,
    currencyId,
    dateFrom,
    dateTo,
    invoiceNumber,
    invoiceSupplierNumber,
    supplierId,
    paymentFrom,
    paymentTo,
    status,
    invoiceType,
  }: FilterField): string => {
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (invoiceType) {
      q.push(
        builder.push(["invoiceType", invoiceType], { like: "both" }).build()
      );
    }

    if (status) {
      q.push(builder.push(["paymentStatus", status], { like: "both" }).build());
    }

    if (branchId) {
      q.push(builder.push(["branchId", branchId]).build());
    }

    if (currencyId) {
      q.push(builder.push(["currencyId", currencyId]).build());
    }

    if (invoiceNumber) {
      q.push(
        builder.push(["invoiceNumber", invoiceNumber], { like: "both" }).build()
      );
    }

    if (supplierId) {
      q.push(builder.push(["supplierId", supplierId]).build());
    }

    if (invoiceSupplierNumber) {
      q.push(
        builder
          .push(["invoiceSupplierNumber", invoiceSupplierNumber], {
            like: "both",
          })
          .build()
      );
    }

    return q.join(builder.AND);
  };

  return {
    findAllInvoice,
    findAllInvoiceSupplier,
    findAll,
    download,
    buildSearchList,
  };
};

export default useReportPayableMutation;
