import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  ReportAgingDashboardDetailDto,
  ReportAgingDashboardDto,
  ReportAgingDashboardHeadDto,
} from "@/models/interface/ar-aging-report";
import { agingServices } from "@/services/aging.service";

const useArAgingReport = () => {
  const findAllDashboard = (params?: RequestQueryParams) => {
    return agingServices.getAgingReportDashboard(params);
  };

  const initReportAgingDashboardHeadDto = (): ReportAgingDashboardHeadDto => ({
    outstanding1: initReportAgingDashboardDto(),
    outstanding2: initReportAgingDashboardDto(),
    outstanding3: initReportAgingDashboardDto(),
    outstanding4: initReportAgingDashboardDto(),
  });

  const initReportAgingDashboardDto = (): ReportAgingDashboardDto => ({
    invoices: [],
    key: 0,
    totalAmount: 0,
    totalInvoice: 0,
  });

  const initReportAgingDashboardDetailDto =
    (): ReportAgingDashboardDetailDto => ({
      amount: 0,
      customerName: "",
      id: "",
      invoiceNumber: "",
      source: "",
    });

  return {
    findAllDashboard,
    initReportAgingDashboardDto,
    initReportAgingDashboardDetailDto,
    initReportAgingDashboardHeadDto,
  };
};

export default useArAgingReport;
