// Core
// Class
import { Api } from "@/models/class/api.class";
import {
  Pagination,
  PropsModel,
} from "@/models/constant/interface/common.interface";
import {
  DataAssetHistory,
  RequestAssetAdjustmentAssignment,
  RequestAssetAdjustmentFinancial,
  RequestAssetCategory,
  RequestAssetClosePeriod,
  RequestAssetsbook,
  RequestCategoryAsset,
  RequestCreateAssetCategory,
  RequestCreateSegment,
  RequestDeleteFile,
  RequestParamDownloadAssetDepreciationProjection,
  RequestPostingAsset,
  RequestPostReclass,
  RequestTemplateDownload,
  RequestUpdateMaster,
  ResponseAssetCategory,
  ResponseAssetHistory,
  ResponseAssetListInquiry,
  ResponseAssetRetirement,
  ResponseAssetTag,
  ResponseCalendar,
  ResponseCategoryAsset,
  ResponseClosePeriod,
  ResponseCreateSegment,
  ResponseDetailAssetAdditions,
  ResponseFindAddValue,
  ResponseFormAssetCategoryById,
  ResponseGetDetailInquiry,
  ResponseGetReportAsset,
  ResponseListAssetAdditionDraft,
  ResponseListAssetAdditions,
  ResponseListAssetBook,
  ResponseListAssetCategory,
  ResponseListCategoryAsset,
  ResponseListCorporateBook,
  ResponseListHistoryDepre,
  ResponseListMasterAsset,
  ResponseListMasterAssetDetail,
  ResponseListSegment,
  ResponsePostingAssetAdditions,
  ResponsePostReclass,
  ResponseReportAssetAdditions,
  ResponseReportAssetReclass,
  ResponseUploadAssetAdditions,
} from "@/models/interface/assets.interface";
// Interfaces
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  AssetLeasingResponseDto,
  AssetResponseDto,
} from "@/models/interface/master-asset";
import { FileCreateResponseDto } from "@/models/interface/storage";
import {
  RequestAssetAdditions,
  ResponseAssetSingle,
} from "@/views/assets/Additions/types";
import { AssetAdjustmentFormDto } from "@interface/asset";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class AssetsServices extends HttpClient {
  listCategory = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListCategoryAsset> => {
    return this.get<ResponseListCategoryAsset>(Api.Category, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listSegment = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListSegment> => {
    return this.get<ResponseListSegment>(Api.Segment, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listSegmentParent = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListSegment> => {
    return this.get<ResponseListSegment>(Api.Segment + "/parent", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listAssetAdditions = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListAssetAdditions> => {
    return this.get<ResponseListAssetAdditions>(Api.AssetAditions, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailAssetAdditions = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<ResponseDetailAssetAdditions> => {
    return this.get<ResponseDetailAssetAdditions>(
      Api.AssetAditions + `/${id}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listAssetCategory(
    params: RequestQueryParamsModel
  ): Promise<ResponseListAssetCategory> {
    return this.get<ResponseListAssetCategory>(Api.AssetCategory, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listAssetCategoryDistinct = (
    params: RequestQueryParamsModel
  ): Promise<string[]> => {
    return this.get<string[]>(Api.AssetCategory + "/distinct", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listMerk = (params: RequestQueryParamsModel): Promise<string[]> => {
    return this.get<string[]>(Api.AssetCategory + "/distinct/merk", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listFormAssetCategoryById = (
    id: string
  ): Promise<ResponseFormAssetCategoryById> => {
    return this.get<ResponseFormAssetCategoryById>(
      Api.AssetCategory + `/form/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listAssetCategoryActive = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListAssetCategory> => {
    return this.get<ResponseListAssetCategory>(Api.AssetCategoryActive, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listAssetBook(
    params: RequestQueryParamsModel
  ): Promise<ResponseListAssetBook> {
    return this.get<ResponseListAssetBook>(Api.AssetsBook, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listCalendarClosePeriodByModul = (
    params: RequestQueryParamsModel
  ): Promise<ResponseClosePeriod> => {
    return this.get<ResponseClosePeriod>(Api.CalendarUpdate, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listTemplateAssetAddition = (): Promise<Blob> => {
    return this.get<Blob>(Api.AssetAditionsTemplate)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listAssetHistory = (
    params: RequestQueryParamsModel
  ): Promise<ResponseAssetHistory> => {
    return this.get<ResponseAssetHistory>(Api.AssetHistory, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listCalendar(params: RequestQueryParamsModel): Promise<ResponseCalendar> {
    return this.get<ResponseCalendar>(Api.Calendar, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailAssetHistory = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<DataAssetHistory> => {
    return this.get<DataAssetHistory>(Api.AssetHistory + `/${id}`, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getFindAddValue = (
    params: RequestQueryParamsModel
  ): Promise<ResponseFindAddValue> => {
    return this.get<ResponseFindAddValue>(Api.AssetMaster + "/add-value", {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * new service {@linkcode getListMasterAsset}
   */
  listMasterAsset = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListMasterAsset> => {
    return this.get<ResponseListMasterAsset>(Api.AssetMaster, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListMasterAsset(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AssetResponseDto>> {
    return this.get<Pagination<AssetResponseDto>>(Api.AssetMaster, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getAssetLeasing(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AssetLeasingResponseDto>> {
    return this.get<Pagination<AssetLeasingResponseDto>>(
      Api.AssetMasterLeasing,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode listMasterAssetAvailableSo}
   */
  listMasterAssetForSo(
    params: RequestQueryParamsModel
  ): Promise<ResponseListMasterAsset> {
    return this.get<ResponseListMasterAsset>(Api.AssetMasterForSO, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listMasterAssetAvailableSo(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AssetResponseDto>> {
    return this.get<Pagination<AssetResponseDto>>(Api.AssetMasterForSO, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listMasterAssetRent(
    params: RequestQueryParamsModel
  ): Promise<AssetResponseDto[]> {
    return this.get<AssetResponseDto[]>(Api.AssetMasterRent, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listMasterAssetForReclass = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListMasterAsset> => {
    return this.get<ResponseListMasterAsset>(Api.AssetMasterForReclass, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listAssetInquiry = (
    params: RequestQueryParamsModel
  ): Promise<ResponseAssetListInquiry> => {
    return this.get<ResponseAssetListInquiry>(Api.AssetInquiry, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listMasterAssetById = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<ResponseListMasterAssetDetail> => {
    return this.get<ResponseListMasterAssetDetail>(Api.AssetMaster + `/${id}`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listAssetAdjustmentFormById(
    params: RequestQueryParamsModel,
    id: string
  ): Promise<AssetAdjustmentFormDto> {
    return this.get<AssetAdjustmentFormDto>(
      Api.AssetAdjustmentFrom + `/${id}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listCorporateBook = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListCorporateBook> => {
    return this.get<ResponseListCorporateBook>(Api.AssetCorporateBook, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailAssetInquiry = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<ResponseGetDetailInquiry> => {
    return this.get<ResponseGetDetailInquiry>(
      Api.AssetInquiryDetail + `/${id}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getAssetTag(
    params: RequestQueryParamsModel,
    id: "type" | "model" | "fuel"
  ): Promise<ResponseAssetTag> {
    return this.get<ResponseAssetTag>(Api.AssetTag + `/${id}`, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getReportAssetRetirement = (
    params: RequestQueryParamsModel
  ): Promise<PropsModel> => {
    return this.get<PropsModel>(Api.getReportAssetRetirement, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getDownloadReportAssetRetirement = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.getReportAssetRetirementDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getPrintReportAssetRetirement = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.getReportAssetRetirementPrint, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getPrintQr = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.QrAsset, { params, responseType: "arraybuffer" })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getReportAssetAdjustment = (
    params: RequestQueryParamsModel
  ): Promise<PropsModel> => {
    return this.get<PropsModel>(Api.getReportAssetAdjustment, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getDownloadReportAssetAdjustment = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.getReportAssetAdjustmentDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getPrintReportAssetAdjustment = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.getReportAssetAdjustmentPrint, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getReportAssetAdditions = (
    params: RequestQueryParamsModel,
    bookId: string
  ): Promise<ResponseReportAssetAdditions> => {
    return this.get<ResponseReportAssetAdditions>(
      Api.AssetAdditionReport + `/${bookId}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getReportAsset = (
    params: RequestQueryParamsModel,
    bookId: string
  ): Promise<ResponseGetReportAsset> => {
    return this.get<ResponseGetReportAsset>(Api.getReportAsset + `/${bookId}`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDownloadReportReclass = (
    params: RequestQueryParamsModel,
    bookId: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.reclass + `/report/${bookId}/download`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPrintReportReclass = (
    params: RequestQueryParamsModel,
    bookId: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.reclass + `/report/${bookId}/print`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDownloadReportAssetAdditions = (
    params: RequestQueryParamsModel,
    bookId: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.getReportAssetAdditions + `/${bookId}/download`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDownloadReportAsset = (
    params: RequestQueryParamsModel,
    bookId: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.getReportAsset + `/${bookId}/download`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPrintReportAssetAdditions = (
    params: RequestQueryParamsModel,
    bookId: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.getReportAssetAdditions + `/${bookId}/print`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPritReportAsset = (
    params: RequestQueryParamsModel,
    bookId: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.getReportAsset + `/${bookId}/print`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  // untuk yang edit di import > posting asset > action > update
  listAssetAdditionsDraft = (
    id: string
  ): Promise<ResponseListAssetAdditionDraft> => {
    return this.get<ResponseListAssetAdditionDraft>(
      Api.AssetAditionsDraft + `/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listReportReclass = (
    params: RequestQueryParamsModel,
    bookId: string
  ): Promise<ResponseReportAssetReclass> => {
    return this.get<ResponseReportAssetReclass>(
      Api.reclass + `/report/${bookId}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listHistoryDepre = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListHistoryDepre> => {
    return this.get<ResponseListHistoryDepre>(Api.HistoryAssetDepre, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  checkAssetDepre = (): Promise<boolean> => {
    return this.get<boolean>(Api.AssetDepreciation + "/check")
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createReclass = (
    payload: RequestPostReclass
  ): Promise<ResponsePostReclass> => {
    return this.post<ResponsePostReclass, RequestPostReclass>(
      Api.reclass,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createUploadFile(
    payload: FormData,
    modul: string
  ): Promise<FileCreateResponseDto> {
    return this.post<FileCreateResponseDto, FormData>(
      Api.uploadFileModul + `/${modul}`,
      payload,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  deleteUploadedFile = (payload: RequestDeleteFile): Promise<boolean> => {
    return this.delete<boolean>(Api.uploadFileModul, { data: payload })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createUploadAssetAddtions = (
    payload: FormData
  ): Promise<ResponseUploadAssetAdditions> => {
    return this.post<ResponseUploadAssetAdditions, FormData>(
      Api.AssetAdditionsUpload,
      payload,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  addAssetTag = (payload: any): Promise<ResponseAssetTag> => {
    return this.post<ResponseAssetTag, any>(Api.AssetTag, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  postDownloadTemplateAssetAdditions = (
    payload: RequestTemplateDownload
  ): Promise<Blob> => {
    return this.post<Blob, RequestTemplateDownload>(
      Api.AssetAdditionTemplateDownload,
      payload,
      { responseType: "blob" }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  postingAssetAdditions = (
    payload: RequestPostingAsset
  ): Promise<ResponsePostingAssetAdditions> => {
    return this.post<ResponsePostingAssetAdditions, RequestPostingAsset>(
      Api.AssetAdditionsPosting,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  postingAllAssetAdditions = (): Promise<boolean> => {
    return this.post<boolean, string>(Api.AssetAdditionsPosting + "-all")
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateAssetClosePeriod = (
    payload: RequestAssetClosePeriod
  ): Promise<boolean> => {
    return this.put<boolean, RequestAssetClosePeriod>(
      Api.AssetDepreciation,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createAssetCategory(
    payload: RequestCreateAssetCategory[]
  ): Promise<ResponseAssetCategory> {
    return this.post<ResponseAssetCategory, RequestCreateAssetCategory[]>(
      Api.AssetCategory,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createSegment(payload: RequestCreateSegment): Promise<ResponseCreateSegment> {
    return this.post<ResponseCreateSegment, RequestCreateSegment>(
      Api.Segment,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createAssetsbook = (payload: RequestAssetsbook): Promise<boolean> => {
    return this.post<boolean, RequestAssetsbook>(Api.AssetsBook, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createCategory(
    payload: RequestCategoryAsset
  ): Promise<ResponseCategoryAsset> {
    return this.post<ResponseCategoryAsset, RequestCategoryAsset>(
      Api.Category,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateAddValue = (
    payload: RequestAssetAdditions,
    assetId: string
  ): Promise<ResponseAssetSingle> => {
    return this.put<ResponseAssetSingle, RequestAssetAdditions>(
      Api.assetUpdateAddValue + assetId,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateAssetCategory(
    payload: RequestAssetCategory,
    id: string
  ): Promise<boolean> {
    return this.put<boolean, RequestAssetCategory>(
      Api.AssetCategory + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateAssetsbook = (
    payload: RequestAssetsbook,
    id: string
  ): Promise<boolean> => {
    return this.put<boolean, RequestAssetsbook>(
      Api.AssetsBook + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteAssetCategory = (id: string): Promise<boolean> => {
    return this.delete<boolean>(Api.AssetCategory + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  createAssetRetirement = (payload: PropsModel): Promise<boolean> => {
    return this.post<boolean, PropsModel>(Api.AssetRetirement, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  detailAssetInquiry = (id: string): Promise<boolean> => {
    return this.get<boolean>(Api.AssetInquiryDetail + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  updateMasterAsset = (
    payload: RequestUpdateMaster,
    id: string
  ): Promise<boolean> => {
    return this.put<boolean, RequestUpdateMaster>(
      Api.AssetMaster + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  updateAssetAdjustmentFinancial = (
    payload: RequestAssetAdjustmentFinancial,
    id: string
  ): Promise<boolean> => {
    return this.put<boolean, RequestAssetAdjustmentFinancial>(
      Api.AssetAdjustmentFinancial + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  updateAssetAdjustmentAssignment = (
    payload: RequestAssetAdjustmentAssignment,
    id: string
  ): Promise<boolean> => {
    return this.put<boolean, RequestAssetAdjustmentAssignment>(
      Api.AssetAdjustmentAssignment + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  detailAssetAdjustment = (
    params: RequestQueryParamsModel
  ): Promise<PropsModel> => {
    return this.get<PropsModel>(Api.getAssetAdjustment, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  detailAssetRetirement = (
    id: string,
    params: RequestQueryParamsModel
  ): Promise<PropsModel> => {
    return this.get<PropsModel>(Api.AssetRetirement + `/${id}`, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getReportAssetRetirementV2(
    params: RequestQueryParamsModel
  ): Promise<ResponseAssetRetirement> {
    return this.get<ResponseAssetRetirement>(Api.getReportAssetRetirement, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportAssetDepreciationProjection(
    params: RequestParamDownloadAssetDepreciationProjection
  ): Promise<Blob> {
    return this.get<Blob>(Api.AssetMaster + "/report-depreciation/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadCommercialReports(
    params: RequestParamDownloadAssetDepreciationProjection
  ): Promise<Blob> {
    return this.get<Blob>(
      Api.AssetMaster + "/report-asset-depreciation/download",
      {
        params,
        responseType: "blob",
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const assetsServices = new AssetsServices();
