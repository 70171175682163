

























































































































































































































































































































































































































































































import MNotificationVue from "@/mixins/MNotification.vue";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataListProduct } from "@/models/interface/logistic.interface";
import {
  DataTax,
  ResponsePreference,
} from "@/models/interface/settings.interface";
import { logisticServices } from "@/services/logistic.service";
import { IForm, InvoiceAPLine } from "@/store/resources/invoice-ap.resource";
import {
  formatterNumber,
  reverseFormatNumber,
  reverseFormatPercent,
  formatterPercent,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Decimal } from "decimal.js-light";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { useTaxRate } from "@/hooks";

interface IFormDetail {
  partNumber: string;
  partName: string;
  partMerk: string;
  qty: number;
  uom: string;
  price: number;
  discountValue: number;
  discountPercent: number;
  incomeTax: string;
  incomeTaxRateValue: number;
  incomeTaxPercent: number;
  baseAmountDpp: number;
}

@Component({
  mixins: [MNotificationVue],
  computed: {
    ...mapState({
      storePreference: (st: any) =>
        st.preferenceStore.dataPreference as ResponsePreference[],
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
      storeAPForm: (st: any) => st.invoiceApStore.form as IForm,
      storeInvoiceAPLines: (st: any) =>
        st.invoiceApStore.invoiceAPLines as InvoiceAPLine[],
      storeInvoiceAPTotalPrepayments: (st: any) =>
        st.invoiceApStore.totalPrepayment as number,
      storeInvoiceAPTotalGross: (st: any) =>
        st.invoiceApStore.totalGross as number,
      storeInvoiceAPSubtotal: (st: any) =>
        st.invoiceApStore.invoiceSubtotal as number,
      storeInvoiceAPTotalTax: (st: any) => st.invoiceApStore.totalTax as number,
      storeInvoiceAPGrandTotal: (st: any) =>
        st.invoiceApStore.grandTotal as number,
      storeInvoiceAPAdditionalDiscountPercent: (st: any) =>
        st.invoiceApStore.additionalDiscountPercent as number,
      storeInvoiceAPAdditionalDiscountAmount: (st: any) =>
        st.invoiceApStore.additionalDiscountAmount as number,
    }),
    ...mapGetters({
      getInvoiceAPLines: "invoiceApStore/GET_INVOICE_AP_LINES",
      allowEdit: "invoiceApStore/GET_ALLOW_TO_EDIT",
    }),
  },
  methods: {
    formatterNumber,
    formatterPercent,
    reverseFormatNumber,
    reverseFormatPercent,
    ...mapMutations({
      setAPForm: "invoiceApStore/SET_FORM_HEADER",
      setInvoiceAPLines: "invoiceApStore/SET_INVOICE_AP_LINES",
    }),
    ...mapActions({
      addRowInvoiceAPLines: "invoiceApStore/ADD_ROW",
      calcAdditionalDiscount: "invoiceApStore/CALC_ADDITIONAL_DISCOUNT",
      pushDeletedInvoiceAPLine: "invoiceApStore/PUSH_DELETED_INVOICE_AP_LINE",
      recalculatePricing: "invoiceApStore/RECALCULATE_PRICING",
    }),
  },
})
export default class TabDetailsInvoiceAp extends Vue {
  form!: WrappedFormUtils;
  page = 1;
  limit = 10;
  selectedRowKeys: number[] = [];
  columnsTable = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
      width: 110,
      scopedSlots: { customRender: "no" },
    },
    {
      title: this.$t("lbl_document_reference"),
      dataIndex: "documentReference",
      key: "documentReference",
      width: 170,
      scopedSlots: { customRender: "documentReference" },
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "partNumber",
      key: "partNumber",
      width: 170,
      scopedSlots: { customRender: "partNumber" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "partName",
      key: "partName",
      width: 170,
      scopedSlots: { customRender: "partName" },
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
      key: "merk",
      width: 170,
      scopedSlots: { customRender: "merk" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      key: "qty",
      width: 150,
      scopedSlots: { customRender: "qty" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uomId",
      key: "uomId",
      width: 150,
      scopedSlots: { customRender: "uomId" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      key: "price",
      width: 200,
      scopedSlots: { customRender: "price" },
    },
    {
      title: this.$t("lbl_expense_account"),
      dataIndex: "expenseAccount",
      key: "expenseAccount",
      width: 200,
      scopedSlots: { customRender: "expenseAccount" },
    },
    {
      title: this.$t("lbl_base_amount"),
      dataIndex: "baseAmount",
      key: "baseAmount",
      width: 200,
      scopedSlots: { customRender: "baseAmount" },
    },
    {
      title: this.$t("lbl_income_tax"),
      dataIndex: "incomeTaxId",
      key: "incomeTaxId",
      width: 200,
      scopedSlots: { customRender: "incomeTaxId" },
    },
    {
      title: this.$t("lbl_tax_code"),
      dataIndex: "taxCode",
      key: "taxCode",
      width: 200,
      scopedSlots: { customRender: "taxCode" },
    },
    {
      title: this.$t("lbl_tax_amount"),
      dataIndex: "taxValue",
      key: "taxValue",
      width: 200,
      scopedSlots: { customRender: "taxAmount" },
    },
    {
      title: this.$t("lbl_sub_total"),
      dataIndex: "subTotal",
      key: "subTotal",
      width: 200,
      scopedSlots: { customRender: "subTotal" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 200,
      scopedSlots: { customRender: "description" },
    },
    {
      title: this.$t("lbl_action"),
      key: "operation",
      width: 200,
      scopedSlots: { customRender: "operation" },
      align: "center",
    },
  ];
  loading = {
    loadingIncomeTax: false,
    loadingUom: false,
  };
  dataUom = [];
  formRules = {
    partNumber: {
      label: "lbl_part_number",
      name: "partNumber",
      placeholder: "lbl_part_number_placeholder",
      decorator: [
        "partNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    partName: {
      label: "lbl_part_name",
      name: "partName",
      placeholder: "lbl_part_name_placeholder",
      decorator: [
        "partName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    partMerk: {
      label: "lbl_part_merk",
      name: "partMerk",
      placeholder: "lbl_part_merk_placeholder",
      decorator: [
        "partMerk",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    qty: {
      label: "lbl_qty",
      name: "qty",
      placeholder: "lbl_qty_placeholder",
      decorator: [
        "qty",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    uom: {
      label: "lbl_uom",
      name: "uom",
      placeholder: "lbl_uom_placeholder",
      decorator: [
        "uom",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    price: {
      label: "lbl_price",
      name: "price",
      placeholder: "lbl_price_placeholder",
      decorator: [
        "price",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    discountValue: {
      label: "lbl_discount",
      name: "discountValue",
      placeholder: "lbl_discount_placeholder",
      decorator: [
        "discountValue",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    discountPercent: {
      label: "lbl_discount",
      name: "discountPercent",
      placeholder: "lbl_discount_placeholder",
      decorator: [
        "discountPercent",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    incomeTaxRateValue: {
      label: "lbl_income_tax_rate",
      name: "incomeTaxRateValue",
      placeholder: "lbl_income_tax_rate_placeholder",
      decorator: [
        "incomeTaxRateValue",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    incomeTaxPercent: {
      label: "lbl_income_tax_rate",
      name: "incomeTaxPercent",
      placeholder: "lbl_income_tax_rate_placeholder",
      decorator: [
        "incomeTaxPercent",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    baseAmountDpp: {
      label: "lbl_base_amount_dpp",
      name: "baseAmountDpp",
      placeholder: "lbl_base_amount_dpp_placeholder",
      decorator: [
        "baseAmountDpp",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  modal = {
    show: false,
    data: undefined,
    index: -1,
  };

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "ModalDetails" });
  }

  // created (): void {
  // }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  onChangeMerk(value: string, index: number): void {
    this.storeInvoiceAPLines[index].merk = value;
  }

  onChangeProduct(
    { code, name, id }: DataListProduct,
    index: number,
    column: "partNumber" | "partName"
  ): void {
    this.storeInvoiceAPLines[index][column] =
      column === "partNumber" ? code : name;

    // set autofill product code/name
    if (column === "partNumber") {
      this.storeInvoiceAPLines[index].partName = name;
    } else {
      this.storeInvoiceAPLines[index].partNumber = code;
    }

    this.storeInvoiceAPLines[index].productId = id;

    this.setBasedOnDetailProduct(id, index);
  }

  setBasedOnDetailProduct(id: string, idxRow: number): void {
    logisticServices
      .getDetailProduct(id)
      .then(
        async ({ baseUnit, baseUnitId, purchaseTaxName, purchaseTaxId }) => {
          this.storeInvoiceAPLines[idxRow].uom = baseUnit;
          this.storeInvoiceAPLines[idxRow].uomId = baseUnitId;
          this.storeInvoiceAPLines[idxRow].taxCode = purchaseTaxName;
          this.storeInvoiceAPLines[idxRow].taxRate = await useTaxRate(
            purchaseTaxId
          );
        }
      );
  }

  onChangeQty(e: number, index: number, source: "modal" | "table"): void {
    this.storeInvoiceAPLines[index].qty = e;
    this.recalculatePricing();
    if (source === "table") return;
    this.updateModalData();
  }

  onChangePrice(e: number, index: number, source: "modal" | "table"): void {
    this.storeInvoiceAPLines[index].price = e;
    this.recalculatePricing();
    if (source === "table") return;
    this.updateModalData();
  }

  onChangeExpenseAccount(e: string, index: number): void {
    this.storeInvoiceAPLines[index].expenseAccountId = e;
  }

  onChangeTaxCode(e: DataTax | undefined, index: number): void {
    this.storeInvoiceAPLines[index].taxCode = e?.code || "";
    this.storeInvoiceAPLines[index].taxRate = e?.rate || 0;
    this.recalculatePricing();
  }

  onChangeDescription(e: string, index: number): void {
    this.storeInvoiceAPLines[index].description = e;
  }

  onChangeIncomeTax(tax: DataTax | undefined, index: number): void {
    this.storeInvoiceAPLines[index].incomeTax = tax?.code || "";
    this.storeInvoiceAPLines[index].incomeTaxId = tax?.id || "";
    this.storeInvoiceAPLines[index].incomeTaxRate = tax?.rate || 0;
    this.recalculatePricing();
  }

  onChangeDiscount(value: number, column: "percent" | "amount"): void {
    const fields = {
      discountValue: 0,
      percentDiscount: 0,
    };
    const { price, qty } = this.form.getFieldsValue();
    const gross = new Decimal(price || 0).times(qty);

    if (column === "percent") {
      const percentage = new Decimal(value || 0).dividedBy(100);
      const final = gross.times(percentage).toNumber();
      fields.percentDiscount = value || 0;
      fields.discountValue = final;
    } else {
      const amount = new Decimal(value || 0);
      const final = amount
        .dividedBy(gross || 1)
        .times(100)
        .toNumber();
      fields.percentDiscount = final;
      fields.discountValue = value || 0;
    }

    this.form.setFieldsValue(fields);
    this.storeInvoiceAPLines[this.modal.index].discountValue =
      fields.discountValue;
    this.storeInvoiceAPLines[this.modal.index].percentDiscount =
      fields.percentDiscount;
    this.recalculatePricing();
    this.updateModalData();
  }

  showMore(record: InvoiceAPLine, index: number): void {
    this.modal.show = true;
    this.modal.index = index;
    this.setDetailForm(record);
  }

  setDetailForm(data: InvoiceAPLine): void {
    const values: IFormDetail = {
      partNumber: data.partNumber,
      partName: data.partName,
      partMerk: data.merk,
      qty: data.qty,
      uom: data.uom,
      price: data.price,
      discountValue: data.discountValue,
      discountPercent: data.percentDiscount,
      incomeTax: data.incomeTaxId,
      incomeTaxRateValue: data.incomeTaxValue,
      incomeTaxPercent: data.incomeTaxRate,
      baseAmountDpp: data.baseAmount,
    };

    for (const key in values) {
      this.form.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.form.setFieldsValue(values);
  }

  getUom(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.loadingUom = true;
    this.loading.loadingUom = false;
    this.dataUom = [];
  }

  handleSaveModal() {
    this.modal.show = false;
  }

  onSelectRow(value: number[]): void {
    this.selectedRowKeys = value;
  }

  handleAddRow(): void {
    this.addRowInvoiceAPLines();
  }

  handleDeleteRow(): void {
    this.$confirm({
      title: this.$t("lbl_modal_delete_title_confirm"),
      content: this.$t("lbl_modal_delete_info", {
        count: this.selectedRowKeys.length,
      }),
      onOk: this.deleteInvoiceAPLine,
    });
  }

  deleteInvoiceAPLine(): void {
    const newSource: InvoiceAPLine[] = this.storeInvoiceAPLines.filter(
      (data: InvoiceAPLine, index: number) => {
        if (this.selectedRowKeys.includes(index) && data.id) {
          // push deleted line to store
          this.pushDeletedInvoiceAPLine(data.id);
        }
        return !this.selectedRowKeys.includes(index);
      }
    );

    // set numbering
    newSource.forEach((data, index) => (data.no = `${index + 1}`));

    this.setInvoiceAPLines(newSource);
    this.selectedRowKeys = [];
    this.recalculatePricing();
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  onChangeAdditionalDiscount(value: number, field: "amount" | "percent"): void {
    this.calcAdditionalDiscount({ value, field });
    this.recalculatePricing();
  }

  updateModalData(): void {
    const newData: InvoiceAPLine = this.storeInvoiceAPLines[this.modal.index];
    this.setDetailForm(newData);
  }
}
