// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const USER_ROUTE: RoutePathModel = {
  path: "/user",
  url: "/user",
  name: "lbl_user",
  redirect: "/user/read",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_user",
        href: "/user/read",
      },
    ],
    title_head: "lbl_user",
    need_auth: true,
  },
};

export const LIST_USER_ROUTE: RoutePathModel = {
  path: "read",
  url: `${USER_ROUTE.url}/read`,
  name: "user.list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_user",
        href: "/user/read",
      },
    ],
    title_head: "lbl_read",
    need_auth: true,
  },
};

export const LIST_MENU_ROUTE: RoutePathModel = {
  path: "/menu/read",
  url: "/menu/read",
  name: "user.menu",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_menu",
        href: "/menu/read",
      },
    ],
    title_head: "lbl_menu",
  },
};

export const LIST_ROLE_ROUTE: RoutePathModel = {
  path: "/role/read",
  url: "/role/read",
  name: "lbl_role",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_role",
        href: "/role/read",
      },
    ],
    title_head: "lbl_role",
  },
};

export const LIST_PRIVILEGE_ROUTE: RoutePathModel = {
  path: "/privilege/read",
  url: "/privilege/read",
  name: "lbl_privilege",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_privilege",
        href: "/privilege/read",
      },
    ],
    title_head: "lbl_privilege",
  },
};

export const CREATE_USER_ROUTE: RoutePathModel = {
  path: "create",
  url: `${USER_ROUTE.url}/create`,
  name: "lbl_create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_user",
        href: "/user/read",
      },
      {
        name: "lbl_create_user",
        href: "/user/create",
      },
    ],
    title_head: "lbl_create",
    mode: Mode.CREATE,
  },
};

export const EDIT_USER_ROUTE: RoutePathModel = {
  path: "update/:id",
  url: `${USER_ROUTE.url}/update`,
  name: "lbl_update",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_user",
        href: "/user/read",
      },
      {
        name: "lbl_update_user",
        href: "/user/update",
      },
    ],
    title_head: "lbl_update",
    mode: Mode.EDIT,
  },
};

export const CHANGE_PASSWORD_ROUTE: RoutePathModel = {
  path: "/change_password/update",
  url: "/change_password/update",
  name: "profile.change-password",
  meta: {
    breadcrumb: [
      {
        name: "lbl_dashboard",
        icon: "dashboard",
        href: "/dashboard",
      },
      {
        name: "lbl_profile_change_password",
        href: "/change_password/update",
      },
    ],
    title_head: "lbl_profile_change_password",
  },
};
