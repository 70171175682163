export enum INVOICE_AP_SOURCE {
    RENT_TO_RENT = "Rent to Rent",
    SERVICE = "Service",
    SPAREPART = "Sparepart",
    OTHERS = "Others",
    UNIT = "Unit",
}

export enum INVOICE_AP_STATUS {
    DRAFT = "Draft",
    NEED_APPROVAL = "Need Approval",
    APPROVED = "Approved",
    REJECTED = "Rejected",
    CANCELLED = "Cancelled",
    FULLY_PAID = "Fully Paid",
    PARTIAL_PAID = "Partial Paid",
    RETURNED = "Returned",
    UNPAID = "Unpaid",
}