import { useWorkOrder } from "@/hooks";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import { WorkOrderDraftResponseDto } from "@/models/interface/work-order";
import store from "@/store";
import { WoDetailDraft } from "@/store/workOrder.store";
import { NavigationGuard } from "vue-router";

export const workOrderCreateLoader: NavigationGuard = (_to, _from, next) => {
  store.dispatch("workOrderStore/resetStore");
  next();
};

export const workOrderDraftLoader: NavigationGuard = async (
  to,
  _from,
  next
) => {
  const { findByIdDraft, mapDetailDraftDtoToDetail } = useWorkOrder();
  try {
    const res: WorkOrderDraftResponseDto = await findByIdDraft(to.params.id);
    const dto: WoDetailDraft = mapDetailDraftDtoToDetail(res);
    store.dispatch("workOrderStore/resetStore");
    store.commit("workOrderStore/setDetailDraft", dto);

    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};
