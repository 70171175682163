enum INTERNAL_CONTRACT_BILLING_TYPE {
  ADVANCED = "Advanced",
  BEHIND = "Behind",
}

enum INTERNAL_CONTRACT_PERIOD_TYPE {
  DAY = "Day",
  MONTH = "Month",
}

enum INTERNAL_CONTRACT_STATUS {
  APPROVED = "Approved",
  CANCELLED = "Cancelled",
  CHANGE_LOCATION = "Change Location",
  CHANGE_UNIT = "Change Unit",
  DRAFT = "Draft",
  ENDED = "Ended",
  EXTENDED = "Extended",
  NEED_APPROVAL = "Need Approval",
  NEW = "New",
  PARTIAL_ENDED = "Partial Ended",
  REJECTED = "Rejected",
  REVISED = "Revised",
}

export {
  INTERNAL_CONTRACT_BILLING_TYPE,
  INTERNAL_CONTRACT_PERIOD_TYPE,
  INTERNAL_CONTRACT_STATUS,
};
