
















































































import { setNumbering } from "@/helpers/common";
import MNotificationVue from "@/mixins/MNotification.vue";
import { PREPAYMENT_STATUS } from "@/models/enums/prepayment.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  InvoiceAPPrepaymentDetail,
  InvoiceAPPrepaymentResponse,
} from "@/models/interface/invoiceAp.interface";
import { InvoiceApPrepaymentData } from "@/models/interface/InvoiceApPrepayment.interface";
import { invoiceApPrepayment } from "@/services/InvoiceApPrepayment.service";
import {
  IForm,
  initInvoiceAPPrepayment,
} from "@/store/resources/invoice-ap.resource";
import { LabelInValue } from "@/types";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

interface Row extends InvoiceApPrepaymentData {
  no: number;
  disabled: boolean;
}

@Component({
  computed: {
    ...mapState({
      storeAPForm: (st: any) => st.invoiceApStore.form as IForm,
      storeInvoiceAPPrepayments: (st: any) =>
        st.invoiceApStore.invoiceAPPrepayments,
      storeInvoiceAPTotalPrepayments: (st: any) =>
        st.invoiceApStore.totalPrepayment,
    }),
    ...mapGetters({
      allowEdit: "invoiceApStore/GET_ALLOW_TO_EDIT",
    }),
  },
  methods: {
    ...mapMutations({
      setInvoiceAPPrepayments: "invoiceApStore/SET_INVOICE_AP_PREPAYMENTS",
    }),
    ...mapActions({
      pushDeletedPrepayment:
        "invoiceApStore/PUSH_DELETED_INVOICE_AP_PREPAYMENTS",
      recalculatePricing: "invoiceApStore/RECALCULATE_PRICING",
    }),
  },
})
export default class TabApplyPrepaymentInvoiceAp extends Mixins(
  MNotificationVue
) {
  totalData = 0;
  dataSource: Row[] = [];
  columnsTable = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
      width: 90,
      scopedSlots: { customRender: "no" },
    },
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "documentNumber",
      key: "invoiceNumber",
      width: 200,
      scopedSlots: { customRender: "invoiceNumber" },
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "total",
      key: "amount",
      width: 150,
      scopedSlots: { customRender: "amount" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      // width: 300,
      scopedSlots: { customRender: "description" },
    },
    {
      title: "",
      dataIndex: "",
      key: "add",
      scopedSlots: { customRender: "buttonCustom" },
    },
  ];
  queryParams: RequestQueryParamsModel = {
    limit: 10,
    page: 0,
    sorts: "createdDate:desc",
    search: `status~${PREPAYMENT_STATUS.UN_APPLIED}`,
  };
  loading = {
    table: false,
    refresh: false,
  };

  created(): void {
    this.queryParams.search = this.createQuerySearch();
    this.getListPrepayment(this.queryParams);
  }

  @Watch("storeAPForm.currency")
  onChangeCurrency(): void {
    this.queryParams.search = this.createQuerySearch();
    this.getListPrepayment(this.queryParams);
  }

  @Watch("storeAPForm.branch")
  onChangeBranch(
    newValue: LabelInValue | undefined,
    oldValue: LabelInValue | undefined
  ): void {
    if (newValue && newValue.key !== oldValue?.key) {
      this.queryParams.search = this.createQuerySearch();
      this.getListPrepayment(this.queryParams);
    }
  }

  @Watch("storeAPForm.supplierName")
  onChangeSupplier(): void {
    this.queryParams.search = this.createQuerySearch();
    this.getListPrepayment(this.queryParams);
  }

  handleDeleteRow(doc: InvoiceAPPrepaymentDetail): void {
    this.$confirm({
      title: this.$t("lbl_modal_delete_title_confirm").toString(),
      onOk: () => this.deletePrepayment(doc),
      onCancel() {
        return;
      },
    });
  }

  getListPrepayment(params: RequestQueryParamsModel): void {
    this.loading.table = true;
    invoiceApPrepayment
      .getListInvoiceApPrepayment(params)
      .then(res => {
        this.totalData = res.totalElements;
        this.dataSource = res.data.map<Row>((item, i) => ({
          ...item,
          no: setNumbering(params.page || 0, params.limit || 0, i),
          disabled: false,
        }));
      })
      .finally(() => {
        this.loading.table = false;
        this.loading.refresh = false;
      });
  }

  addData(row: Row): void {
    const doc = this.storeInvoiceAPPrepayments.prepaymentLines.find(
      (item: InvoiceAPPrepaymentDetail) =>
        item.invoicePrepaymentNo === row.documentNumber
    );
    if (doc) {
      this.showNotifError(this.$t("notif_error_duplicate_data").toString());
      return;
    }

    const data: InvoiceAPPrepaymentResponse = {
      prepaymentLines: [...this.storeInvoiceAPPrepayments.prepaymentLines],
    };
    data.prepaymentLines.push({
      createdDate: "",
      modifiedDate: "",
      invoicePrepaymentNo: row.documentNumber,
      invoicePrepaymentId: row.id,
      invoicePrepaymentDate: row.invoiceDate,
      appliedAmount: row.total, // langsung ambil total invoice ap prepayment
      description: row.description || "",
      id: null,
    });

    this.setInvoiceAPPrepayments(data);
    this.recalculatePricing();
  }

  deletePrepayment(doc: InvoiceAPPrepaymentDetail): void {
    const newPrepayments: InvoiceAPPrepaymentDetail[] =
      this.storeInvoiceAPPrepayments.prepaymentLines.filter(
        (item: InvoiceAPPrepaymentDetail) =>
          item.invoicePrepaymentId !== doc.invoicePrepaymentId
      );
    if (doc.id) {
      this.pushDeletedPrepayment(doc.id);
    }
    this.setInvoiceAPPrepayments({ prepaymentLines: newPrepayments });
    this.recalculatePricing();
  }

  resetPrepayments(): void {
    this.setInvoiceAPPrepayments(initInvoiceAPPrepayment());
  }

  handleRefresh(): void {
    this.queryParams.search = this.createQuerySearch();
    this.loading.refresh = true;
    this.getListPrepayment(this.queryParams);
  }

  createQuerySearch(): string {
    const { supplierName, branch, currency } = this.storeAPForm as IForm;
    const searchBy: string[] = [`status~${PREPAYMENT_STATUS.UN_APPLIED}`];

    if (supplierName) {
      searchBy.push(`supplier.secureId~${supplierName}`);
    }

    if (branch) {
      searchBy.push(`branchWarehouse.secureId~${branch.key}`);
    }

    if (currency) {
      searchBy.push(`priceCurrency.currencyCode~${currency}`);
    }

    return searchBy.join("_AND_");
  }

  onChangeTable(pagination: {
    total: number;
    current: number;
    pageSize: number;
  }): void {
    this.queryParams.page = pagination.current - 1;
    if (this.queryParams.limit !== pagination.pageSize) {
      this.queryParams.page = 0;
    }

    this.queryParams.limit = pagination.pageSize;
    this.getListPrepayment(this.queryParams);
  }
}
