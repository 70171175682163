var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "p-3" },
    [
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxRegistrationNumber.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.taxRegistrationNumber.decorator,
                expression: "formRules.taxRegistrationNumber.decorator"
              }
            ],
            attrs: {
              name: _vm.formRules.taxRegistrationNumber.name,
              placeholder: _vm.$t(
                _vm.formRules.taxRegistrationNumber.placeholder
              ),
              autocomplete: "off",
              disabled: true
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxRegistrationName.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.taxRegistrationName.decorator,
                expression: "formRules.taxRegistrationName.decorator"
              }
            ],
            attrs: {
              name: _vm.formRules.taxRegistrationName.name,
              placeholder: _vm.$t(
                _vm.formRules.taxRegistrationName.placeholder
              ),
              autocomplete: "off",
              disabled: true
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxInvoiceDate.label) } },
        [
          _c("a-date-picker", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.taxInvoiceDate.decorator,
                expression: "formRules.taxInvoiceDate.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              name: _vm.formRules.taxInvoiceDate.name,
              placeholder: _vm.$t(_vm.formRules.taxInvoiceDate.placeholder),
              format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
              disabled: _vm.allowEdit,
              "show-time": ""
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.taxInvoiceNumber.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.taxInvoiceNumber.decorator,
                expression: "formRules.taxInvoiceNumber.decorator"
              }
            ],
            attrs: {
              name: _vm.formRules.taxInvoiceNumber.name,
              placeholder: _vm.$t(_vm.formRules.taxInvoiceNumber.placeholder),
              autocomplete: "off",
              disabled: _vm.allowEdit
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }