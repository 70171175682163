import { SearchBuilder } from "@/builder";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  Nullable,
  Pagination,
} from "@/models/constant/interface/common.interface";
import { ReportInvControllingResponseDto } from "@/models/interface/invoice-controlling-report";
import { invoiceServices } from "@/services/invoice.service";
import store from "@/store";
import { Moment } from "moment";
import { useFindMasterType } from "./master-type";
import useDate from "./useDate";

export type InvoicedEnum = "NO" | "YES";
export type FilterField = {
  branchId: string;
  branchName: string;
  date: Array<Moment>;
} & Nullable<{
  customerId: string;
  customerName: string;
  unitCode: string;
  icId: string;
  icNumber: string;
  status: string;
  isInvoiced: InvoicedEnum;
}>;

const useInvoiceControllingReport = () => {
  const findAll = (
    params?: RequestQueryParams
  ): Promise<Pagination<ReportInvControllingResponseDto>> => {
    return invoiceServices.getListReportInvoiceControlling(params);
  };

  const download = (params?: RequestQueryParams) => {
    return invoiceServices.downloadReportInvoiceControlling(params);
  };

  const findAllStatus = () => {
    return useFindMasterType("INVOICE_CONTROLLING_REPORT_STATUS");
  };

  const filterBy = (field: FilterField): string => {
    const builder = new SearchBuilder();
    const query: Array<string> = [];

    query.push(builder.push(["branchId", field.branchId]).build());

    if (field.customerId) {
      query.push(builder.push(["customerId", field.customerId]).build());
    }

    if (field.unitCode) {
      query.push(
        builder.push(["unitCode", field.unitCode], { like: "both" }).build()
      );
    }

    if (field.icId) {
      query.push(builder.push(["icId", field.icId]).build());
    }

    if (field.status) {
      query.push(
        builder
          .push(["invoiceStatus", trimSpaceToUnderscore(field.status)], {
            like: "both",
          })
          .build()
      );
    }

    return query.join(builder.AND);
  };

  const buildReportHeader = (form: FilterField): string => {
    const { toDefaultFormat } = useDate();
    const company: string =
      store.state["authStore"].authData.companyName || "ALL";
    const branch: string = form.branchName || "ALL";
    const customer: string = form.customerName || "ALL";
    const unitCode: string = form.unitCode || "ALL";
    const icNumber: string = form.icNumber || "ALL";
    const byInvoice: string = form.isInvoiced || "ALL";
    const status: string = form.status || "ALL";

    let dateFrom = "ALL";
    let dateTo = "ALL";

    const [start, end] = form.date;
    dateFrom = toDefaultFormat(start);
    dateTo = toDefaultFormat(end);

    const header: Array<string> = [
      company,
      branch,
      customer,
      dateFrom,
      dateTo,
      unitCode,
      icNumber,
      status,
      byInvoice,
    ];

    return header.join(",");
  };

  return { findAll, findAllStatus, filterBy, download, buildReportHeader };
};

export default useInvoiceControllingReport;
