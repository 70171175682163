import { removeDuplicateObj } from "@/helpers/common";
import { Row, useRemoveRows } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { ONE } from "@/models/constant/global.constant";
import {
  initForm,
  initWoDetailDraft,
  initWoSparepartRow,
  initWoUnitRow,
} from "@/store/resources/WorkOrder.resource";
import {
  WorkOrderCreateRequestDto,
  WorkOrderDraftResponseDto,
  WorkOrderSparePartRequestDto,
  WorkOrderUnitRequestDto,
} from "@interface/work-order";
import { Moment } from "moment";

export type WoUnitRow = Row<WorkOrderUnitRequestDto, string> & {
  serialNumber: string;
  assetCategory: string;
  capacity: string;
  type: string;
  model: string;
  location: string;
};
export type WoSparepartRow = Row<WorkOrderSparePartRequestDto, string> & {
  productCode: string;
  productName: string;
  productUom: string;
  productLocation: string;
  qtyAvailable: number;
};
type OmitWoCreateDto = Omit<
  WorkOrderCreateRequestDto,
  "workOrderDate" | "scheduleOrder" | "units" | "spareParts"
>;
export type FormValue = OmitWoCreateDto & {
  workOrderDate: Moment | null;
  scheduleOrder: Moment | null;
  units: Array<WoUnitRow>;
  spareParts: Array<WoSparepartRow>;
  unitCode: string;
  customerName: string;
};
type OmitWoDraftDto = Omit<
  WorkOrderDraftResponseDto,
  "scheduleOrder" | "workOrderDate"
>;
export type WoDetailDraft = OmitWoDraftDto & {
  workOrderDate: Moment | null;
  scheduleOrder: Moment | null;
};

export interface State {
  form: FormValue;
  detailDraft: WoDetailDraft;
}

const state: State = {
  form: initForm(),
  detailDraft: initWoDetailDraft(),
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setDetailDraft: (st: State, payload: WoDetailDraft): void => {
    st.detailDraft = payload;
  },
};

const getters = {
  /**
   * @description
   * get all unit code from unit table
   */
  getAvailableUnits: ({ form: { units } }: State): Array<Option<WoUnitRow>> => {
    const unitAvail = units.map(item => ({
      key: item.assetId,
      label: item.unitCode,
      value: item.assetId,
      meta: item,
    }));
    return removeDuplicateObj(unitAvail, "value");
  },
};

const actions = {
  resetStore: (ctx): void => {
    const { commit } = ctx;
    commit("setForm", initForm());
    commit("setDetailDraft", initWoDetailDraft());
  },
  addUnit: ({ state }): void => {
    const unit: WoUnitRow = initWoUnitRow();
    const local: State = state;
    unit.no = local.form.units.length + ONE;
    local.form.units.push(unit);
  },
  removeUnits: ({ state, commit }, keys: Array<string>): void => {
    const {
      form: { units },
    }: State = state;
    const { newSource } = useRemoveRows(units, keys);

    const form: Partial<FormValue> = {
      units: newSource.map((item, i) => ({ ...item, no: i + ONE })),
    };
    commit("setForm", form);
  },
  addSparepart: ({ state }): void => {
    const { form }: State = state;
    const sparepart = initWoSparepartRow();
    sparepart.no = form.spareParts.length + ONE;
    form.spareParts.push(sparepart);
  },
  removeSpareParts: ({ state, commit }, keys: Array<string>): void => {
    const {
      form: { spareParts },
    }: State = state;
    const { newSource } = useRemoveRows(spareParts, keys);

    const form: Partial<FormValue> = {
      spareParts: newSource.map((item, i) => ({ ...item, no: i + ONE })),
    };
    commit("setForm", form);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
