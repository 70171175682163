export class StringUtils {
  static objectToString(
    obj: Record<string, unknown>,
    delimiter = ""
  ): string {
    const resultArray: string[] = [];

    for (const key in obj) {
      if (key && obj[key]) {
        resultArray.push(`${key} = ${obj[key]}`);
      }
    }

    return resultArray.join(delimiter);
  }
}
