import { Api } from "@/models/class/api.class";
import { GetAgingReport } from "@/models/interface/aging.interface";
import { ReportAgingDashboardHeadDto } from "@/models/interface/ar-aging-report";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class AgingServices extends HttpClient {
  getAgingReportDashboard(
    params?: RequestQueryParamsModel
  ): Promise<ReportAgingDashboardHeadDto> {
    return this.get<ReportAgingDashboardHeadDto>(Api.AgingDashboard, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getAgingReport(params: RequestQueryParamsModel): Promise<GetAgingReport> {
    return this.get<GetAgingReport>(Api.Aging, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getAgingDownload(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.Aging + "/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const agingServices = new AgingServices();
