import {
  buildContactCreateAddressDataDto,
  buildContactCreateAddressDataLineDto,
  buildContactCreateBankDataDto,
  buildContactCreateCustomerDataDto,
  buildContactCreateDto,
  buildContactCreateEmployeeDataDto,
  buildContactCreateSupplierDataDto,
} from "@/builder";
import { generateUUID } from "@/helpers/uuid";
import {
  AddressDataDto,
  AddressDataLineDto,
  BankDataDto,
  ContactDataCreateDto,
  CustomerDataDto,
  DetailContactDataDto,
  EmployeeDataDto,
  SupplierDataDto,
} from "@/models/interface/contact-data";
import {
  ContactFormAccountState,
  ContactFormAddressState,
  ContactFormBankState,
  ContactFormCustomerState,
  ContactFormEmployeeState,
  ContactFormGeneralState,
  ContactFormPicState,
  ContactFormState,
  ContactFormSupplierState,
  EnumGenderType,
} from "@/store/models/Contact.model";
import {
  initContactFormAccountState,
  initContactFormAddressState,
  initContactFormBankState,
  initContactFormCustomerState,
  initContactFormEmployeeState,
  initContactFormGeneralState,
  initContactFormPicState,
  initContactFormState,
  initContactFormSupplierState,
} from "@/store/resources/Contact.resource";
import moment from "moment";

export class ContactMapper {
  static toContactAddressDataLineDto(
    state: ContactFormPicState
  ): AddressDataLineDto {
    const dto: AddressDataLineDto = buildContactCreateAddressDataLineDto();

    dto.picName = state.name;
    dto.telephoneNo = state.telephone;
    dto.phoneNo = state.phone;
    dto.email = state.email;
    dto.position = state.position;

    return dto;
  }

  static toContactAddressDataDto(
    state: ContactFormAddressState
  ): AddressDataDto {
    const dto: AddressDataDto = buildContactCreateAddressDataDto();

    dto.country = state.country;
    dto.cityDistrict = state.city;
    dto.postalCode = state.postalCode;
    dto.address = state.address;
    dto.location = state.location;
    dto.taxType = state.taxType;
    dto.shipTo = state.asShip;
    dto.billTo = state.asBill;
    dto.primaryShipTo = state.isPrimaryShip;
    dto.primaryBillTo = state.isPrimaryBill;
    dto.addressDataLines = state.pics.map(
      ContactMapper.toContactAddressDataLineDto
    );

    return dto;
  }

  static toContactBankDataDto(state: ContactFormBankState): BankDataDto {
    const dto: BankDataDto = buildContactCreateBankDataDto();

    dto.bankAccName = state.accountName;
    dto.bankAccNumber = state.accountNumber;
    dto.bankBranch = state.branchName;
    dto.bankName = state.bankName ?? "";

    return dto;
  }

  static toContactEmployeeDataDto(
    state: ContactFormEmployeeState
  ): EmployeeDataDto {
    const dto: EmployeeDataDto = buildContactCreateEmployeeDataDto();

    dto.nik = state.nik;
    dto.gender = state.gender;
    dto.position = state.department ?? "";
    dto.dateOfBirth = state.dob ? state.dob.format() : "";
    dto.startDate = state.startDate ? state.startDate.format() : "";

    return dto;
  }

  static toContactCustomerDataDto(
    state: ContactFormCustomerState
  ): CustomerDataDto {
    const dto: CustomerDataDto = buildContactCreateCustomerDataDto();

    dto.customerType = state.type ?? "";
    dto.creditLimit = state.creditLimit;
    dto.discount = state.discount;
    dto.taxInvoiceCode = state.taxInvoiceCode ?? "";

    return dto;
  }

  static toContactSupplierDataDto(
    state: ContactFormSupplierState
  ): SupplierDataDto {
    const dto: SupplierDataDto = buildContactCreateSupplierDataDto();

    dto.supplierType = state.type ?? "";

    return dto;
  }

  static toContactDataCreateDto(state: ContactFormState): ContactDataCreateDto {
    const dto: ContactDataCreateDto = buildContactCreateDto();

    dto.firstName = state.general.firstName;
    dto.lastName = state.general.lastName;
    dto.email = state.general.email;
    dto.phoneNumber = state.general.phoneNumber;
    dto.mobileNumber = state.general.mobileNumber;
    dto.fax = state.general.faxNumber;
    dto.taxRegisNumber = state.general.taxRegistrationNumber;
    dto.taxRegisName = state.general.taxRegistrationName;
    dto.active = state.general.isActive;
    dto.supplier = state.general.isSupplier;
    dto.customer = state.general.isCustomer;
    dto.employee = state.general.isSupplier;
    dto.receivableAccountId = state.account.receivable?.key ?? "";
    dto.revenueAccountId = state.account.revenue?.key ?? "";
    dto.payableAccountId = state.account.payable?.key ?? "";
    dto.prepaymentAccountId = state.account.prepayment?.key ?? "";
    dto.top = state.general.termOfPayment ?? null;
    dto.addressDataList = state.addresses.map(
      ContactMapper.toContactAddressDataDto
    );
    dto.bankDataList = state.banks.map(ContactMapper.toContactBankDataDto);
    dto.employeeData = ContactMapper.toContactEmployeeDataDto(
      state.general.employee
    );
    dto.customerData = ContactMapper.toContactCustomerDataDto(
      state.general.customer
    );
    dto.supplierData = ContactMapper.toContactSupplierDataDto(
      state.general.supplier
    );
    dto.copyToCompanyId =
      state.general.customer.companyClones
        ?.filter(item => !!item.key)
        .map(({ key }) => key) ?? [];
    dto.appIds = state.general.customer.thirdApps ?? [];

    return dto;
  }

  static toContactFormEmployeeState(
    data: DetailContactDataDto
  ): ContactFormEmployeeState {
    const state: ContactFormEmployeeState = initContactFormEmployeeState();

    state.department = data.employeeData.position;
    state.dob = moment(data.employeeData.dateOfBirth);
    state.employeeNumber = data.employeeNumber;
    state.gender = data.employeeData.gender as EnumGenderType;
    state.nik = data.employeeData.nik;
    state.startDate = moment(data.employeeData.startDate);

    return state;
  }

  static toContactFormCustomerState(
    data: DetailContactDataDto
  ): ContactFormCustomerState {
    const state: ContactFormCustomerState = initContactFormCustomerState();

    state.creditLimit = data.customerData.creditLimit;
    state.customerNumber = data.customerNumber;
    state.discount = data.customerData.discount;
    state.taxInvoiceCode = data.customerData.taxInvoiceCode;
    state.type = data.customerData.customerType;
    state.thirdApps = data.appIds ?? [];

    return state;
  }

  static toContactFormSupplierState(
    data: DetailContactDataDto
  ): ContactFormSupplierState {
    const state: ContactFormSupplierState = initContactFormSupplierState();

    state.supplierNumber = data.supplierNumber;
    state.type = data.supplierData.supplierType;

    return state;
  }

  static toContactFormGeneralState(
    data: DetailContactDataDto
  ): ContactFormGeneralState {
    const state: ContactFormGeneralState = initContactFormGeneralState();
    state.isCustomer = data.customer;
    state.isEmployee = data.employee;
    state.isSupplier = data.supplier;
    state.isActive = data.active;
    state.firstName = data.firstName;
    state.lastName = data.lastName;
    state.email = data.email;
    state.phoneNumber = data.phoneNumber;
    state.mobileNumber = data.mobileNumber;
    state.faxNumber = data.fax;
    state.taxRegistrationNumber = data.taxRegisNumber;
    state.taxRegistrationName = data.taxRegisName;
    state.termOfPayment = data.top;

    if (data.employee) {
      state.employee = ContactMapper.toContactFormEmployeeState(data);
    }

    if (data.customer) {
      state.customer = ContactMapper.toContactFormCustomerState(data);
    }

    if (data.supplier) {
      state.supplier = ContactMapper.toContactFormSupplierState(data);
    }

    return state;
  }

  static toContactFormPicState(data: AddressDataLineDto): ContactFormPicState {
    const state: ContactFormPicState = initContactFormPicState();

    state.key = generateUUID();
    state.email = data.email;
    state.name = data.picName;
    state.phone = data.phoneNo;
    state.position = data.position;
    state.telephone = data.telephoneNo;
    state.isEdit = false;

    return state;
  }

  static toContactFormAddressState(
    data: AddressDataDto
  ): ContactFormAddressState {
    const state: ContactFormAddressState = initContactFormAddressState();

    state.address = data.address;
    state.city = data.cityDistrict;
    state.country = data.country;
    state.isPrimaryBill = data.primaryBillTo;
    state.isPrimaryShip = data.primaryShipTo;
    state.location = data.location;

    if (
      data.addressDataLines !== null &&
      Array.isArray(data.addressDataLines)
    ) {
      state.pics = data.addressDataLines.map(
        ContactMapper.toContactFormPicState
      );
    }

    state.postalCode = data.postalCode;
    state.taxType = data.taxType;
    state.asBill = data.billTo;
    state.asShip = data.shipTo;
    state.key = generateUUID();

    return state;
  }

  static toContactFormBankState(data: BankDataDto): ContactFormBankState {
    const state: ContactFormBankState = initContactFormBankState();

    state.accountName = data.bankAccName;
    state.accountNumber = data.bankAccNumber;
    state.bankName = data.bankName;
    state.branchName = data.bankBranch;
    state.key = generateUUID();
    state.isEdit = false;

    return state;
  }

  static toContactFormAccountState(
    data: DetailContactDataDto
  ): ContactFormAccountState {
    const state: ContactFormAccountState = initContactFormAccountState();

    if (data.receivableAccount) {
      state.receivable = {
        label: `${data.receivableAccount.code} - ${data.receivableAccount.description}`,
        key: data.receivableAccount.id,
      };
    }
    if (data.revenueAccount) {
      state.revenue = {
        label: `${data.revenueAccount.code} - ${data.revenueAccount.description}`,
        key: data.revenueAccount.id,
      };
    }
    if (data.payableAccount) {
      state.payable = {
        label: `${data.payableAccount.code} - ${data.payableAccount.description}`,
        key: data.payableAccount.id,
      };
    }
    if (data.prepaymentAccount) {
      state.prepayment = {
        label: `${data.prepaymentAccount.code} - ${data.prepaymentAccount.description}`,
        key: data.prepaymentAccount.id,
      };
    }

    return state;
  }

  static toContactFormState(data: DetailContactDataDto): ContactFormState {
    const state: ContactFormState = initContactFormState();

    if (data.addressDataList) {
      state.addresses = data.addressDataList.map(
        ContactMapper.toContactFormAddressState
      );
    }

    if (data.bankDataList) {
      state.banks = data.bankDataList.map(ContactMapper.toContactFormBankState);
    }

    state.general = ContactMapper.toContactFormGeneralState(data);
    state.account = ContactMapper.toContactFormAccountState(data);

    return state;
  }
}
