import { assetsServices } from "@/services/assets.service";

const useUploader = () => {
  const uploadImage = (payload: FormData, module: string) => {
    return assetsServices.createUploadFile(payload, module);
  };

  return { uploadImage };
};

export default useUploader;
