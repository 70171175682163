
import { ModalOptions } from "ant-design-vue/types/modal";
import Vue from "vue";
export default Vue.extend({
  name: "MNotification",
  methods: {
    showNotifWarning(description: string, prop?): void {
      this.$notification.warning({
        message: this.$t("lbl_warning_title").toString(),
        description: this.$t(description, { ...prop }).toString(),
        duration: 30,
      });
    },
    showNotifError(description: string, prop?): void {
      this.$notification.error({
        message: this.$t("lbl_error_title").toString(),
        description: this.$t(description, { ...prop }).toString(),
        duration: 30,
      });
    },
    showNotifSuccess(description: string, prop?): void {
      this.$notification.success({
        message: this.$t("lbl_success").toString(),
        description: this.$t(description, { ...prop }).toString(),
        duration: 30,
      });
    },
    showNotifInfo(description: string, prop?): void {
      this.$notification.info({
        message: this.$t("lbl_info_title").toString(),
        description: this.$t(description, { ...prop }).toString(),
        duration: 30,
      });
    },
    showConfirmationDeleteItems(cb: ModalOptions["onOk"]): void {
      this.$confirm({
        title: this.$t("lbl_modal_delete_title_confirm"),
        content: this.$t("lbl_modal_delete_info", {
          count: this.selectedRowKeys.length,
        }),
        onOk: cb,
      });
    },
    showConfirmationCancel(cb: ModalOptions["onOk"]): void {
      this.$confirm({
        title: this.$t("lbl_cancel_document_confirm"),
        content: this.$t("lbl_cancel_document_info"),
        onOk: cb,
      });
    },
    showConfirmationLeavePage(cb: ModalOptions["onOk"]): void {
      this.$confirm({
        title: this.$t("lbl_confirmation"),
        content: this.$t("lbl_leave_page"),
        onOk: cb,
      });
    },
  },
});
