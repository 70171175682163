import { Option } from "@/models/class/option.class";
import {
  INTERNAL_CONTRACT_BILLING_TYPE,
  INTERNAL_CONTRACT_PERIOD_TYPE,
  INTERNAL_CONTRACT_STATUS,
} from "@/models/enums/InternalContract.enum";
import { CustomAddressDataList } from "@/models/interface/contact.interface";
import {
  InternalContractDetailCreateDTO,
  InternalContractProductCreateDTO,
  InternalContractRequestDTO,
} from "@/models/interface/internal-contract/InternalContractCreate.interface";
import { InternalContractGetDTO } from "@/models/interface/internal-contract/InternalContractDetail.interface";

export type InternalContractState = {
  formModel: Omit<InternalContractRequestDTO, "salary">;
  detailDoc: InternalContractGetDTO | null;
  optsShipToAddress: Option<CustomAddressDataList>[];
};

const state: InternalContractState = {
  formModel: {
    branchId: "",
    referenceNo: "",
    contractDate: "", // internal contract date
    customerId: "",
    shipTo: "",
    billTo: "",
    picName: "",
    currencyId: "",
    periodType: "" as INTERNAL_CONTRACT_PERIOD_TYPE, // rent type
    rentPeriod: 0,
    startRent: "", // rent starting date
    endRent: "", // rent period range = rent start date + rent period (based on period type)
    billingType: "" as INTERNAL_CONTRACT_BILLING_TYPE,
    termOfCondition: "",
    fileContract: "", // attachment url
    salesId: "",
    service: false, // is service (switch button)
    notes: "",
    total: 0, // sum sub total contract details + sum sub total product services
    productServices: [] as InternalContractProductCreateDTO[], // product service lines
    contractDetails: [] as InternalContractDetailCreateDTO[], // unit lines

    deleteLineId: [],
    approverId: "", // inteded to be empty string
    status: "" as INTERNAL_CONTRACT_STATUS,
  },
  detailDoc: null,
  optsShipToAddress: [], // hold ship to address options, used by form header and units form table
};

const mutations = {
  setFields: (
    st: InternalContractState,
    payload: InternalContractRequestDTO
  ): void => {
    st.formModel = payload;
  },
  setDetailDoc: (
    st: InternalContractState,
    payload: InternalContractGetDTO
  ): void => {
    st.detailDoc = payload;
  },
  setOptsShipToAddress: (
    st: InternalContractState,
    payload: Option<CustomAddressDataList>[]
  ): void => {
    st.optsShipToAddress = payload;
  },
};

const actions = {};

const getters = {
  isDayPeriod: (st: InternalContractState): boolean => {
    return st.formModel.periodType === INTERNAL_CONTRACT_PERIOD_TYPE.DAY;
  },
  isMonthPeriod: (st: InternalContractState): boolean => {
    return st.formModel.periodType === INTERNAL_CONTRACT_PERIOD_TYPE.MONTH;
  },
  getOptsShipToAddress: (
    st: InternalContractState
  ): Option<CustomAddressDataList>[] => {
    return st.optsShipToAddress;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
