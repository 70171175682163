export enum LeasingPaymentTypeEnum {
  CHEQUE = "Cheque",
  BANK_TRANSFER = "Bank Transfer",
  AUTO_DEBIT = "Auto Debit",
  CASH = "Cash",
}

export enum LeasingStateEnum {
  SUBMITTED = "Submitted",
  PAID = "Paid",
  CANCELLED = "Cancelled",
  DRAFT = "Draft",
}
