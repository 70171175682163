var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          !_vm.allowEdit
            ? _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleAddRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.handleDeleteRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  dataSource: _vm.storeInvoiceAPLines,
                  columns: _vm.columnsTable,
                  scroll: { x: "calc(700px + 50%)", y: 520 },
                  "row-key": function(r, i) {
                    return i
                  },
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectRow
                  },
                  pagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", { total: total })
                    }
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "partNumber",
                    fn: function(text, ref) {
                      var generated = ref.generated
                      var partNumber = ref.partNumber
                      var productId = ref.productId
                      var no = ref.no
                      return [
                        generated
                          ? _c("span", [_vm._v(_vm._s(partNumber || "-"))])
                          : _c("product-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: productId,
                                query: "type~SERVICE",
                                disabled: _vm.allowEdit
                              },
                              on: {
                                meta: function(e) {
                                  return _vm.onChangeProduct(
                                    e,
                                    no - 1,
                                    "partNumber"
                                  )
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "partName",
                    fn: function(text, ref) {
                      var generated = ref.generated
                      var partName = ref.partName
                      var productId = ref.productId
                      var no = ref.no
                      return [
                        generated
                          ? _c("span", [_vm._v(_vm._s(partName || "-"))])
                          : _c("product-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: productId,
                                "opt-label": "name",
                                query: "type~SERVICE",
                                disabled: _vm.allowEdit
                              },
                              on: {
                                meta: function(e) {
                                  return _vm.onChangeProduct(
                                    e,
                                    no - 1,
                                    "partName"
                                  )
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "merk",
                    fn: function(text, ref) {
                      var generated = ref.generated
                      var merk = ref.merk
                      var no = ref.no
                      return [
                        generated
                          ? _c("span", [_vm._v(_vm._s(merk || "-"))])
                          : _c("master-product-merk-select", {
                              staticClass: "w-100",
                              attrs: { value: merk, disabled: _vm.allowEdit },
                              on: {
                                change: function(e) {
                                  return _vm.onChangeMerk(e, no - 1)
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "qty",
                    fn: function(text, ref) {
                      var qty = ref.qty
                      var no = ref.no
                      return [
                        _c("a-input-number", {
                          attrs: {
                            placeholder: _vm.$t(_vm.formRules.qty.placeholder),
                            value: qty,
                            formatter: _vm.formatterNumber,
                            parser: _vm.reverseFormatNumber,
                            min: 0,
                            precision: _vm.storeBaseDecimalPlace,
                            "allow-clear": "",
                            disabled: _vm.allowEdit
                          },
                          on: {
                            change: function(e) {
                              return _vm.onChangeQty(e, no - 1, "table")
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "uomId",
                    fn: function(text, ref) {
                      var uom = ref.uom
                      return _c("span", {}, [
                        _vm._v(" " + _vm._s(uom || "-") + " ")
                      ])
                    }
                  },
                  {
                    key: "price",
                    fn: function(text, ref) {
                      var price = ref.price
                      var no = ref.no
                      return [
                        _c("a-input-number", {
                          staticClass: "w-100",
                          attrs: {
                            placeholder: _vm.$t(
                              _vm.formRules.price.placeholder
                            ),
                            value: price,
                            formatter: _vm.formatterNumber,
                            parser: _vm.reverseFormatNumber,
                            min: 0,
                            precision: _vm.storeBaseDecimalPlace,
                            "allow-clear": "",
                            disabled: _vm.allowEdit
                          },
                          on: {
                            change: function(e) {
                              return _vm.onChangePrice(e, no - 1, "table")
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "expenseAccount",
                    fn: function(text, ref) {
                      var generated = ref.generated
                      var expenseAccountId = ref.expenseAccountId
                      var expenseAccount = ref.expenseAccount
                      var no = ref.no
                      return [
                        generated
                          ? _c("span", [_vm._v(_vm._s(expenseAccount || "-"))])
                          : _c("expense-account-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: expenseAccountId,
                                disabled: _vm.allowEdit
                              },
                              on: {
                                change: function(e) {
                                  return _vm.onChangeExpenseAccount(e, no - 1)
                                }
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "incomeTaxId",
                    fn: function(text, ref) {
                      var incomeTaxId = ref.incomeTaxId
                      var no = ref.no
                      return [
                        _c("tax-code-select", {
                          staticClass: "w-100",
                          attrs: {
                            value: incomeTaxId,
                            "tax-type": "INCOME_TAX_PAYABLES",
                            disabled: _vm.allowEdit
                          },
                          on: {
                            meta: function(e) {
                              return _vm.onChangeIncomeTax(e, no - 1)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "taxCode",
                    fn: function(text, ref) {
                      var taxCode = ref.taxCode
                      var no = ref.no
                      return [
                        _c("tax-code-select", {
                          staticClass: "w-100",
                          attrs: {
                            value: taxCode,
                            "tax-type": "VAT_IN",
                            disabled: _vm.allowEdit
                          },
                          on: {
                            meta: function(e) {
                              return _vm.onChangeTaxCode(e, no - 1)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "baseAmount",
                    fn: function(text) {
                      return _c("span", {}, [
                        _vm._v(" " + _vm._s(_vm._f("currency")(text)) + " ")
                      ])
                    }
                  },
                  {
                    key: "taxAmount",
                    fn: function(text) {
                      return _c("span", {}, [
                        _vm._v(" " + _vm._s(_vm._f("currency")(text)) + " ")
                      ])
                    }
                  },
                  {
                    key: "subTotal",
                    fn: function(text) {
                      return _c("span", {}, [
                        _vm._v(" " + _vm._s(_vm._f("currency")(text)) + " ")
                      ])
                    }
                  },
                  {
                    key: "description",
                    fn: function(text, ref) {
                      var description = ref.description
                      var no = ref.no
                      return [
                        _c("a-textarea", {
                          attrs: {
                            value: description,
                            "allow-clear": "",
                            disabled: _vm.allowEdit
                          },
                          on: {
                            change: function(e) {
                              return _vm.onChangeDescription(
                                e.target.value,
                                no - 1
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "operation",
                    fn: function(text, record) {
                      return _c("span", {}, [
                        _c(
                          "a",
                          {
                            staticClass: "ant-dropdown-link",
                            on: {
                              click: function($event) {
                                return _vm.showMore(record, record.no - 1)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_more")) + " ")]
                        )
                      ])
                    }
                  },
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "a-row",
                          {
                            staticClass: "flex-column align-items-md-end",
                            attrs: { type: "flex" }
                          },
                          [
                            _c(
                              "a-col",
                              { attrs: { xs: { span: 24 }, lg: { span: 16 } } },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass: "footer-table",
                                    attrs: {
                                      "aria-describedby": "footer table"
                                    }
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-bold text-right pr-3"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_total")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("a-input-number", {
                                            staticClass: "w-100",
                                            attrs: {
                                              value:
                                                _vm.storeInvoiceAPTotalGross,
                                              disabled: "",
                                              placeholder: _vm.$t("lbl_total"),
                                              formatter: _vm.formatterNumber,
                                              parser: _vm.reverseFormatNumber,
                                              min: 0,
                                              precision:
                                                _vm.storeBaseDecimalPlace
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-bold text-right pr-3"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lbl_sub_total_invoice")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("a-input-number", {
                                            staticClass: "w-100",
                                            attrs: {
                                              value: _vm.storeInvoiceAPSubtotal,
                                              disabled: "",
                                              placeholder: _vm.$t(
                                                "lbl_sub_total_invoice"
                                              ),
                                              formatter: _vm.formatterNumber,
                                              parser: _vm.reverseFormatNumber,
                                              min: 0,
                                              precision:
                                                _vm.storeBaseDecimalPlace
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-bold text-right pr-3"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lbl_total_prepayment")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("a-input-number", {
                                            staticClass: "w-100",
                                            attrs: {
                                              value:
                                                _vm.storeInvoiceAPTotalPrepayments,
                                              disabled: "",
                                              placeholder: _vm.$t(
                                                "lbl_total_prepayment"
                                              ),
                                              formatter: _vm.formatterNumber,
                                              parser: _vm.reverseFormatNumber,
                                              min: 0,
                                              precision:
                                                _vm.storeBaseDecimalPlace
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-bold text-right pr-3"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_discount")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "a-row",
                                            { attrs: { gutter: [16, 16] } },
                                            [
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    span: 12,
                                                    xs: 24,
                                                    sm: 24,
                                                    md: 12
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-form-item",
                                                    {
                                                      staticClass: "mb-0 pb-0"
                                                    },
                                                    [
                                                      _c("a-input-number", {
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          value:
                                                            _vm.storeInvoiceAPAdditionalDiscountPercent,
                                                          formatter:
                                                            _vm.formatterPercent,
                                                          parser:
                                                            _vm.reverseFormatPercent,
                                                          min: 0,
                                                          max: 100,
                                                          precision:
                                                            _vm.storeBaseDecimalPlace,
                                                          disabled:
                                                            _vm.allowEdit
                                                        },
                                                        on: {
                                                          change: function(e) {
                                                            return _vm.onChangeAdditionalDiscount(
                                                              e,
                                                              "percent"
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    span: 12,
                                                    xs: 24,
                                                    sm: 24,
                                                    md: 12
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-form-item",
                                                    {
                                                      staticClass: "mb-0 pb-0"
                                                    },
                                                    [
                                                      _c("a-input-number", {
                                                        staticClass: "w-100",
                                                        attrs: {
                                                          value:
                                                            _vm.storeInvoiceAPAdditionalDiscountAmount,
                                                          formatter:
                                                            _vm.formatterNumber,
                                                          parser:
                                                            _vm.reverseFormatNumber,
                                                          min: 0,
                                                          precision:
                                                            _vm.storeBaseDecimalPlace,
                                                          disabled:
                                                            _vm.allowEdit
                                                        },
                                                        on: {
                                                          change: function(e) {
                                                            return _vm.onChangeAdditionalDiscount(
                                                              e,
                                                              "amount"
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-bold text-right pr-3"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_total_tax")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("a-input-number", {
                                            staticClass: "w-100",
                                            attrs: {
                                              value: _vm.storeInvoiceAPTotalTax,
                                              disabled: "",
                                              placeholder: _vm.$t(
                                                "lbl_total_tax"
                                              ),
                                              formatter: _vm.formatterNumber,
                                              parser: _vm.reverseFormatNumber,
                                              min: 0,
                                              precision:
                                                _vm.storeBaseDecimalPlace
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-bold text-right pr-3"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lbl_grand_total")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("a-input-number", {
                                            staticClass: "w-100",
                                            attrs: {
                                              value:
                                                _vm.storeInvoiceAPGrandTotal,
                                              disabled: "",
                                              placeholder: _vm.$t(
                                                "lbl_grand_total"
                                              ),
                                              formatter: _vm.formatterNumber,
                                              parser: _vm.reverseFormatNumber,
                                              min: 0,
                                              precision:
                                                _vm.storeBaseDecimalPlace
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            centered: false,
            "dialog-style": { top: "20px" },
            maskClosable: false,
            width: 920,
            title: _vm.$t("lbl_details")
          },
          model: {
            value: _vm.modal.show,
            callback: function($$v) {
              _vm.$set(_vm.modal, "show", $$v)
            },
            expression: "modal.show"
          }
        },
        [
          _c(
            "a-form",
            _vm._b(
              { attrs: { layout: "vertical", form: _vm.form } },
              "a-form",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.partNumber.label)
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.partNumber.decorator,
                                expression: "formRules.partNumber.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.partNumber.name,
                              placeholder: _vm.$t(
                                _vm.formRules.partNumber.placeholder
                              ),
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.partName.label) }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.partName.decorator,
                                expression: "formRules.partName.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.partName.name,
                              placeholder: _vm.$t(
                                _vm.formRules.partName.placeholder
                              ),
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.partMerk.label) }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.partMerk.decorator,
                                expression: "formRules.partMerk.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.partMerk.name,
                              placeholder: _vm.$t(
                                _vm.formRules.partMerk.placeholder
                              ),
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t(_vm.formRules.qty.label) } },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.qty.decorator,
                                expression: "formRules.qty.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.qty.name,
                              placeholder: _vm.$t(
                                _vm.formRules.qty.placeholder
                              ),
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: _vm.allowEdit
                            },
                            on: {
                              change: function(e) {
                                return _vm.onChangeQty(
                                  e,
                                  _vm.modal.index,
                                  "modal"
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t(_vm.formRules.uom.label) } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.uom.decorator,
                                  expression: "formRules.uom.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.uom.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.uom.placeholder
                                ),
                                disabled: ""
                              }
                            },
                            _vm._l(_vm.dataUom, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(data.value) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(data.value) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t(_vm.formRules.price.label) } },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.price.decorator,
                                expression: "formRules.price.decorator"
                              }
                            ],
                            staticClass: "w-100",
                            attrs: {
                              name: _vm.formRules.price.name,
                              placeholder: _vm.$t(
                                _vm.formRules.price.placeholder
                              ),
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: _vm.allowEdit
                            },
                            on: {
                              change: function(e) {
                                return _vm.onChangePrice(
                                  e,
                                  _vm.modal.index,
                                  "modal"
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.discountValue.label)
                          }
                        },
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: [8, 8] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-item",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _c("a-input-number", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRules.discountPercent
                                                .decorator,
                                            expression:
                                              "formRules.discountPercent.decorator"
                                          }
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          name:
                                            _vm.formRules.discountPercent.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.discountPercent
                                              .placeholder
                                          ),
                                          formatter: _vm.formatterPercent,
                                          parser: _vm.reverseFormatPercent,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          disabled: _vm.allowEdit
                                        },
                                        on: {
                                          change: function(e) {
                                            return _vm.onChangeDiscount(
                                              e,
                                              "percent"
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-item",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _c("a-input-number", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRules.discountValue
                                                .decorator,
                                            expression:
                                              "formRules.discountValue.decorator"
                                          }
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          name:
                                            _vm.formRules.discountValue.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.discountValue
                                              .placeholder
                                          ),
                                          formatter: _vm.formatterNumber,
                                          parser: _vm.reverseFormatNumber,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          disabled: _vm.allowEdit
                                        },
                                        on: {
                                          change: function(e) {
                                            return _vm.onChangeDiscount(
                                              e,
                                              "amount"
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRules.incomeTaxRateValue.label
                            )
                          }
                        },
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-item",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _c("a-input-number", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRules.incomeTaxPercent
                                                .decorator,
                                            expression:
                                              "formRules.incomeTaxPercent.decorator"
                                          }
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          name:
                                            _vm.formRules.incomeTaxPercent.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.incomeTaxPercent
                                              .placeholder
                                          ),
                                          formatter: _vm.formatterPercent,
                                          parser: _vm.reverseFormatPercent,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          disabled: ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-item",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _c("a-input-number", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRules.incomeTaxRateValue
                                                .decorator,
                                            expression:
                                              "formRules.incomeTaxRateValue.decorator"
                                          }
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          name:
                                            _vm.formRules.incomeTaxRateValue
                                              .name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.incomeTaxRateValue
                                              .placeholder
                                          ),
                                          formatter: _vm.formatterNumber,
                                          parser: _vm.reverseFormatNumber,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          disabled: ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.baseAmountDpp.label)
                          }
                        },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.baseAmountDpp.decorator,
                                expression: "formRules.baseAmountDpp.decorator"
                              }
                            ],
                            staticClass: "w-100",
                            attrs: {
                              name: _vm.formRules.baseAmountDpp.name,
                              placeholder: _vm.$t(
                                _vm.formRules.baseAmountDpp.placeholder
                              ),
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSaveModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }