// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const LOGISTIC_ROUTE: RoutePathModel = {
  path: "/logistic",
  url: "/logistic",
  name: "lbl_logistic",
  meta: {
    breadcrumb: "lbl_logistic",
    title_head: "lbl_logistic",
    need_auth: true,
  },
};

export const CLOSEPERIODELOGISTIC_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/closeperiod`,
  url: `${LOGISTIC_ROUTE.url}/closeperiod`,
  name: "logistic.close-period",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_close_period",
        href: `${LOGISTIC_ROUTE.url}/closeperiod`,
      },
    ],
    title_head: "lbl_close_period",
  },
};
export const GOODS_RECEIPT_CHECKLIST_LIST_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist`,
  url: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist`,
  name: "logistic.goods-receipt-checklist",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_goods_receipt_checklist",
        href: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist`,
      },
    ],
    title_head: "lbl_goods_receipt_checklist",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const GOODS_RECEIPT_CHECKLIST_CREATE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist/create`,
  url: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist/create`,
  name: "logistic.goods-receipt-checklist.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_goods_receipt_checklist",
        href: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist`,
      },
      {
        name: "lbl_create",
        href: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist/create`,
      },
    ],
    title_head: "lbl_goods_receipt_checklist_create",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const GOODS_RECEIPT_CHECKLIST_EDIT_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist/:id/edit`,
  url: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist/:id/edit`,
  name: "logistic.goods-receipt-checklist.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_goods_receipt_checklist",
        href: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist`,
      },
      {
        name: "lbl_edit",
        href: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist/:id/edit`,
      },
    ],
    title_head: "lbl_goods_receipt_checklist_edit",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const GOODS_RECEIPT_CHECKLIST_DETAIL_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist/:id`,
  url: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist/:id`,
  name: "logistic.goods-receipt-checklist.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_goods_receipt_checklist",
        href: `${LOGISTIC_ROUTE.url}/goods-receipt-checklist`,
      },
      {
        name: "lbl_detail",
      },
    ],
    title_head: "lbl_goods_receipt_checklist_detail",
    mode: Mode.DETAIL,
    need_auth: true,
  },
};

export const MASTER_PART_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/part`,
  url: `${LOGISTIC_ROUTE.url}/part`,
  name: "logistic.part",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_master_part",
        icon: "snippets",
      },
      {
        name: "lbl_part",
        href: "/logistic/part",
      },
    ],
    title_head: "lbl_part",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const MASTER_PART_EDIT_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/part/:id/edit`,
  url: `${LOGISTIC_ROUTE.url}/part/:id/edit`,
  name: "logistic.part.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_master_part",
        icon: "snippets",
      },
      {
        name: "lbl_part",
        href: "/logistic/part",
      },
      {
        name: "lbl_edit_part",
      },
    ],
    title_head: "lbl_edit_part",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const MASTER_PART_CREATE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/part/create`,
  url: `${LOGISTIC_ROUTE.url}/part/create`,
  name: "logistic.part.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_master_part",
        icon: "snippets",
      },
      {
        name: "lbl_part",
        href: "/logistic/part",
      },
      {
        name: "lbl_create_part",
        href: "/logistic/part/create",
      },
    ],
    title_head: "lbl_create_part",
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const PRODUCT_CATEGORY_INDEX_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/part-category`,
  url: `${LOGISTIC_ROUTE.url}/part-category`,
  name: "logistic.part-category",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_master_part",
        icon: "snippets",
      },
      {
        name: "lbl_part_category",
        href: "/logistic/part-category",
      },
    ],
    title_head: "lbl_part_category",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const PRODUCT_CATEGORY_CREATE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/part-category/create`,
  url: `${LOGISTIC_ROUTE.url}/part-category/create`,
  name: "logistic.part-category.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_master_part",
        icon: "snippets",
      },
      {
        name: "lbl_part_category",
        href: "/logistic/part-category",
      },
    ],
    title_head: "lbl_part_category",
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const PRODUCT_CATEGORY_EDIT_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/part-category/:id/edit`,
  url: `${LOGISTIC_ROUTE.url}/part-category/:id/edit`,
  name: "logistic.part-category.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_master_part",
        icon: "snippets",
      },
      {
        name: "lbl_part_category",
        href: "/logistic/part-category",
      },
    ],
    title_head: "lbl_part_category",
    need_auth: true,
    mode: Mode.EDIT,
  },
};

export const WAREHOUSE_INDEX_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/warehouse`,
  url: `${LOGISTIC_ROUTE.url}/warehouse`,
  name: "logistic.warehouse",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_master_location",
        icon: "environment",
      },
      {
        name: "lbl_warehouse",
        href: "/logistic/warehouse",
      },
    ],
    title_head: "lbl_warehouse",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const BRANCH_INDEX_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/branch`,
  url: `${LOGISTIC_ROUTE.url}/branch`,
  name: "logistic.branch",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_master_location",
        icon: "environment",
      },
      {
        name: "lbl_branch",
        href: "/logistic/branch",
      },
    ],
    title_head: "lbl_branch",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const RACK_INDEX_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/rack`,
  url: `${LOGISTIC_ROUTE.url}/rack`,
  name: "logistic.rack",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_master_location",
        icon: "environment",
      },
      {
        name: "lbl_rack",
        href: "/logistic/rack",
      },
    ],
    title_head: "lbl_rack",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const WAREHOUSETRANSFER_ROUTE: RoutePathModel = {
  path: "warehousetransfer",
  url: `${LOGISTIC_ROUTE.url}/warehousetransfer`,
  name: "lbl_warehouse_transfer",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_warehouse_transfer_setup",
        href: "/logistic/warehousetransfer",
      },
    ],
    title_head: "lbl_warehouse_transfer",
    mode: Mode.EDIT,
  },
};
export const WAREHOUSETRANSFERLIST_ROUTE: RoutePathModel = {
  path: "warehousetransferlist",
  url: `${LOGISTIC_ROUTE.url}/warehousetransferlist`,
  name: "lbl_warehouse_transfer_list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_warehouse_transfer_list",
        href: "/logistic/warehousetransferlist",
      },
    ],
    title_head: "lbl_warehouse_transfer_list",
  },
};

export const WORK_ORDER_CREATE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/work-order/create`,
  url: `${LOGISTIC_ROUTE.url}/work-order/create`,
  name: "logistic.work-order.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_work_order",
        href: `${LOGISTIC_ROUTE.url}/work-order`,
      },
      {
        name: "lbl_create_work_order",
        href: `${LOGISTIC_ROUTE.url}/work-order/create`,
      },
    ],
    title_head: "lbl_create_work_order",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const WORK_ORDER_DETAIL_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/work-order/:id`,
  url: `${LOGISTIC_ROUTE.url}/work-order/:id`,
  name: "logistic.work-order.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_work_order",
        href: `${LOGISTIC_ROUTE.url}/work-order`,
      },
      {
        name: "lbl_edit_work_order",
        href: `${LOGISTIC_ROUTE.url}/work-order/:id`,
      },
    ],
    title_head: "lbl_work_order_detail",
    mode: Mode.DETAIL,
    need_auth: true,
  },
};

export const WORK_ORDER_LIST_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/work-order`,
  url: `${LOGISTIC_ROUTE.url}/work-order`,
  name: "logistic.work-order",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_work_order",
        href: `${LOGISTIC_ROUTE.url}/work-order`,
      },
    ],
    title_head: "lbl_work_order",
    need_auth: true,
  },
};
export const RESULTWORKORDER_ROUTE: RoutePathModel = {
  path: "workorder/result",
  url: `${LOGISTIC_ROUTE.url}/workorder/result`,
  name: "logistic.work-order.result",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_result_customer_maintenance",
        href: `${LOGISTIC_ROUTE.url}/workorder/result`,
      },
    ],
    title_head: "lbl_result_customer_maintenance",
    need_auth: true,
  },
};
export const CREATERESULTWORKORDER_ROUTE: RoutePathModel = {
  path: "workorder/result/create",
  url: `${LOGISTIC_ROUTE.url}/workorder/result/create`,
  name: "lbl_create_customer_maintenance",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_customer_maintenance_list",
        href: `${LOGISTIC_ROUTE.url}/workorder/result`,
      },
      {
        name: "lbl_create_customer_maintenance",
        href: `${LOGISTIC_ROUTE.url}/workorder/result/create`,
      },
    ],
    title_head: "lbl_customer_maintenance_list",
  },
};
export const VIEWRESULTWORKORDER_ROUTE: RoutePathModel = {
  path: "workorder/result/view/:id",
  url: `${LOGISTIC_ROUTE.url}/workorder/result/view/:id`,
  name: "lbl_view_customer_maintenance",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_customer_maintenance_list",
        href: `${LOGISTIC_ROUTE.url}/workorder/result`,
      },
      {
        name: "lbl_view_customer_maintenance",
        href: `${LOGISTIC_ROUTE.url}/workorder/result/view/:id`,
      },
    ],
    title_head: "lbl_customer_maintenance_list",
    mode: Mode.VIEW,
  },
};

export const STOCK_ADJUSTMENT_INDEX_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/stock-adjustment`,
  url: `${LOGISTIC_ROUTE.url}/stock-adjustment`,
  name: "logistic.stock-adjustment",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_stock_adjustment",
        href: `${LOGISTIC_ROUTE.url}/stock-adjustment`,
      },
    ],
    title_head: "lbl_stock_adjustment",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const STOCK_ADJUSTMENT_EDIT_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/stock-adjustment/:id/edit`,
  url: `${LOGISTIC_ROUTE.url}/stock-adjustment/:id/edit`,
  name: "logistic.stock-adjustment.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_stock_adjustment",
        href: `${LOGISTIC_ROUTE.url}/stock-adjustment`,
      },
      {
        name: "lbl_edit",
      },
    ],
    title_head: "lbl_edit_stock_adjustment",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const STOCK_ADJUSTMENT_DETAIL_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/stock-adjustment/:id`,
  url: `${LOGISTIC_ROUTE.url}/stock-adjustment/:id`,
  name: "logistic.stock-adjustment.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_stock_adjustment",
        href: `${LOGISTIC_ROUTE.url}/stock-adjustment`,
      },
      {
        name: "lbl_detail",
      },
    ],
    title_head: "lbl_detail_stock_adjustment",
    mode: Mode.DETAIL,
    need_auth: true,
  },
};

export const STOCK_ADJUSTMENT_CREATE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/stock-adjustment/create`,
  url: `${LOGISTIC_ROUTE.url}/stock-adjustment/create`,
  name: "logistic.stock-adjustment.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_stock_adjustment",
        href: `${LOGISTIC_ROUTE.url}/stock-adjustment`,
      },
      {
        name: "lbl_create",
      },
    ],
    title_head: "lbl_create_stock_adjustment",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const REPORT_STOCK_ADJUSMENT_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/reports/stock-adjustment`,
  url: `${LOGISTIC_ROUTE.url}/reports/stock-adjustment`,
  name: "lbl_stock_adjustment",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_stock_adjustment",
        href: `${LOGISTIC_ROUTE.url}/reports/stock-adjustment`,
      },
    ],
    title_head: "lbl_stock_adjustment",
  },
};

export const STOCK_REPORT_INDEX_ROUTE: RoutePathModel = {
  path: `/logistic/reports/stock-report`,
  url: `/logistic/reports/stock-report`,
  name: "logistic.reports.stock-report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_stock_report",
        href: `${LOGISTIC_ROUTE.url}/reports/stock-report`,
      },
    ],
    title_head: "lbl_stock_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const REPORTS_CUSTOMER_MAINTENANCE_ROUTE: RoutePathModel = {
  path: `/logistic/reports/customermaintenance`,
  url: `/logistic/reports/customermaintenance`,
  name: "lbl_work_order_report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_work_order_report",
        href: `${LOGISTIC_ROUTE.url}/reports/customermaintenance`,
      },
    ],
    title_head: "lbl_work_order_report",
  },
};

export const REPORTKARTU_ROUTE: RoutePathModel = {
  path: `/logistic/reports/kartu`,
  url: `/logistic/reports/kartu`,
  name: "lbl_valuation_report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_valuation_report",
        href: `${LOGISTIC_ROUTE.url}/reports/kartu`,
      },
    ],
    title_head: "lbl_valuation_report",
  },
};

/**
 * @deprecated
 */
export const LISTOFPRODUCTBRANCH_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/listofproduct/branch`,
  url: `${LOGISTIC_ROUTE.url}/listofproduct/branch`,
  name: "lbl_product_branch_list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_list_of_product",
        icon: "book",
      },
      {
        name: "lbl_branch",
        href: "/logistic/listofproduct/branch",
      },
    ],
    title_head: "lbl_product_branch_list",
  },
};

/**
 * @deprecated
 */
export const LISTOFPRODUCTWAREHOUSE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/listofproduct/warehouse`,
  url: `${LOGISTIC_ROUTE.url}/listofproduct/warehouse`,
  name: "lbl_product_warehouse_list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_list_of_product",
        icon: "book",
      },
      {
        name: "lbl_warehouse",
        href: "/logistic/listofproduct/warehouse",
      },
    ],
    title_head: "lbl_product_warehouse_list",
  },
};

/**
 * @deprecated
 */
export const LISTOFPRODUCTRACK_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/listofproduct/rack`,
  url: `${LOGISTIC_ROUTE.url}/listofproduct/rack`,
  name: "lbl_product_rack_list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_list_of_product",
        icon: "book",
      },
      {
        name: "lbl_rack",
        href: "/logistic/listofproduct/rack",
      },
    ],
    title_head: "lbl_product_rack_list",
  },
};

export const PRODUCT_INVENTORY_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/product-inventory`,
  url: `${LOGISTIC_ROUTE.url}/product-inventory`,
  name: "logistic.product-inventory",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
        href: "",
      },
      {
        name: "lbl_list_of_product",
        icon: "book",
        href: "/logistic/product-inventory",
      },
    ],
    title_head: "lbl_list_of_product",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const RETURNFROMCUSTOMER_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/returnfromcustomer`,
  url: `${LOGISTIC_ROUTE.url}/returnfromcustomer`,
  name: "lbl_return_from_customer",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "database",
      },
      {
        name: "lbl_return_from_customer",
        href: "/logistic/returnfromcustomer",
      },
    ],
    title_head: "lbl_return_from_customer",
  },
};
export const CSF_LIST_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/csf/list`,
  url: `${LOGISTIC_ROUTE.url}/csf/list`,
  name: "lbl_csf_list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_csf_list",
        href: "/logistic/csf/list",
      },
    ],
    title_head: "lbl_csf_list",
  },
};
export const TRANSFER_TO_ASSET_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/transfer-to-asset`,
  url: `${LOGISTIC_ROUTE.url}/transfer-to-asset`,
  name: "lbl_transfer_to_asset",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_transfer_to_asset",
        href: "/logistic/transfer-to-asset",
      },
    ],
    title_head: "lbl_transfer_to_asset",
  },
};
export const CREATE_TRANSFER_TO_ASSET_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/transfer-to-asset/create`,
  url: `${LOGISTIC_ROUTE.url}/transfer-to-asset/create`,
  name: "lbl_create_transfer_to_asset",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_transfer_to_asset",
        href: "/logistic/transfer-to-asset",
      },
      {
        name: "lbl_create_transfer_to_asset",
        href: "/logistic/transfer-to-asset/create",
      },
    ],
    mode: Mode.CREATE,
    title_head: "lbl_create_transfer_to_asset",
  },
};
export const VIEW_TRANSFER_TO_ASSET_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/transfer-to-asset/view`,
  url: `${LOGISTIC_ROUTE.url}/transfer-to-asset/view`,
  name: "lbl_view_transfer_to_asset",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_transfer_to_asset",
        href: "/logistic/transfer-to-asset",
      },
      {
        name: "lbl_view_transfer_to_asset",
        href: "/logistic/transfer-to-asset/view",
      },
    ],
    mode: Mode.VIEW,
    title_head: "lbl_view_transfer_to_asset",
  },
};
export const RETURNFROMCUSTOMERCREATE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/returnfromcustomer/create`,
  url: `${LOGISTIC_ROUTE.url}/returnfromcustomer/create`,
  name: "lbl_create_return_from_customer",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "database",
      },
      {
        name: "lbl_return_from_customer",
        href: "/logistic/returnfromcustomer",
      },
      {
        name: "lbl_create_return_from_customer",
        href: "/logistic/returnfromcustomer/create",
      },
    ],
    title_head: "lbl_create_return_from_customer",
    mode: Mode.CREATE,
  },
};
export const RETURNFROMCUSTOMEREDIT_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/returnfromcustomer/edit/:id`,
  url: `${LOGISTIC_ROUTE.url}/returnfromcustomer/edit/:id`,
  name: "lbl_edit_return_from_customer",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "database",
      },
      {
        name: "lbl_return_from_customer",
        href: "/logistic/returnfromcustomer",
      },
      {
        name: "lbl_edit_return_from_customer",
        href: "/logistic/returnfromcustomer/edit/:id",
      },
    ],
    title_head: "lbl_edit_return_from_customer",
    mode: Mode.EDIT,
  },
};

export const FIFO_REPORT_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/reports/fifo-report`,
  url: `${LOGISTIC_ROUTE.url}/reports/fifo-report`,
  name: "logistic.report.fifo-report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "book",
      },
      {
        name: "lbl_fifo_report",
        href: `${LOGISTIC_ROUTE.url}/reports/fifo-report`,
      },
    ],
    title_head: "lbl_fifo_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};
