// Core
// Class
import { Api } from "@/models/class/api.class";
// Interfaces
import { Pagination } from "@/models/constant/interface/common.interface";
import { HeadProductFifoValuationReportDTO } from "@/models/interface/FifoValuationReport.interface";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import {
  GoodsReceiveChecklistResponse,
  RequestReceivingItems,
  RequestResultWorkOrder,
  ResponseDetailProduct,
  ResponseDetailUomConversions,
  ResponseGetListWorkOrder,
  ResponseGetListWorkOrderHeader,
  ResponseGetStockAdjustment,
  ResponseListOfInventory,
  ResponseListOfReceivingItems,
  ResponseListProduct,
  ResponseReportValuation,
  ResponseReportWorkOrderResult,
  ResponseResultWorkOrder,
  ResponseReturnNumber,
  ResponseWarehouse,
  ResponseWarehouseBranch,
  ResponseWarehouseLocation,
  ResponseWorkOrderCsf,
  ResponseWorkOrderDetail,
  ResponseWorkOrderResult,
  ResponseWorkOrderResultDetail,
} from "@/models/interface/logistic.interface";
import { ProductResponseDto } from "@/models/interface/master-product";
import { ReceivingItemDraftResponseDTO } from "@/models/interface/receive-item.interface";
import {
  RequestReturnFromCustomer,
  SalesReturnResponseDTO,
} from "@/models/interface/SalesReturn.interface";
import { StockCardReportResultDto } from "@/models/interface/StockReport.interface";
import { HeadStockValuationReportCreateDTO } from "@/models/interface/StockValuationReport.interface";
import {
  DataListWarehouseTransfer,
  DetailWarehouseTransfer,
  RequestWarehouseTransfer,
  ResponseListWarehouseTransfer,
} from "@/models/interface/warehouse.interface";
import {
  BranchWarehouseCreateRequestDto,
  BranchWarehouseResponseDto,
} from "@interface/branch";
import {
  BaseResponse,
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@interface/http.interface";
import {
  WarehouseLocationCreateRequestDto,
  WarehouseLocationResponseDto,
} from "@interface/location";
import {
  WarehouseCreateRequestDto,
  WarehouseCreateResponseDto,
  WarehouseResponseDto,
} from "@interface/warehouse";
import {
  ResultWorkOrderListFilterResponseDto,
  WorkOrderCreateRequestDto,
  WorkOrderDraftResponseDto,
  WorkOrderListResponseDto,
  WorkOrderResponseDto,
} from "@interface/work-order";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class LogisticServices extends HttpClient {
  public constructor() {
    super();
  }

  /**
   * @deprecated
   * new service {@linkcode getListProduct}
   */
  listProduct(params: RequestQueryParamsModel): Promise<ResponseListProduct> {
    return this.get<ResponseListProduct>(Api.Product, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListProduct(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ProductResponseDto>> {
    return this.get<Pagination<ProductResponseDto>>(Api.Product, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode productService.getDetailProduct}
   */
  getDetailProduct = (id: string): Promise<ResponseDetailProduct> => {
    return this.get<ResponseDetailProduct>(Api.Product + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailUomConversions = (
    id: string
  ): Promise<ResponseDetailUomConversions> => {
    return this.get<ResponseDetailUomConversions>(
      Api.DetailUomConversions + `/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListStockReport(
    params: RequestQueryParamsModel
  ): Promise<StockCardReportResultDto> {
    return this.get<StockCardReportResultDto>(Api.ReportKartuStock, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printListOfProduct(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.ProductReport + "/print", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadListOfProduct(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.ProductReport + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadStockReport(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.ReportKartuStock + `/download`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   */
  getPrintKartuStock = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.ReportKartuStock + `/print`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   */
  getReportValuation = (
    params: RequestQueryParamsModel,
    asOfDate: string
  ): Promise<ResponseReportValuation> => {
    return this.get<ResponseReportValuation>(
      Api.ReportValuation + `/${asOfDate}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListReportValuation(
    asOfDate: string,
    params: RequestQueryParamsModel
  ): Promise<HeadStockValuationReportCreateDTO> {
    return this.get<HeadStockValuationReportCreateDTO>(
      Api.ReportValuation + "/list/" + asOfDate,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDownloadValuation(
    params: RequestQueryParamsModel,
    asOfDate: string
  ): Promise<Blob> {
    return this.get<Blob>(Api.ReportValuation + `/${asOfDate}/download`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getPrintValuation(
    params: RequestQueryParamsModel,
    asOfDate: string
  ): Promise<Blob> {
    return this.get<Blob>(Api.ReportValuation + `/${asOfDate}/print`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDownloadStockAdjustment = (
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.StockAdjustment + `/report/download`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDownloadStockAdjustmentCogs = (
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(
      Api.StockAdjustment + `/report/download/cogs`,
      { params, responseType: "arraybuffer" }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDownloadWorkOrder = (woId: string): Promise<Blob> => {
    return this.get<Blob>(Api.DownloadCsfWorkorder + `/${woId}`, {
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  printCsfFromWO(woId: string): Promise<Blob> {
    return this.get<Blob>(`${Api.PrintCSFFromWorkOrder}/${woId}`, {
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * use {@linkcode downloadWorkOrder}
   */
  getDownloadListWorkOrder = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.DownloadListWorkorder, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode printWorkOrder}
   */
  getPrintListWorkOrder = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.PrintListWorkorder, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPrintStockAdjustment = (
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.StockAdjustment + `/report/print`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPrintStockAdjustmentCogs = (
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.StockAdjustment + `/report/print/cogs`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDownloadWarehouseTransfer = (
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.WarehouseTransfer + `/report/download`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDownloadWarehouseTransferForm = (id: string): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.WarehouseTransfer + `/print/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPrintWarehouseTransfer = (
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.WarehouseTransfer + `/report/print`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getStockAdjustment = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetStockAdjustment> => {
    return this.get<ResponseGetStockAdjustment>(Api.StockAdjustment, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListResultWorkOrder(
    params: RequestQueryParamsModel
  ): Promise<ResponseWorkOrderResult> {
    return this.get<ResponseWorkOrderResult>(Api.Workorder + "/result", {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListWorkOrderResultFilter(
    params: RequestQueryParamsModel
  ): Promise<Pagination<ResultWorkOrderListFilterResponseDto>> {
    return this.get<Pagination<ResultWorkOrderListFilterResponseDto>>(
      Api.WorkOrderResultFilter,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListReportResultWorkOrder(
    params: RequestQueryParamsModel
  ): Promise<ResponseReportWorkOrderResult> {
    return this.get<ResponseReportWorkOrderResult>(
      Api.Workorder + "/result/report",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getResultWorkOrderDetail = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<ResponseWorkOrderResultDetail> => {
    return this.get<ResponseWorkOrderResultDetail>(
      Api.Workorder + `/result/${id}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDownloadResultdWorkOrder = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.Workorder + `/result/report/download`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getPrintResultdWorkOrder = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.Workorder + `/result/report/print`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode getAllWorkOrderHeader}
   */
  getListWorkOrderHeader = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListWorkOrderHeader> => {
    return this.get<ResponseGetListWorkOrderHeader>(
      Api.Workorder + "/headers",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getAllWorkOrderHeader(
    params: RequestQueryParamsModel
  ): Promise<Pagination<WorkOrderResponseDto>> {
    return this.get<Pagination<WorkOrderResponseDto>>(Api.WorkorderHeaders, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * use {@linkcode getAllWorkOrder}
   */
  getListWorkOrder = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListWorkOrder> => {
    return this.get<ResponseGetListWorkOrder>(Api.Workorder, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getAllWorkOrder(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<WorkOrderListResponseDto>> {
    return this.get<Pagination<WorkOrderListResponseDto>>(Api.WorkorderList, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadWorkOrder(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.WorkorderListDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printWorkOrder(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.WorkorderListPrint, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public createWorkOrder(
    payload: WorkOrderCreateRequestDto
  ): Promise<WorkOrderResponseDto> {
    return this.post<WorkOrderResponseDto, WorkOrderCreateRequestDto>(
      Api.Workorder,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailWorkOrder(id: string): Promise<WorkOrderResponseDto> {
    return this.get<WorkOrderResponseDto>(`${Api.Workorder}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListWorkOrderDetailByIdAndCsf = (
    params: RequestQueryParamsModel,
    id: string,
    csf: string
  ): Promise<ResponseWorkOrderDetail> => {
    return this.get<ResponseWorkOrderDetail>(
      Api.Workorder + `/${id}/csf/${csf}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListWorkOrderCsf(
    params: RequestQueryParamsModel
  ): Promise<ResponseWorkOrderCsf> {
    return this.get<ResponseWorkOrderCsf>(Api.Workorder + `/csf`, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadCsfList(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.DownloadCsfList, { params, responseType: "blob" })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListWorkOrderCsfPrint = (id: string): Promise<Blob> => {
    return this.get<Blob>(Api.Workorder + `/print/csf/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListWorkOrderDetailCsf = (
    params: RequestQueryParamsModel,
    id: string
  ): Promise<ResponseWorkOrderDetail> => {
    return this.get<ResponseWorkOrderDetail>(Api.Workorder + `/csf/${id}`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getWorkOrderDocNo = (params: RequestQueryParamsModel): Promise<string> => {
    return this.get<string>(Api.Workorder + "/doc-no", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  cancelResultWorkOrder = (idResultWorkOrder: string): Promise<string> => {
    return this.post<string, undefined>(
      Api.Workorder + `/result/cancel/${idResultWorkOrder}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createResultWorkOrder = (
    payload: RequestResultWorkOrder
  ): Promise<ResponseResultWorkOrder> => {
    return this.post<ResponseResultWorkOrder, RequestResultWorkOrder>(
      Api.Workorder + "/result",
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createReturnFromCustomer(
    payload: RequestReturnFromCustomer
  ): Promise<SalesReturnResponseDTO> {
    return this.post<SalesReturnResponseDTO, RequestReturnFromCustomer>(
      Api.ReturnNumber,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service use {@linkcode getListWarehouse}
   */
  public listWarehouse(
    params: RequestQueryParamsModel,
    path: string
  ): Promise<ResponseWarehouse> {
    return this.get<ResponseWarehouse>(Api.Warehouse + path, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListWarehouse(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<WarehouseResponseDto>> {
    return this.get<Pagination<WarehouseResponseDto>>(Api.Warehouse, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailWarehouse(id: string): Promise<WarehouseResponseDto> {
    return this.get<WarehouseResponseDto>(Api.Warehouse + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public createWarehouse(
    payload: WarehouseCreateRequestDto
  ): Promise<WarehouseCreateResponseDto> {
    return this.post<WarehouseCreateResponseDto, WarehouseCreateRequestDto>(
      Api.Warehouse,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public updateWarehouse(
    payload: WarehouseCreateRequestDto,
    path: string
  ): Promise<WarehouseResponseDto> {
    return this.put<WarehouseResponseDto, WarehouseCreateRequestDto>(
      `${Api.Warehouse}/${path}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public deleteWarehouse(path: string): Promise<any> {
    return this.delete<any>(Api.Warehouse + path)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public getDetailDrafWo(path: string): Promise<WorkOrderDraftResponseDto> {
    return this.get<WorkOrderDraftResponseDto>(`${Api.WorkorderDraft}/${path}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  downloadReportReceivingItems = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.ReceiveItemDownload, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  printReportReceivingItems = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.ReceiveItemPrint, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  downloadReportReturnFromCustomer = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.DownloadReturnFromCustomer, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  printReportReturnFromCustomer = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.PrintReturnFromCustomer, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  public closePeriodInventory(params: string): Promise<boolean> {
    return this.post<boolean, any>(Api.ClosePeriodInventory + params)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode getListInventoryLine}
   */
  public listOfInventory(
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfInventory> {
    return this.get<ResponseListOfInventory>(Api.InventoryLine, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListInventoryLine(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<InventoryLineResponseDto>> {
    return this.get<Pagination<InventoryLineResponseDto>>(Api.InventoryLine, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listOfReceivingItems(
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfReceivingItems> {
    return this.get<ResponseListOfReceivingItems>(Api.ReceiveItem, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  public listOfReceivingItemsAvailable(
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfReceivingItems> {
    return this.get<ResponseListOfReceivingItems>(Api.ReceiveItemAvailable, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  public listOfReceivingItemsAvailableInvoiceAp(
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfReceivingItems> {
    return this.get<ResponseListOfReceivingItems>(
      Api.ReceiveItemAvailableInvoiceAp,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  public listOfReceivingItemsChecklist(
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfReceivingItems> {
    return this.get<ResponseListOfReceivingItems>(Api.ReceiveItemChecklist, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listOfReceivingItemsChecklistV2(
    params: RequestQueryParamsModel
  ): Promise<GoodsReceiveChecklistResponse> {
    return this.get<GoodsReceiveChecklistResponse>(Api.ReceiveItemChecklist, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  public listOfReceivingItemsChecklistHeader(
    params: RequestQueryParamsModel
  ): Promise<GoodsReceiveChecklistResponse> {
    return this.get<GoodsReceiveChecklistResponse>(
      Api.ReceiveItemChecklist + "/header",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  public getOfReceivingDocument(): Promise<string> {
    return this.get<string>(Api.ReceiveItemDocument)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode getListWarehouseBranch}
   */
  public listWarehouseBranch(
    params: RequestQueryParamsModel,
    path: string
  ): Promise<ResponseWarehouseBranch> {
    return this.get<ResponseWarehouseBranch>(Api.WarehouseBranch + path, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListWarehouseBranch(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<BranchWarehouseResponseDto>> {
    return this.get<Pagination<BranchWarehouseResponseDto>>(
      Api.WarehouseBranch,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailWarehouseBranch(id: string): Promise<BranchWarehouseResponseDto> {
    return this.get<BranchWarehouseResponseDto>(Api.WarehouseBranch + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createWarehouseBranch(
    payload: BranchWarehouseCreateRequestDto
  ): Promise<BranchWarehouseResponseDto> {
    return this.post<
      BranchWarehouseResponseDto,
      BranchWarehouseCreateRequestDto
    >(Api.WarehouseBranch, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateWarehouseBranch(
    payload: BranchWarehouseCreateRequestDto,
    id: string
  ): Promise<BranchWarehouseResponseDto> {
    return this.put<
      BranchWarehouseResponseDto,
      BranchWarehouseCreateRequestDto
    >(`${Api.WarehouseBranch}/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public getReceiveItems(
    params: RequestQueryParamsModel,
    path: string
  ): Promise<RequestReceivingItems> {
    return this.get<RequestReceivingItems>(Api.ReceiveItemDraft + `/${path}`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode ReceiveItemService.getDetailDraftReceiveItem}
   */
  public getDetailReceiveItem(
    id: string
  ): Promise<ReceivingItemDraftResponseDTO> {
    return this.get<ReceivingItemDraftResponseDTO>(
      `${Api.ReceiveItemDraft}/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode ReceiveItemService.createReceiveItem}
   */
  public createReceivingItems(
    payload: RequestReceivingItems
  ): Promise<boolean> {
    return this.post<boolean, RequestReceivingItems>(Api.ReceiveItem, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode ReceiveItemService.updateReceiveItem}
   */
  public updatebyIdReceivingItems(
    payload: RequestReceivingItems,
    id: string
  ): Promise<boolean> {
    return this.put<boolean, RequestReceivingItems>(
      Api.ReceiveItem + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode ReceiveItemService.createReceiveItemAsDraft}
   */
  public createReceivingItemsDraft(
    payload: RequestReceivingItems
  ): Promise<boolean> {
    return this.post<boolean, RequestReceivingItems>(
      Api.ReceiveItemDraft,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode ReceiveItemService.submitReceiveItem}
   */
  public updateReceivingItems(
    payload: RequestReceivingItems,
    path: string
  ): Promise<boolean> {
    return this.post<boolean, RequestReceivingItems>(
      Api.ReceiveItemUpdateCheck + `/${path}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  public updateReceivingItemsSubmit(
    payload: RequestReceivingItems,
    path: string
  ): Promise<boolean> {
    return this.post<boolean, RequestReceivingItems>(
      Api.ReceiveItemSubmit + `/${path}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public deleteWarehouseBranch(path: string): Promise<any> {
    return this.delete<any>(Api.WarehouseBranch + path)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode getListWarehouseLocation}
   */
  public listWarehouseLocation(
    params: RequestQueryParamsModel,
    path: string
  ): Promise<ResponseWarehouseLocation> {
    return this.get<ResponseWarehouseLocation>(Api.WarehouseLocation + path, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListWarehouseLocation(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<WarehouseLocationResponseDto>> {
    return this.get<Pagination<WarehouseLocationResponseDto>>(
      Api.WarehouseLocation,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public createWarehouseLocation(
    payload: WarehouseLocationCreateRequestDto
  ): Promise<BaseResponse> {
    return this.post<BaseResponse, WarehouseLocationCreateRequestDto>(
      Api.WarehouseLocation,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public updateWarehouseLocation(
    payload: WarehouseLocationCreateRequestDto,
    path: string
  ): Promise<WarehouseLocationResponseDto> {
    return this.put<
      WarehouseLocationResponseDto,
      WarehouseLocationCreateRequestDto
    >(`${Api.WarehouseLocation}/${path}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailWarehouseLocation(
    id: string
  ): Promise<WarehouseLocationResponseDto> {
    return this.get<WarehouseLocationResponseDto>(
      Api.WarehouseLocation + "/" + id
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listReturnNumber(
    params: RequestQueryParamsModel
  ): Promise<ResponseReturnNumber> {
    return this.get<ResponseReturnNumber>(Api.ReturnNumber, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public deleteWarehouseLocation(path: string): Promise<any> {
    return this.delete<any>(Api.WarehouseLocation + path)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * get all warehouse transfer list
   * @param params query params
   * @returns Promise
   */
  public listWarehouseTransfer(
    params: RequestQueryParamsModel
  ): Promise<ResponseListWarehouseTransfer> {
    return this.get<ResponseListWarehouseTransfer>(Api.WarehouseTransfer, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * create a new warehouse transfer
   * @param payload request warehouse transfer
   * @returns Promise
   */
  public createWarehouseTransfer(
    payload: RequestWarehouseTransfer
  ): Promise<DataListWarehouseTransfer> {
    return this.post<DataListWarehouseTransfer, RequestWarehouseTransfer>(
      Api.WarehouseTransfer,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * get detail warehouse transfer
   * @param id warehouse transfer id
   * @returns Promise
   */
  public detailWarehouseTransfer(id: string): Promise<DetailWarehouseTransfer> {
    return this.get<DetailWarehouseTransfer>(Api.WarehouseTransfer + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * update warehouse transfer
   * @param id warehouse transfer id
   * @param payload request warehouse transfer
   * @returns Promise
   */
  public updateWarehouseTransfer(
    id: string,
    payload: RequestWarehouseTransfer
  ): Promise<DataListWarehouseTransfer> {
    return this.put<DataListWarehouseTransfer, RequestWarehouseTransfer>(
      Api.WarehouseTransfer + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * cancel warehouse transfer
   * @param id warehouse transfer id
   * @returns Promise
   */
  public cancelWarehouseTransfer(
    id: string
  ): Promise<DataListWarehouseTransfer> {
    return this.put<DataListWarehouseTransfer, boolean>(
      Api.WarehouseTransferCancel + `/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * receive warehouse transfer
   * @param id warehouse transfer id
   * @returns Promise
   */
  public receiveWarehouseTransfer(
    id: string,
    payload: RequestWarehouseTransfer
  ): Promise<DataListWarehouseTransfer> {
    return this.put<DataListWarehouseTransfer, RequestWarehouseTransfer>(
      Api.WarehouseTransferReceive + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * submit warehouse transfer
   * @param id warehouse transfer id
   * @returns Promise
   */
  public submitWarehouseTransfer(
    id: string,
    payload: RequestWarehouseTransfer
  ): Promise<DataListWarehouseTransfer> {
    return this.put<DataListWarehouseTransfer, RequestWarehouseTransfer>(
      Api.WarehouseTransferSubmit + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public getFifoReport(
    param: RequestQueryParamsModel
  ): Promise<HeadProductFifoValuationReportDTO> {
    return this.get<HeadProductFifoValuationReportDTO>(Api.FifoReport, {
      params: param,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public downloadFifoReport(param: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(`${Api.FifoReport}/download`, {
      params: param,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode ReceiveItemService.printGrChecklistReport}
   */
  public printGrChecklistReport(id: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.ReceiveItemChecklistPrint}/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  public printFifoReport(param: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(`${Api.FifoReport}/print`, {
      params: param,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * Get list of available work order for creating a picking list
   * @param params query param
   * @returns Promise
   */
  public getAvailableWorkOrder(
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListWorkOrder> {
    return this.get<ResponseGetListWorkOrder>(`${Api.Workorder}/available/pl`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const logisticServices = new LogisticServices();
