import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import moment, { Moment } from "moment";

const useDate = () => {
  const toStartDay = (date: Moment) => {
    return date.set({ hour: 0, minute: 0, second: 0 });
  };

  const toStartMonth = (date: Moment) => {
    return moment(date).startOf("month");
  };

  const toEndMonth = (date: Moment) => {
    return moment(date).endOf("month");
  };

  const toEndDay = (date: Moment) => {
    return date.set({ hour: 23, minute: 59, second: 59 });
  };

  const getHour = (date: Moment) => {
    return date.get("hour");
  };

  const getMinute = (date: Moment) => {
    return date.get("minute");
  };

  const getSecond = (date: Moment) => {
    return date.get("second");
  };

  const now = () => {
    return moment();
  };

  const toDefaultFormat = (date: Moment) => {
    return date.format(DEFAULT_DATE_FORMAT);
  };

  const toStartYear = (year: Moment) => {
    const JANUARY = 1;
    return year.set({ day: 1, month: JANUARY });
  };

  const toEndYear = (year: Moment) => {
    const DECEMBER = 12;
    return year.set({ day: 31, month: DECEMBER });
  };

  const toAsOfDate = (date: Moment): string => {
    return date.format(DATE_FORMAT_YYYY_MM_DD);
  };

  return {
    toAsOfDate,
    toEndYear,
    toStartYear,
    toStartDay,
    toEndDay,
    getHour,
    getMinute,
    getSecond,
    now,
    toDefaultFormat,
    toStartMonth,
    toEndMonth,
  };
};

export default useDate;
