var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "p-3" },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 17 } },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "danger", disabled: _vm.allowEdit },
                  on: { click: _vm.resetPrepayments }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.storeInvoiceAPPrepayments.prepaymentLines
                        .length,
                      expression:
                        "!storeInvoiceAPPrepayments.prepaymentLines.length"
                    }
                  ]
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_no_document_selected")) + " ")]
              ),
              _vm._l(_vm.storeInvoiceAPPrepayments.prepaymentLines, function(
                doc
              ) {
                return _c(
                  "a-button",
                  {
                    key: doc.invoicePrepaymentNo,
                    staticClass: "ml-2",
                    attrs: { type: "dashed", disabled: _vm.allowEdit },
                    on: {
                      click: function($event) {
                        return _vm.handleDeleteRow(doc)
                      }
                    }
                  },
                  [
                    _c("a-icon", {
                      staticStyle: { color: "#f5222d" },
                      attrs: { type: "delete", theme: "filled" }
                    }),
                    _vm._v(" " + _vm._s(doc.invoicePrepaymentNo || "-") + " ")
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "a-col",
            { attrs: { span: 7, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loading.refresh, icon: "reload" },
                  on: { click: _vm.handleRefresh }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_refresh")))]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  "data-source": _vm.dataSource,
                  columns: _vm.columnsTable,
                  "row-key": function(r) {
                    return r.id
                  },
                  scroll: { y: 700 },
                  pagination: {
                    showTotal: function() {
                      return _vm.$t("lbl_total_items", { total: _vm.totalData })
                    },
                    total: _vm.totalData,
                    showSizeChanger: true,
                    current: (_vm.queryParams.page || 0) + 1
                  },
                  loading: _vm.loading.table
                },
                on: { change: _vm.onChangeTable },
                scopedSlots: _vm._u([
                  {
                    key: "amount",
                    fn: function(amount) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm._f("currency")(amount)))
                      ])
                    }
                  },
                  {
                    key: "buttonCustom",
                    fn: function(text, record) {
                      return _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: record.disabled || _vm.allowEdit
                          },
                          on: {
                            click: function($event) {
                              return _vm.addData(record)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_add")))]
                      )
                    }
                  },
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "table",
                          {
                            staticStyle: {
                              width: "400px",
                              "min-width": "unset"
                            },
                            attrs: {
                              "aria-describedby": "tableTotalPrepayment"
                            }
                          },
                          [
                            _c("thead", [
                              _c("th", { attrs: { colspan: "2" } })
                            ]),
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("lbl_total_prepayment")))
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.storeInvoiceAPTotalPrepayments
                                      )
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }