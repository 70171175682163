import { abilitiesPlugin } from "@casl/vue";
import Vue from "vue";
import VueSanitize from "vue-sanitize";
import "../node_modules/nprogress/nprogress.css";
import App from "./App.vue";
import { i18n } from "./locales";
import router from "./router";
import store from "./store";
// Vue Components
import "./components";
// Library
import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Form,
  FormModel,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  message,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Popover,
  Radio,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Statistic,
  Steps,
  Switch,
  Table,
  Tabs,
  Tag,
  TimePicker,
  Tooltip,
  Upload,
} from "ant-design-vue";

import "@/filters";
import { defaultAbility } from "./plugins/Casl";

// Vue Use
Vue.use(Avatar);
Vue.use(Badge);
Vue.use(Statistic);
Vue.use(Dropdown);
Vue.use(Popover);
Vue.use(Result);
Vue.use(Drawer);
Vue.use(Descriptions);
Vue.use(Card);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Row);
Vue.use(DatePicker);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Menu);
Vue.use(Layout);
Vue.use(Pagination);
Vue.use(Popconfirm);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(Modal);
Vue.use(Select);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(TimePicker);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(Space);
Vue.use(Empty);
Vue.use(Spin);
Vue.use(List);
Vue.use(Breadcrumb);
Vue.use(Steps);
Vue.use(Skeleton);
Vue.use(ConfigProvider);
const defaultOptions = {
  allowedTags: ["a", "br", "div", "p", "span"],
  allowedAttributes: {
    a: ["href"],
  },
};

Vue.use(VueSanitize, defaultOptions);

// casl
Vue.use(abilitiesPlugin, defaultAbility, {
  useGlobalProperties: true,
});

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$confirm = Modal.confirm;

// Vue Config
Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.config.devtools = true;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
