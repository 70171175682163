








































































































































import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { InvoiceAPPaymentDetailResponseDTO, InvoiceAPPrepaymentDetail, InvoiceAPResponse } from "@/models/interface/invoiceAp.interface";
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { formatterNumber, reverseFormatNumber, reverseFormatPercent, formatterPercent } from "@/validator/globalvalidator";

type TableType = "prepaymentUsed" | "apPayment" | "return";

@Component({
  computed: {
    ...mapState({
      detailInvoiceAP: (store: any) => store.invoiceApStore.detailInvoiceAP as InvoiceAPResponse,
      storeBaseDecimalPlace: (st: any) => st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  methods: {
    formatterNumber,
    formatterPercent,
    reverseFormatNumber,
    reverseFormatPercent,
  }
})
export default class TabStatusInvoiceAp extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT
  formRules = {
    total: {
      label: "lbl_total",
      name: "total",
      placeholder: "lbl_total_placeholder",
      decorator: ["total"],
    },
    prepaymentUsed: {
      label: "lbl_prepayment_used",
      name: "prepaymentUsed",
      placeholder: "lbl_prepayment_used_placeholder",
      decorator: ["prepaymentUsed"],
    },
    remainingInvoiceAmount: {
      label: "lbl_remaining_invoice_amount",
      name: "remainingInvoiceAmount",
      placeholder: "lbl_remaining_invoice_amount_placeholder",
      decorator: ["remainingInvoiceAmount"],
    },
    apPayment: {
      label: "lbl_ap_payment",
      name: "apPayment",
      placeholder: "lbl_ap_payment_placeholder",
      decorator: ["apPayment"],
    },
    return: {
      label: "lbl_returns",
      name: "return",
      placeholder: "lbl_returns_placeholder",
      decorator: ["return"],
    },
  }
  visibleModal= false
  titleModal: TableType = 'prepaymentUsed'
  dataSourcePrepaymentUsed: InvoiceAPPrepaymentDetail[] = []
  dataSourceApPayment: InvoiceAPPaymentDetailResponseDTO[] = [];
  dataSourceReturn = []

  columnsTablPrepaymentUsed = [
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoicePrepaymentDate",
      key: "invoiceDate",
      width: 170,
      scopedSlots: { customRender: 'isDate' },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoicePrepaymentNo",
      key: "invoiceNumber",
      width: 170,
      scopedSlots: { customRender: 'isNull' },
    },
    {
      title: this.$t("lbl_prepayment_used_amount"),
      dataIndex: "appliedAmount",
      key: "prepaymentUsedAmount",
      width: 170,
      scopedSlots: { customRender: 'isCurrency' },
    },
  ]
  columnsTableApPayment = [
    {
      title: this.$t("lbl_ap_payment_date"),
      dataIndex: "paymentDate",
      key: "paymentDate",
      width: 170,
      scopedSlots: { customRender: 'isDate' },
    },
    {
      title: this.$t("lbl_ap_payment_number"),
      dataIndex: "paymentNumber",
      key: "paymentNumber",
      width: 170,
      scopedSlots: { customRender: 'isNull' },
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      width: 170,
      scopedSlots: { customRender: 'isCurrency' },
    },
  ]
  columnsTableReturn = [
    {
      title: this.$t("lbl_return_date"),
      dataIndex: "returnDate",
      key: "returnDate",
      width: 170,
      scopedSlots: { customRender: 'isDate' },
    },
    {
      title: this.$t("lbl_return_number"),
      dataIndex: "returnNumber",
      key: "returnNumber",
      width: 170,
      scopedSlots: { customRender: 'isNull' },
    },
    {
      title: this.$t("lbl_return_amount"),
      dataIndex: "returnAmount",
      key: "returnAmount",
      width: 170,
      scopedSlots: { customRender: 'isCurrency' },
    },
  ]

  handleCloseModal () {
    this.visibleModal = false
  }

  handleClickMore (form: TableType): void {
    this.visibleModal = true
    switch (form) {
      case 'prepaymentUsed':
        this.setTablePrepayment();
        break;
      case 'apPayment':
        this.setTableApPayment();
        break;
      case 'return':
        this.titleModal = form
        this.dataSourceReturn = []
        break;
      default:
        break;
    }
  }

  setTablePrepayment(): void {
    this.titleModal = "prepaymentUsed";
    const { applyPrepayment } = this.detailInvoiceAP as InvoiceAPResponse;
    this.dataSourcePrepaymentUsed = applyPrepayment.prepaymentLines;
  }

  setTableApPayment(): void {
    this.titleModal = "apPayment";
    const { invoiceAPPaymentDetails } = this.detailInvoiceAP as InvoiceAPResponse;
    this.dataSourceApPayment = invoiceAPPaymentDetails;
  }
}
