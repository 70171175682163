var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "p-3" },
    [
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.total.label) } },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.total.decorator,
                expression: "formRules.total.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              name: _vm.formRules.total.name,
              placeholder: _vm.$t(_vm.formRules.total.placeholder),
              autocomplete: "off",
              disabled: true,
              formatter: _vm.formatterNumber,
              parser: _vm.reverseFormatNumber,
              min: 0,
              precision: _vm.storeBaseDecimalPlace
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.prepaymentUsed.label) } },
        [
          _c(
            "a-space",
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.prepaymentUsed.decorator,
                    expression: "formRules.prepaymentUsed.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.prepaymentUsed.name,
                  placeholder: _vm.$t(_vm.formRules.prepaymentUsed.placeholder),
                  autocomplete: "off",
                  disabled: true,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  min: 0,
                  precision: _vm.storeBaseDecimalPlace
                }
              }),
              _c(
                "a-button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function($event) {
                      return _vm.handleClickMore("prepaymentUsed")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_more")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: { label: _vm.$t(_vm.formRules.remainingInvoiceAmount.label) }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.remainingInvoiceAmount.decorator,
                expression: "formRules.remainingInvoiceAmount.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              name: _vm.formRules.remainingInvoiceAmount.name,
              placeholder: _vm.$t(
                _vm.formRules.remainingInvoiceAmount.placeholder
              ),
              autocomplete: "off",
              disabled: true,
              formatter: _vm.formatterNumber,
              parser: _vm.reverseFormatNumber,
              min: 0,
              precision: _vm.storeBaseDecimalPlace
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.apPayment.label) } },
        [
          _c(
            "a-space",
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.apPayment.decorator,
                    expression: "formRules.apPayment.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.apPayment.name,
                  placeholder: _vm.$t(_vm.formRules.apPayment.placeholder),
                  autocomplete: "off",
                  disabled: true,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  min: 0,
                  precision: _vm.storeBaseDecimalPlace
                }
              }),
              _c(
                "a-button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function($event) {
                      return _vm.handleClickMore("apPayment")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_more")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.return.label) } },
        [
          _c(
            "a-space",
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.return.decorator,
                    expression: "formRules.return.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.return.name,
                  placeholder: _vm.$t(_vm.formRules.return.placeholder),
                  autocomplete: "off",
                  disabled: true,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  min: 0,
                  precision: _vm.storeBaseDecimalPlace
                }
              }),
              _c(
                "a-button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function($event) {
                      return _vm.handleClickMore("return")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_more")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 820,
            title:
              _vm.titleModal === "prepaymentUsed"
                ? "Details Prepayment Used"
                : _vm.titleModal === "apPayment"
                ? "Details AP Payment"
                : "Details Return",
            showmodal: _vm.visibleModal,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c("TableCustom", {
                attrs: {
                  idtable: "table2",
                  dataSource:
                    _vm.titleModal === "prepaymentUsed"
                      ? _vm.dataSourcePrepaymentUsed
                      : _vm.titleModal === "apPayment"
                      ? _vm.dataSourceApPayment
                      : _vm.dataSourceReturn,
                  columns:
                    _vm.titleModal === "prepaymentUsed"
                      ? _vm.columnsTablPrepaymentUsed
                      : _vm.titleModal === "apPayment"
                      ? _vm.columnsTableApPayment
                      : _vm.columnsTableReturn,
                  scroll: { x: "calc(100px + 50%)", y: 400 },
                  paginationcustom: false,
                  defaultPagination: true
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                { key: "back", on: { click: _vm.handleCloseModal } },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }