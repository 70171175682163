import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";
export const SETTINGS_ROUTE: RoutePathModel = {
  path: "settings",
  url: "/settings",
  name: "lbl_settings",
  meta: {
    breadcrumb: "lbl_settings",
    title_head: "lbl_settings",
    need_auth: true,
  },
};

//COA
export const COA_INDEX_ROUTE: RoutePathModel = {
  path: "coa",
  url: "/coa",
  name: "settings.coa",
  meta: {
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_chart_of_account",
        href: "/coa",
      },
    ],
    title_head: "lbl_chart_of_account",
    need_auth: true,
  },
};

//CURRENCY
export const CURRENCY_ROUTE: RoutePathModel = {
  path: "currency",
  url: "/currency",
  name: "lbl_currency",
  meta: {
    title_head: "lbl_currency",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_currency",
        href: "/currency",
      },
    ],
  },
};

//COMPANY
export const COMPANY_ROUTE: RoutePathModel = {
  path: "company",
  url: "/company",
  name: "settings.company",
  meta: {
    title_head: "lbl_company",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_company",
        href: "/company",
      },
    ],
    need_auth: true,
  },
};

//CALENDAR
export const CALENDAR_ROUTE: RoutePathModel = {
  path: "calendar",
  url: "/calendar",
  name: "lbl_calendar",
  meta: {
    title_head: "lbl_calendar",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_calendar",
        href: "/calendar",
      },
    ],
  },
};

export const UOM_ROUTE: RoutePathModel = {
  path: "uom",
  url: "/uom",
  name: "lbl_uom",
  meta: {
    title_head: "lbl_uom",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_uom",
        href: "/uom",
      },
    ],
  },
};

export const BANK_ROUTE: RoutePathModel = {
  path: "bank",
  url: "/bank",
  name: "lbl_bank",
  meta: {
    title_head: "lbl_bank",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_bank",
        href: "/bank",
      },
    ],
  },
};
export const TAX_ROUTE: RoutePathModel = {
  path: "tax",
  url: "/tax",
  name: "lbl_tax",
  meta: {
    title_head: "lbl_tax",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_tax",
        href: "/tax",
      },
    ],
  },
};
export const TAX_INVOICE_SERIAL_NUMBER: RoutePathModel = {
  path: "taxinvoiceserialnumber",
  url: "/taxinvoiceserialnumber",
  name: "lbl_tax_invoice_sn",
  meta: {
    title_head: "lbl_tax_invoice_sn",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_tax_invoice_sn",
        href: "/settings/taxinvoiceserialnumber",
      },
    ],
    mode: Mode.CREATE,
  },
};
export const DETAIL_TAX_INVOICE_SERIAL_NUMBER: RoutePathModel = {
  path: "taxinvoiceserialnumber/detail/:id",
  url: "/taxinvoiceserialnumber/detai/:idl",
  name: "lbl_tax_invoice_sn_detail",
  meta: {
    title_head: "lbl_tax_invoice_sn_detail",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_tax_invoice_sn",
        href: "/settings/taxinvoiceserialnumber",
      },
      {
        name: "lbl_tax_invoice_sn_detail",
        href: "/settings/taxinvoiceserialnumber/detail/:id",
      },
    ],
    mode: Mode.EDIT,
  },
};
export const TAX_INVOICE_CODE: RoutePathModel = {
  path: "taxinvoicecode",
  url: "/taxinvoicecode",
  name: "lbl_tax_invoice_code",
  meta: {
    title_head: "lbl_tax_invoice_code",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_tax_invoice_code",
        href: "/settings/taxinvoicecode",
      },
    ],
  },
};
export const PREFERENCES_ROUTE: RoutePathModel = {
  path: "/settings/preferences",
  url: "/settings/preferences",
  name: "settings.preference.list",
  meta: {
    title_head: "lbl_preferences",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_preferences",
        href: "/preferences",
      },
    ],
  },
};
