import { findPercentage } from "@/helpers/common";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { APP_DECIMAL_PLACES } from "@/models/enums/global.enum";
import { INVOICE_AP_STATUS } from "@/models/enums/invoice-ap.enum";
import { INVOICE_TYPE } from "@/models/enums/invoice.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  InvoiceAPCreateDTO,
  InvoiceAPLineRequestDTO,
  InvoiceAPPrepaymentDetail,
  InvoiceAPPrepaymentLineRequestDTO,
  InvoiceAPPrepaymentResponse,
  InvoiceAPResponse,
  InvoiceAPUpdateDTO,
} from "@/models/interface/invoiceAp.interface";
import localStorageService from "@/services/LocalStorage.service";
import Decimal from "decimal.js-light";
import moment from "moment";
import {
  IForm,
  initFormHeader,
  initInvoiceAPCreateDTO,
  initInvoiceAPPrepayment,
  initInvoiceAPResponseDTO,
  initInvoiceAPRow,
  InvoiceAPLine,
} from "./resources/invoice-ap.resource";

type State = {
  form: IForm;
  invoiceAPLines: InvoiceAPLine[];
  invoiceAPPrepayments: InvoiceAPPrepaymentResponse;
  deletedPrepaymentLineIds: string[];
  deletedInvoiceLineIds: string[];
  totalPrepayment: number;
  invoiceSubtotal: number;
  totalGross: number;
  additionalDiscountPercent: number;
  additionalDiscountAmount: number;
  totalTax: number;
  grandTotal: number;
  detailInvoiceAP: InvoiceAPResponse;
};

const ALLOW_EDIT_DOCUMENT: INVOICE_AP_STATUS[] = [
  INVOICE_AP_STATUS.DRAFT,
  INVOICE_AP_STATUS.NEED_APPROVAL,
];

const state: State = {
  form: initFormHeader(),
  invoiceAPLines: [],
  invoiceAPPrepayments: initInvoiceAPPrepayment(),
  deletedPrepaymentLineIds: [],
  deletedInvoiceLineIds: [],
  totalPrepayment: 0,
  totalGross: 0,
  invoiceSubtotal: 0,
  additionalDiscountPercent: 0,
  additionalDiscountAmount: 0,
  totalTax: 0,
  grandTotal: 0,
  detailInvoiceAP: initInvoiceAPResponseDTO(),
};

const mutations = {
  ["SET_DETAIL_INVOICE_AP"]: (st: State, payload: InvoiceAPResponse): void => {
    st.detailInvoiceAP = payload;
  },
  ["SET_FORM_HEADER"]: (st: State, payload: IForm): void => {
    st.form = payload;
  },
  ["SET_INVOICE_AP_LINES"]: (st: State, payload: InvoiceAPLine[]): void => {
    st.invoiceAPLines = payload;
  },
  ["SET_INVOICE_AP_PREPAYMENTS"]: (
    st: State,
    payload: InvoiceAPPrepaymentResponse
  ): void => {
    st.invoiceAPPrepayments = payload;
  },
  ["SET_INVOICE_AP_DELETED_LINE"]: (st: State, payload: string[]): void => {
    st.deletedInvoiceLineIds = payload;
  },
  ["SET_INVOICE_AP_DELETED_PREPAYMENT_LINE"]: (
    st: State,
    payload: string[]
  ): void => {
    st.deletedPrepaymentLineIds = payload;
  },
  ["SET_TOTAL_PREPAYMENT"]: (st: State, payload: number): void => {
    st.totalPrepayment = payload;
  },
  ["SET_INVOICE_SUB_TOTAL"]: (st: State, payload: number): void => {
    st.invoiceSubtotal = payload;
  },
  ["SET_TOTAL_GROSS"]: (st: State, payload: number): void => {
    st.totalGross = payload;
  },
  ["SET_ADDITIONAL_DISCOUNT_PERCENT"]: (st: State, payload: number): void => {
    st.additionalDiscountPercent = payload;
  },
  ["SET_ADDITIONAL_DISCOUNT_AMOUNT"]: (st: State, payload: number): void => {
    st.additionalDiscountAmount = payload;
  },
  ["SET_TOTAL_TAX"]: (st: State, payload: number): void => {
    st.totalTax = payload;
  },
  ["SET_GRAND_TOTAL"]: (st: State, payload: number): void => {
    st.grandTotal = payload;
  },
};

const getters = {
  ["GET_INVOICE_AP_LINES"]: (st: State): InvoiceAPLine[] => {
    return st.invoiceAPLines;
  },
  ["TAX_CALCULATION_EXCLUSIVE"]: (st: State): boolean => {
    return st.form.taxType === TAX_CALCULATION.EXCLUSIVE;
  },
  ["TAX_CALCULATION_INCLUSIVE"]: (st: State): boolean => {
    return st.form.taxType === TAX_CALCULATION.INCLUSIVE;
  },
  ["GET_ALLOW_TO_EDIT"]: (st: State): boolean => {
    return (
      !!st.form.statusInvoice &&
      !ALLOW_EDIT_DOCUMENT.includes(st.form.statusInvoice as INVOICE_AP_STATUS)
    );
  },
};

const actions = {
  ["RESET_STATE"]: ({ commit }): void => {
    commit("SET_FORM_HEADER", initFormHeader());
    commit("SET_INVOICE_AP_LINES", []);
    commit("SET_INVOICE_AP_PREPAYMENTS", initInvoiceAPPrepayment());
    commit("SET_INVOICE_AP_DELETED_LINE", []);
    commit("SET_INVOICE_AP_DELETED_PREPAYMENT_LINE", []);
    commit("SET_TOTAL_PREPAYMENT", 0);
    commit("SET_TOTAL_GROSS", 0);
    commit("SET_INVOICE_SUB_TOTAL", 0);
    commit("SET_ADDITIONAL_DISCOUNT_PERCENT", 0);
    commit("SET_ADDITIONAL_DISCOUNT_AMOUNT", 0);
    commit("SET_TOTAL_TAX", 0);
    commit("SET_GRAND_TOTAL", 0);
    commit("SET_DETAIL_INVOICE_AP", initInvoiceAPResponseDTO());
  },
  ["ADD_ROW"]: (context): void => {
    const row = initInvoiceAPRow();
    row.no = context.state.invoiceAPLines.length + 1;
    context.state.invoiceAPLines.push(row);
  },
  ["SUM_PREPAYMENT"]: (context): void => {
    const prepayments = context.state.invoiceAPPrepayments
      .prepaymentLines as InvoiceAPPrepaymentDetail[];
    const total = prepayments.reduce<number>((a, b) => {
      return new Decimal(b.appliedAmount || 0).plus(a).toNumber();
    }, 0);
    context.commit("SET_TOTAL_PREPAYMENT", total);
  },
  ["SUM_GROSS"]: (context): void => {
    const row = context.state.invoiceAPLines as InvoiceAPLine[];
    const total = row.reduce<number>((a, b) => {
      return new Decimal(b.gross || 0).plus(a).toNumber();
    }, 0);
    context.commit("SET_TOTAL_GROSS", total);
  },
  ["SUM_INVOICE_SUB_TOTAL"]: (context): void => {
    const row = context.state.invoiceAPLines as InvoiceAPLine[];
    const total = row.reduce<number>((a, b) => {
      return new Decimal(b.subTotal || 0).plus(a).toNumber();
    }, 0);
    context.commit("SET_INVOICE_SUB_TOTAL", total);
  },
  ["SUM_TAX"]: (context): void => {
    const row = context.state.invoiceAPLines as InvoiceAPLine[];
    const total = row.reduce<number>((a, b) => {
      return new Decimal(b.taxValue || 0).plus(a).toNumber();
    }, 0);
    context.commit("SET_TOTAL_TAX", total);
  },
  ["SET_INVOICE_AP_LINE_EXPENSE_ACCOUNT"]: async (
    context,
    payload: { accountId: string; accountDescription: string }
  ): Promise<void> => {
    const { accountId, accountDescription } = payload;
    context.state.invoiceAPLines.forEach((item: InvoiceAPLine) => {
      item.expenseAccountId = accountId;
      item.expenseAccount = accountDescription;
    });
  },
  ["CALC_GRAND_TOTAL"]: (context): void => {
    const invoiceSubtotal: number = context.state.invoiceSubtotal || 0;
    const totalPrepayment: number = context.state.totalPrepayment || 0;
    const additionalDiscount: number =
      context.state.additionalDiscountAmount || 0;
    const grandTotal: number = new Decimal(invoiceSubtotal)
      .minus(totalPrepayment)
      .minus(additionalDiscount)
      .toNumber();
    context.commit("SET_GRAND_TOTAL", grandTotal);
  },
  ["CALC_ADDITIONAL_DISCOUNT"]: (
    context,
    payload: { value: number; field: "amount" | "percent" }
  ): void => {
    if (payload.field === "percent") {
      const percentage = new Decimal(payload.value || 0).dividedBy(100);
      const final: number = percentage
        .times(context.state.totalGross)
        .toNumber();
      context.commit("SET_ADDITIONAL_DISCOUNT_PERCENT", payload.value);
      context.commit("SET_ADDITIONAL_DISCOUNT_AMOUNT", final);
    } else {
      const amount = new Decimal(payload.value || 0);
      const final: number = amount
        .dividedBy(context.state.totalGross || 1)
        .times(100)
        .toNumber();
      context.commit("SET_ADDITIONAL_DISCOUNT_AMOUNT", payload.value);
      context.commit("SET_ADDITIONAL_DISCOUNT_PERCENT", final);
    }
  },
  ["PUSH_DELETED_INVOICE_AP_PREPAYMENTS"]: (context, payload: string): void => {
    context.state.deletedPrepaymentLineIds.push(payload);
  },
  ["PUSH_DELETED_INVOICE_AP_LINE"]: (context, payload: string): void => {
    context.state.deletedInvoiceLineIds.push(payload);
  },
  ["CALC_PRICING_INVOICE_AP"]: (context): void => {
    const dp =
      localStorageService.load(APP_DECIMAL_PLACES.DP) ||
      DECIMAL_PLACES_CURRENCY;
    context.state.invoiceAPLines.forEach((x: InvoiceAPLine) => {
      let gross = new Decimal(x.price || 0).times(x.qty || 0);

      x.gross = gross.toNumber();

      // exclusive / none
      const taxRate = new Decimal(x.taxRate || 0).dividedBy(100);

      // find inclusive gross amount
      if (context.getters.TAX_CALCULATION_INCLUSIVE) {
        gross = gross
          .dividedBy(taxRate.plus(1))
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
      }

      // calculate discount percent
      x.percentDiscount = findPercentage(x.discountValue, gross.toNumber());

      // gross after discount line
      // E = C - D
      const grossAfterDiscount = gross.minus(x.discountValue || 0);
      // later define on model
      x.grossAfterDiscount = grossAfterDiscount.toNumber();

      // calc income tax
      const incomeTaxRate = new Decimal(x.incomeTaxRate || 0).dividedBy(100);
      x.incomeTaxValue = new Decimal(x.grossAfterDiscount || 0)
        .times(incomeTaxRate)
        .toNumber();

      let baseAmount = new Decimal(0);
      let taxAmount = new Decimal(0);
      let subTotal = new Decimal(0);

      // tax calculation
      if (context.getters.TAX_CALCULATION_EXCLUSIVE) {
        baseAmount = new Decimal(x.grossAfterDiscount || 0);
        taxAmount = baseAmount
          .times(taxRate)
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
        subTotal = baseAmount.plus(taxAmount).minus(x.incomeTaxValue || 0);
        x.baseAmount = baseAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.taxValue = taxAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.subTotal = subTotal
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
      } else if (context.getters.TAX_CALCULATION_INCLUSIVE) {
        const grossValue = new Decimal(x.qty || 0).times(x.price || 0);
        const baseAmountInclusive = grossValue.dividedBy(taxRate.plus(1));

        const baseAmountAfterDiscountLine = baseAmountInclusive.minus(
          x.discountValue
        );

        taxAmount = baseAmountAfterDiscountLine
          .times(taxRate)
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
        subTotal = baseAmountAfterDiscountLine
          .plus(taxAmount)
          .minus(x.incomeTaxValue || 0);
        x.baseAmount = baseAmountAfterDiscountLine
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.taxValue = taxAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.subTotal = subTotal
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
      } else {
        baseAmount = new Decimal(x.grossAfterDiscount || 0);
        subTotal = baseAmount.minus(x.incomeTaxValue || 0);
        x.baseAmount = baseAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.taxValue = 0;
        x.subTotal = subTotal
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
      }
    });
  },
  ["RECALCULATE_PRICING"]: async ({ dispatch }): Promise<void> => {
    await dispatch("CALC_PRICING_INVOICE_AP");
    await dispatch("SUM_GROSS");
    await dispatch("SUM_INVOICE_SUB_TOTAL");
    await dispatch("SUM_PREPAYMENT");
    await dispatch("SUM_TAX");
    await dispatch("CALC_GRAND_TOTAL");
  },
  ["CONSTRUCT_PAYLOAD_CREATE"]: (
    context,
    param: { status: INVOICE_AP_STATUS }
  ): Promise<InvoiceAPCreateDTO> => {
    return new Promise<InvoiceAPCreateDTO>((resolve, reject) => {
      try {
        const payload: InvoiceAPCreateDTO = initInvoiceAPCreateDTO();
        const {
          source,
          branch,
          currency,
          supplierName,
          supplierShipAddress,
          supplierBillAddress,
          termOfPayment,
          taxType,
          invoiceSupplierNo,
          invoiceDate,
          accountingDate,
          invoiceReceivedDate,
          rate,
          payablesAccount,
          invoiceDescription,
          taxRegistrationNumber,
          taxRegistrationName,
          taxInvoiceDate,
          taxInvoiceNumber,
        } = context.state.form as IForm;
        const { prepaymentLines } = context.state
          .invoiceAPPrepayments as InvoiceAPPrepaymentResponse;
        const invoiceLines = context.state.invoiceAPLines as InvoiceAPLine[];
        payload.accountingDate = moment(accountingDate).format();
        payload.applyPrepayment = {
          deletedPrepaymentLineIds: [],
          prepaymentLines:
            prepaymentLines.map<InvoiceAPPrepaymentLineRequestDTO>(item => ({
              appliedAmount: item.appliedAmount,
              description: item.description,
              invoicePrepaymentId: item.invoicePrepaymentId,
              secureId: item.id,
            })),
        };
        payload.branchWarehouseId = branch?.key ?? "";
        payload.currency = currency;
        payload.currencyRate = rate || 1;
        payload.description = invoiceDescription;
        payload.discountValue = context.state.additionalDiscountAmount;
        payload.invoiceAPLines = invoiceLines.map<InvoiceAPLineRequestDTO>(
          item => ({
            baseAmount: item.baseAmount,
            description: item.description,
            discountValue: item.discountValue,
            expenseAccountId: item.expenseAccountId,
            goodReceiptLineId: item.goodReceiptLineId,
            incomeTaxId: item.incomeTaxId,
            merk: item.merk,
            percentDiscount: item.percentDiscount,
            price: item.price,
            productId: item.productId,
            purchaseOrderLineId: item.purchaseOrderLineId,
            qty: item.qty,
            secureId: item.id,
            subTotal: item.subTotal,
            taxCode: item.taxCode,
            taxValue: item.taxValue,
            uomId: item.uomId,
          })
        );
        payload.invoiceDate = moment(invoiceDate).format();
        payload.invoiceReceivedDate = moment(invoiceReceivedDate).format();
        payload.invoiceSource = source;
        payload.invoiceSupplierNo = invoiceSupplierNo;
        payload.invoiceType = INVOICE_TYPE.INVOICE_AP;
        payload.payablesAccountId = payablesAccount;
        payload.percentDiscount = context.state.additionalDiscountPercent;
        payload.status = param.status;
        payload.supplierBillToAddress = supplierBillAddress;
        payload.supplierId = supplierName;
        payload.supplierShipToAddress = supplierShipAddress;
        payload.taxInvoiceDate = moment(taxInvoiceDate).format();
        payload.taxInvoiceNumber = taxInvoiceNumber;
        payload.taxRegistrationName = taxRegistrationName;
        payload.taxRegistrationNumber = taxRegistrationNumber;
        payload.taxType = taxType;
        payload.termOfPayment = +termOfPayment;
        resolve(payload);
      } catch (error) {
        reject(error);
      }
    });
  },
  ["CONSTRUCT_PAYLOAD_UPDATE"]: async (
    context,
    param: { status: INVOICE_AP_STATUS }
  ): Promise<InvoiceAPCreateDTO> => {
    const payloadCreate: InvoiceAPCreateDTO = (await context.dispatch(
      "CONSTRUCT_PAYLOAD_CREATE",
      { status: param.status }
    )) as InvoiceAPCreateDTO;
    const payload: InvoiceAPUpdateDTO = {
      ...payloadCreate,
      deletedInvoiceAPLineIds: [],
    };
    payload.applyPrepayment.deletedPrepaymentLineIds = context.state
      .deletedPrepaymentLineIds as string[];
    payload.deletedInvoiceAPLineIds = context.state
      .deletedInvoiceLineIds as string[];

    return payload;
  },
  ["MAP_DETAIL_TO_FORM"]: (
    context,
    payload: { detail: InvoiceAPResponse }
  ): void => {
    const detail: InvoiceAPResponse = payload.detail;
    const $form = { ...context.state.form } as IForm;
    $form.source = detail.invoiceSource;
    $form.branch = {
      key: detail.branchWarehouseId,
      label: detail.branchWarehouseName,
    };
    $form.currency = detail.currency;
    $form.supplierName = detail.supplierId;
    $form.supplierShipAddress = detail.supplierShipToAddress;
    $form.supplierBillAddress = detail.supplierBillToAddress;
    $form.termOfPayment = detail.termOfPayment.toString();

    $form.findPurchaseOrder = detail.purchaseOrders.map<string>(
      item => item.id
    );
    $form.findGoodReceipt = detail.goodReceipts.map<string>(item => item.id);

    $form.taxType = detail.taxType;
    $form.invoiceNumber = detail.documentNumber;
    $form.invoiceSupplierNo = detail.invoiceSupplierNo;
    $form.invoiceDate = detail.invoiceDate;
    $form.accountingDate = detail.accountingDate;
    $form.invoiceReceivedDate = detail.invoiceReceivedDate;
    $form.rate = detail.currencyRate;
    $form.journalNumber = detail.journalNo;
    $form.payablesAccount = detail.payablesAccountId;
    $form.invoiceDescription = detail.description;
    $form.statusInvoice = detail.status;
    $form.taxRegistrationNumber = detail.taxRegistrationNumber;
    $form.taxRegistrationName = detail.taxRegistrationName;
    $form.taxInvoiceDate = detail.taxInvoiceDate;
    $form.taxInvoiceNumber = detail.taxInvoiceNumber;

    context.commit("SET_FORM_HEADER", $form);
  },
  ["MAP_DETAIL_TO_TABLE"]: (
    context,
    payload: { detail: InvoiceAPResponse }
  ): void => {
    const detail: InvoiceAPResponse = payload.detail;
    const dataSource: InvoiceAPLine[] =
      detail.invoiceAPLines.map<InvoiceAPLine>((item, i) => ({
        ...item,
        uniqueId: item.id as string,
        no: i + 1 + "",
        taxRate: item.taxRate,
        generated: true,
        gross: new Decimal(item.qty || 0).times(item.price || 0).toNumber(),
        grossAfterDiscount: new Decimal(item.qty || 0)
          .times(item.price || 0)
          .minus(item.discountValue || 0)
          .toNumber(),
      }));

    context.commit("SET_INVOICE_AP_LINES", dataSource);
  },
  ["MAP_SUMMARY"]: (context, payload: { detail: InvoiceAPResponse }): void => {
    context.commit("SET_TOTAL_PREPAYMENT", payload.detail.prepaymentUsed);
    context.commit("SET_TOTAL_GROSS", payload.detail.total);
    context.commit("SET_INVOICE_SUB_TOTAL", payload.detail.invoiceSubtotal);
    context.commit(
      "SET_ADDITIONAL_DISCOUNT_PERCENT",
      payload.detail.percentDiscount
    );
    context.commit(
      "SET_ADDITIONAL_DISCOUNT_AMOUNT",
      payload.detail.discountValue
    );
    context.commit("SET_TOTAL_TAX", payload.detail.totalTax);
    context.commit("SET_GRAND_TOTAL", payload.detail.grandTotal);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
