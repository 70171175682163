import { generateUUID } from "@/helpers/uuid";
import { ONE } from "@/models/constant/global.constant";
import {
  FormValue,
  WoDetailDraft,
  WoSparepartRow,
  WoUnitRow,
} from "@/store/workOrder.store";

export const initForm = (): FormValue => ({
  branchWarehouseId: "",
  customerAddress: "",
  customerId: "",
  customerName: "",
  description: "",
  mechanicId: "",
  picName: "",
  scheduleOrder: null,
  spareParts: [],
  unitCode: "",
  units: [],
  workOrderDate: null,
});

export const initWoSparepartRow = (): WoSparepartRow => ({
  key: generateUUID(),
  no: ONE,
  notes: "",
  partNumber: "",
  productCode: "",
  productId: "",
  productLocation: "",
  productLocationId: "",
  productName: "",
  productUom: "",
  productUomId: "",
  qtyAvailable: 0,
  qtyWorkOrder: 0,
  unitCode: "",
});

export const initWoUnitRow = (): WoUnitRow => ({
  assetId: "",
  capacity: "",
  assetCategory: "",
  key: generateUUID(),
  location: "",
  model: "",
  no: ONE,
  serialNumber: "",
  type: "",
  unitCode: "",
  unitSpecification: "",
});

export const initWoDetailDraft = (): WoDetailDraft => ({
  branchWarehouse: "",
  branchWarehouseId: "",
  customerAddress: "",
  customerId: "",
  customerName: "",
  description: "",
  documentNumber: "",
  mechanicId: "",
  mechanicName: "",
  picName: "",
  scheduleOrder: null,
  spareParts: [],
  units: [],
  workOrderDate: null,
  workOrderId: "",
});
