import { Row, useCalculator, useCurrency, useLeasing } from "@/hooks";
import { ONE } from "@/models/constant/global.constant";
import {
  LeasingPaymentTypeEnum,
  LeasingStateEnum,
} from "@/models/enums/Leasing.enum";
import {
  initForm,
  initRowAsset,
  initRowPeriod,
} from "@/store/resources/Leasing.resource";
import {
  LeasingAssetCreateRequestDto,
  LeasingAssetResponseDto,
  LeasingCreateRequestDto,
  LeasingDetailResponseDto,
  LeasingPeriodCreateRequestDto,
  LeasingPeriodResponseDto,
} from "@interface/leasing";
import { ResponsePreference } from "@interface/settings.interface";
import moment, { Moment } from "moment";

type AssetItem = Omit<LeasingAssetCreateRequestDto, "paymentTaxInvoiceDate"> &
  Pick<
    LeasingAssetResponseDto,
    | "assetUnitCode"
    | "brand"
    | "type"
    | "provision"
    | "residue"
    | "serialNumber"
    | "nettFinance"
    | "interest"
    | "insurancePremium"
  > & {
    paymentTaxInvoiceDate: Moment | null;
  };
type PeriodActionLoadingState = {
  settle: boolean;
  cancel: boolean;
};
type PeriodItem = Omit<
  LeasingPeriodCreateRequestDto,
  "paymentDate" | "paymentRealizationDate"
> &
  Pick<
    LeasingPeriodResponseDto,
    | "amortize"
    | "balance"
    | "cashInOutDocumentNumber"
    | "cashInOutId"
    | "installment"
    | "paymentCost"
  > & {
    paymentDate: Moment | null;
    paymentRealizationDate: Moment | null;
    loading: PeriodActionLoadingState;
  };
export type RowAsset = Row<AssetItem, string>;
export type RowPeriod = Row<PeriodItem, string>;
type FormDetail = Pick<
  LeasingDetailResponseDto,
  | "bankName"
  | "branchName"
  | "cancellable"
  | "currencyName"
  | "dealerName"
  | "insuranceName"
  | "interestCostAccountCode"
  | "interestCostAccountName"
  | "journalId"
  | "journalNo"
  | "leasingNumber"
  | "lessorName"
  | "paidOff"
  | "policyNumber"
  | "principalCostAccountCode"
  | "principalCostAccountName"
  | "residue"
  | "status"
  | "total"
> & { paidOffDate: Moment | null };
type CreateDto = Omit<
  LeasingCreateRequestDto,
  "assetList" | "periodList" | "leasingContractDate"
> & {
  assetList: Array<RowAsset>;
  periodList: Array<RowPeriod>;
  leasingContractDate: Moment | null;
};
type AdditionalField = {
  useCheque: boolean;
  chequeNumber: string;
  startPaymentDate: Moment | null;
};
export type FormValue = CreateDto & FormDetail & AdditionalField;
export type State = {
  form: FormValue;
  detail: LeasingDetailResponseDto;
};

const state: State = {
  form: initForm(),
  detail: useLeasing().initDetailDto(),
};

const getters = {
  isIdr: (st: State): boolean => {
    const IDR = "IDR";
    return st.form.currencyName.toUpperCase() === IDR;
  },
  isDocExist: (st: State): boolean => {
    return !!st.form.id;
  },
  sumInsurancePeriod: (st: State): number => {
    const { sum } = useCalculator();
    const amortize: Array<number> = st.form.periodList.map<number>(row => {
      return row.amortize || 0;
    });
    return sum(amortize);
  },
  sumPrincipalCost: (st: State): number => {
    const { sum } = useCalculator();
    const principalCost: Array<number> = st.form.periodList.map<number>(row => {
      return row.principalCost || 0;
    });
    return sum(principalCost);
  },
  sumInterestCost: (st: State): number => {
    const { sum } = useCalculator();
    const interestCost: Array<number> = st.form.periodList.map<number>(row => {
      return row.interestCost || 0;
    });
    return sum(interestCost);
  },
  sumPaymentCost: (st: State): number => {
    const { sum } = useCalculator();
    const paymentCost: Array<number> = st.form.periodList.map<number>(row => {
      return row.paymentCost || 0;
    });
    return sum(paymentCost);
  },
  disabledForm: ({ form: { status } }: State): boolean => {
    return status.toUpperCase() === LeasingStateEnum.SUBMITTED.toUpperCase();
  },
  isDraft: ({ form: { status } }: State): boolean => {
    return status.toUpperCase() === LeasingStateEnum.DRAFT.toUpperCase();
  },
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  /**
   * @deprecated
   */
  setBankId(st: State, value: string): void {
    st.form.companyBankId = value;
  },
  setDetail: (st: State, payload: LeasingDetailResponseDto): void => {
    st.detail = payload;
  },
};

const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    const form: FormValue = initForm();
    commit("setForm", form);
  },
  getDefaultCurrency: (context): void => {
    const { commit, rootGetters } = context;
    const { findById } = useCurrency();
    const currency: ResponsePreference | undefined = rootGetters[
      "preferenceStore/GET_PREFERENCE_BY_KEY"
    ]("feature_base_currency");
    if (!currency || !currency.value) return;
    findById(currency.value).then(({ id, currencyCode }) => {
      const form: Partial<FormValue> = {
        currencyId: id,
        currencyName: currencyCode,
      };
      commit("setForm", form);
    });
  },
  addUnit: (context): void => {
    const { state } = context;
    const local: State = state;
    const unit = initRowAsset();
    local.form.assetList.push(unit);
  },
  removeUnit: (context, keys: Array<string>): void => {
    const { state, commit } = context;
    const {
      form: { assetList },
    }: State = state;
    let deletedLines: Array<string> = [];
    const source: Array<RowAsset> = assetList.filter(el => {
      if (keys.includes(el.key) && !!el.id) {
        deletedLines = [...deletedLines, el.id];
      }
      return !keys.includes(el.key);
    });
    const form: Partial<FormValue> = {
      assetList: source,
      deletedAssetLineIds: deletedLines,
    };
    commit("setForm", form);
  },
  calcUnitPricing: (context): void => {
    const { state } = context;
    const {
      calcUnitNettFinance,
      calcUnitInterest,
      calcUnitProvision,
      calcUnitInsurance,
      calcUnitResidue,
    } = useLeasing();
    const local: State = state;
    local.form.assetList.forEach(unit => {
      unit.nettFinance = calcUnitNettFinance({
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        nettFinanceLeasing: local.form.nettFinance,
        taxTotalAmount: local.form.taxTotalAmount,
      });
      unit.interest = calcUnitInterest({
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        interestLeasing: local.form.interest,
        taxTotalAmount: local.form.taxTotalAmount,
      });
      unit.provision = calcUnitProvision({
        taxTotalAmount: local.form.taxTotalAmount,
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        provisionLeasing: local.form.provision,
      });
      unit.insurancePremium = calcUnitInsurance({
        taxTotalAmount: local.form.taxTotalAmount,
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        premiumLeasing: local.form.insurancePremium,
      });
      unit.residue = calcUnitResidue({
        taxTotalAmount: local.form.taxTotalAmount,
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        residueLeasing: local.form.residue,
      });
    });
  },
  calcLeasingResidue: (context): void => {
    const { state, commit } = context;
    const { calcLeasingResidue } = useLeasing();
    const local: State = state;
    const residue: number = calcLeasingResidue({
      costPrice: local.form.costPrice,
      nettFinance: local.form.nettFinance,
      taxTotalAmount: local.form.taxTotalAmount,
    });
    const form: Partial<FormValue> = {
      residue,
    };

    commit("setForm", form);
  },
  calcTotalInstallment: (context): void => {
    const { state, commit } = context;
    const local: State = state;
    const { calcTotalInstallment } = useLeasing();
    const form: Partial<FormValue> = {
      total: calcTotalInstallment({
        nettFinance: local.form.nettFinance,
        interest: local.form.interest,
      }),
    };
    commit("setForm", form);
  },
  generatePeriod: (context): void => {
    const { state } = context;
    const local: State = state;

    const {
      startPaymentDate,
      periodList,
      leasingPeriod,
      insurancePremium,
      useCheque,
      chequeNumber,
    } = local.form;
    const FIRST_INDEX = 0;
    const { calcPaymentAmortize } = useLeasing();

    for (let index = 0; index < leasingPeriod; index++) {
      const row: RowPeriod = initRowPeriod();

      row.amortize = calcPaymentAmortize({
        insurancePremium,
        leasingPeriod,
      });

      if (useCheque && chequeNumber) {
        const increment = parseInt(chequeNumber) + index + 1;
        row.chequeNumber = increment.toString();
      }

      row.installment = index + 1;

      row.paymentType = LeasingPaymentTypeEnum.BANK_TRANSFER;
      if (useCheque) {
        row.paymentType = LeasingPaymentTypeEnum.CHEQUE;
      }

      periodList.push(row);
    }

    //#region fixing gap date
    periodList[FIRST_INDEX].paymentDate = moment(startPaymentDate);

    for (let index = 1; index < periodList.length; index++) {
      const row = periodList[index];
      const prevMonth: Moment | null = moment(
        periodList[index - ONE].paymentDate
      );
      if (prevMonth === null) continue;
      const oneMonth = moment.duration({ months: 1 });
      const nextMonth = moment(prevMonth.add(oneMonth));
      row.paymentDate = moment(nextMonth);

      // #1 the date from the first payment
      const dateFirstPayment = moment(startPaymentDate).date();

      // #2 the date after we added one month
      const dateAfterAdd = moment(row.paymentDate).date();

      // #3 the maximum date from #2
      const lastDateAfterAdd = moment(row.paymentDate).endOf("month").date();

      /**
       * if the date after we added one month is less than
       * the date of first payment and the last date after we
       * added one month is higher equal than the date of
       * first payment
       *
       * @example
       * date of first payment = 30 / 31
       * date after added = 28 (the maximum date is 28 / 29)
       * last date after added = 30 / 31
       *
       * we sould use the date of first payment
       *
       * @see https://momentjs.com/docs/#/manipulating/add/
       */
      if (
        dateAfterAdd < dateFirstPayment &&
        lastDateAfterAdd >= dateFirstPayment
      ) {
        row.paymentDate = moment().set({
          year: row.paymentDate.year(),
          month: row.paymentDate.month(),
          date: dateFirstPayment,
        });
      }
      //#endregion
    }
  },
  fetchDetail: async (context, leasingId: string): Promise<void> => {
    const { commit } = context;
    const { findById, mapDetailToForm } = useLeasing();
    const res = await findById(leasingId);
    const form: FormValue = mapDetailToForm(res);
    commit("setForm", form);
    commit("setDetail", res);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
