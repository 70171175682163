import { IAdditionalPrivilege } from "@/models/interface/privilege.interface"
import { IUserRole, ResponseDetailUserAuth } from "@/models/interface/user.interface"

export const initAuthData = (): ResponseDetailUserAuth => {
  return {
    active: false,
    additionalPrivileges: [] as IAdditionalPrivilege[],
    companyId: "",
    companyName: "",
    companyTaxRegisNo: "",
    email: "",
    employeeId: "",
    employeeName: "",
    id: "",
    roles: [] as IUserRole[],
    username: "",
  }
}