import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  InternalContractCloseRequestDTO,
  InternalContractGetDTO,
  InternalContractListDto,
  InternalContractRequestDTO,
} from "@/models/interface/internal-contract";
import { DataListInternalContract } from "@/models/interface/salesOrder.interface";
import { internalContractService } from "@/services/InternalContract.service";
import { salesOrderServices } from "@/services/salesorder.service";

const useInternalContract = () => {
  const service = internalContractService;

  const createDoc = (payload: InternalContractRequestDTO) => {
    return service.create(payload);
  };

  const closeBulk = (body: InternalContractCloseRequestDTO) => {
    return service.closeBulk(body);
  };

  const findAllAvailableIc = (params: RequestQueryParams) => {
    return salesOrderServices.getListAvailableInternalContract(params);
  };

  const findById = (id: string): Promise<InternalContractGetDTO> => {
    return service.detail(id);
  };

  const findAll = (
    params?: RequestQueryParams
  ): Promise<Pagination<InternalContractListDto>> => {
    return internalContractService.list(params);
  };

  const toOptions = (
    data: Array<DataListInternalContract>
  ): Array<Option<DataListInternalContract>> => {
    return data.map(item => ({
      label: item.documentNo,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  return {
    createDoc,
    closeBulk,
    findAllAvailableIc,
    findById,
    toOptions,
    findAll,
  };
};

export default useInternalContract;
