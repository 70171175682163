import { generateUUID } from "@/helpers/uuid";
import { ONE } from "@/models/constant/global.constant";
import {
  LeasingPaymentTypeEnum,
  LeasingStateEnum,
} from "@/models/enums/Leasing.enum";
import { FormValue, RowAsset, RowPeriod } from "@/store/leasing.store";

export const initForm = (): FormValue => ({
  assetList: [],
  bankName: "",
  billingAddressLessor: "",
  branchId: "",
  branchName: "",
  chequeNumber: "",
  cancellable: false,
  companyBankId: "",
  costPrice: 0,
  currencyId: "",
  currencyName: "",
  dealerId: "",
  dealerName: "",
  deletedAssetLineIds: [],
  deletedPeriodLineIds: [],
  id: "",
  insuranceId: "",
  insuranceName: "",
  insurancePeriod: 0,
  insurancePremium: 0,
  interest: 0,
  interestCostAccountCode: "",
  interestCostAccountId: "",
  interestCostAccountName: "",
  journalId: "",
  journalNo: "",
  leasingContractDate: null,
  leasingContractNumber: "",
  leasingNumber: "",
  leasingPeriod: 0,
  lessorId: "",
  lessorName: "",
  nettFinance: 0,
  notes: "",
  paidOff: false,
  paidOffDate: null,
  periodList: [],
  policyNumber: "",
  principalCostAccountCode: "",
  principalCostAccountId: "",
  principalCostAccountName: "",
  provision: 0,
  rates: ONE,
  residue: 0,
  startPaymentDate: null,
  status: "" as LeasingStateEnum,
  taxTotalAmount: 0,
  total: 0,
  useCheque: false,
});

export const initRowAsset = (): RowAsset => ({
  apPrepaymentInvoiceId: "",
  assetId: "",
  costPricePerUnit: 0,
  id: "",
  paymentTaxInvoiceDate: null,
  paymentTaxInvoiceNumber: "",
  portion: 0,
  purchaseDate: "",
  taxInvoiceNumber: "",
  assetUnitCode: "",
  brand: "",
  type: "",
  provision: 0,
  residue: 0,
  serialNumber: "",
  key: generateUUID(),
  nettFinance: 0,
  insurancePremium: 0,
  interest: 0,
});

export const initRowPeriod = (): RowPeriod => ({
  amortize: 0,
  balance: 0,
  cashInOutDocumentNumber: "",
  cashInOutId: "",
  chequeNumber: "",
  id: "",
  installment: 0,
  interestCost: 0,
  key: generateUUID(),
  loading: { cancel: false, settle: false },
  paymentCost: 0,
  paymentDate: null,
  paymentRealizationDate: null,
  paymentType: "" as LeasingPaymentTypeEnum,
  principalCost: 0,
});
