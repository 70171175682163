











































import {
  DATE_TIME_HOURS_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters({
      allowEdit: "invoiceApStore/GET_ALLOW_TO_EDIT",
    }),
  },
})
export default class TabTaxDetailsInvoiceAp extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;
  formRules = {
    taxRegistrationNumber: {
      label: "lbl_tax_registration_number",
      name: "taxRegistrationNumber",
      placeholder: "lbl_tax_registration_number_placeholder",
      decorator: [
        "taxRegistrationNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxRegistrationName: {
      label: "lbl_tax_registration_name",
      name: "taxRegistrationName",
      placeholder: "lbl_tax_registration_name_placeholder",
      decorator: [
        "taxRegistrationName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxInvoiceDate: {
      label: "lbl_tax_invoice_date",
      name: "taxInvoiceDate",
      placeholder: "lbl_tax_invoice_date_placeholder",
      decorator: [
        "taxInvoiceDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxInvoiceNumber: {
      label: "lbl_tax_invoice_number",
      name: "taxInvoiceNumber",
      placeholder: "lbl_tax_invoice_number_placeholder",
      decorator: [
        "taxInvoiceNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
}
