// AP
import "@/views/account-payables/index";
// Asset Inquiry Open Form
import "@/views/assets/Inquiry/index";
import "./CharacterLength";
import "./custom/button";
import "./custom/input";
import "./custom/modal";
import "./custom/pagination";
import "./custom/qrcode";
import "./custom/select";
import "./custom/table";
import "./Drawer";
