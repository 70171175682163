// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const PURCHASING_ROUTE: RoutePathModel = {
  path: "/purchasing",
  url: "/purchasing",
  name: "Purchasing",
  meta: {
    breadcrumb: "Purchasing",
    title_head: "Purchasing",
    need_auth: true,
  },
};

export const GOODS_RECEIPT_PRICE_LIST_ROUTE: RoutePathModel = {
  path: `/purchasing/goods-receipt-price`,
  url: `/purchasing/goods-receipt-price`,
  name: "purchasing.goods-receipt-price",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_goods_receipt_price",
        href: `/purchasing/goods-receipt-price`,
      },
    ],
    title_head: "lbl_goods_receipt_price",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const GOODS_RECEIPT_PRICE_CREATE_ROUTE: RoutePathModel = {
  path: `/purchasing/goods-receipt-price/create`,
  url: `/purchasing/goods-receipt-price/create`,
  name: "purchasing.goods-receipt-price.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_goods_receipt_price",
        href: `/purchasing/goods-receipt-price`,
      },
      {
        name: "lbl_create",
      },
    ],
    title_head: "lbl_goods_receipt_price_create",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const GOODS_RECEIPT_PRICE_EDIT_ROUTE: RoutePathModel = {
  path: `/purchasing/goods-receipt-price/:id/edit`,
  url: `/purchasing/goods-receipt-price/:id/edit`,
  name: "purchasing.goods-receipt-price.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_goods_receipt_price",
        href: `/purchasing/goods-receipt-price`,
      },
      {
        name: "lbl_edit",
      },
    ],
    title_head: "lbl_goods_receipt_price_edit",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const GOODS_RECEIPT_PRICE_DETAIL_ROUTE: RoutePathModel = {
  path: `/purchasing/goods-receipt-price/:id`,
  url: `/purchasing/goods-receipt-price/:id`,
  name: "purchasing.goods-receipt-price.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_goods_receipt_price",
        href: `/purchasing/goods-receipt-price`,
      },
      {
        name: "lbl_detail",
      },
    ],
    title_head: "lbl_goods_receipt_price_detail",
    mode: Mode.DETAIL,
    need_auth: true,
  },
};

export const PURCHASINGCLOSEPERIODE_ROUTE: RoutePathModel = {
  path: `${PURCHASING_ROUTE.url}/closeperiod`,
  url: `${PURCHASING_ROUTE.url}/closeperiod`,
  name: "lbl_purchasing_close_period",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_purchasing_close_period",
        href: `${PURCHASING_ROUTE.url}/closeperiod`,
      },
    ],
    title_head: "lbl_purchasing_close_period",
  },
};

export const TRANSACTION_CREATERETURNTOSUPPLIER_ROUTE: RoutePathModel = {
  path: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier/create`,
  url: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier/create`,
  name: "purchasing.transaction.supplier-return.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_transaction",
        icon: "file-text",
      },
      {
        name: "lbl_return_to_supplier",
        href: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier`,
      },
      {
        name: "lbl_create",
        href: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier/create`,
      },
    ],
    title_head: "lbl_return_to_supplier_create",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const TRANSACTION_EDITRETURNTOSUPPLIER_ROUTE: RoutePathModel = {
  path: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier/edit/:id`,
  url: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier/edit/:id`,
  name: "purchasing.transaction.supplier-return.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_transaction",
        icon: "file-text",
      },
      {
        name: "lbl_return_to_supplier",
        href: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier`,
      },
      {
        name: "lbl_edit",
        href: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier/edit/:id`,
      },
    ],
    title_head: "lbl_return_to_supplier_edit",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const TRANSACTION_RETURNTOSUPPLIER_ROUTE: RoutePathModel = {
  path: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier`,
  url: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier`,
  name: "purchasing.transaction.supplier-return",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_transaction",
        icon: "file-text",
      },
      {
        name: "lbl_return_to_supplier",
        href: `${PURCHASING_ROUTE.url}/transaction/return-to-supplier`,
      },
    ],
    title_head: "lbl_return_to_supplier",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const REPORT_PURCHASEORDER_ROUTE: RoutePathModel = {
  path: `${PURCHASING_ROUTE.url}/report/purchase-order`,
  url: `${PURCHASING_ROUTE.url}/report/purchase-order`,
  name: "lbl_report_purchase_order",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "Reports",
        icon: "file",
      },
      {
        name: "lbl_report_purchase_order",
        href: `${PURCHASING_ROUTE.url}/report/purchase-order`,
      },
    ],
    title_head: "lbl_report_purchase_order",
  },
};

export const REPORT_PURCHASEORDER_DETAIL_ROUTE: RoutePathModel = {
  path: `${PURCHASING_ROUTE.url}/report/purchase-order/detail`,
  url: `${PURCHASING_ROUTE.url}/report/purchase-order/detail`,
  name: "purchasing.report.purchase-order.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_purchase_order_detail_report",
        href: `${PURCHASING_ROUTE.url}/report/purchase-order`,
      },
    ],
    title_head: "lbl_purchase_order_detail_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const PURCHASEORDER_ROUTE: RoutePathModel = {
  path: `${PURCHASING_ROUTE.url}/purchase-order`,
  url: `${PURCHASING_ROUTE.url}/purchase-order`,
  name: "lbl_purchase_order",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_purchase_order",
        href: `${PURCHASING_ROUTE.url}/purchase-order`,
      },
    ],
    title_head: "lbl_purchase_order",
  },
};

export const CREATEPURCHASEORDER_ROUTE: RoutePathModel = {
  path: `${PURCHASING_ROUTE.url}/purchase-order/create`,
  url: `${PURCHASING_ROUTE.url}/purchase-order/create`,
  name: "lbl_create_purchase_order",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "lbl_purchase_order",
        href: `${PURCHASING_ROUTE.url}/purchase-order`,
      },
      {
        name: "lbl_create_purchase_order",
        href: `${PURCHASING_ROUTE.url}/purchase-order/create`,
      },
    ],
    title_head: "lbl_create_purchase_order",
    mode: Mode.CREATE,
  },
};

export const VIEWPURCHASEORDER_ROUTE: RoutePathModel = {
  path: `${PURCHASING_ROUTE.url}/purchase-order/detail/:id`,
  url: `${PURCHASING_ROUTE.url}/purchase-order/detail/:id`,
  name: "lbl_view_purchase_order",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchase_order",
        href: `${PURCHASING_ROUTE.url}/purchase-order`,
      },
      {
        name: "lbl_view_purchase_order",
        href: `${PURCHASING_ROUTE.url}/purchase-order/detail/:id`,
      },
    ],
    title_head: "lbl_view_purchase_order",
    mode: Mode.VIEW,
  },
};

export const PURCHASEREQUISITIONLIST_ROUTE: RoutePathModel = {
  path: `/purchasing/purchaserequisition`,
  url: `/purchasing/purchaserequisition`,
  name: "Purchase Requisition",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "Purchase Requisition",
        href: "/purchasing/purchaserequisition",
      },
    ],
    title_head: "Purchase Requisition",
    mode: Mode.VIEW,
  },
};
export const PURCHASEREQUISITIONCREATE_ROUTE: RoutePathModel = {
  path: `/purchasing/purchaserequisition/create`,
  url: `/purchasing/purchaserequisition/create`,
  name: "Create Purchase Requisition",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "Purchase Requisition",
        href: "/purchasing/purchaserequisition",
      },
      {
        name: "Create Purchase Requisition",
        href: "/purchasing/purchaserequisition/create",
      },
    ],
    title_head: "Create Purchase Requisition",
    mode: Mode.CREATE,
  },
};
export const PURCHASEREQUISITIONEDIT_ROUTE: RoutePathModel = {
  path: `/purchasing/purchaserequisition/edit/:id`,
  url: `/purchasing/purchaserequisition/edit/:id`,
  name: "Edit Purchase Requisition",
  meta: {
    breadcrumb: [
      {
        name: "lbl_purchasing",
        icon: "shop",
      },
      {
        name: "Purchase Requisition",
        href: "/purchasing/purchaserequisition",
      },
      {
        name: "Edit Purchase Requisition",
        href: "/purchasing/purchaserequisition/edit/:id",
      },
    ],
    title_head: "Edit Purchase Requisition",
    mode: Mode.EDIT,
  },
};
